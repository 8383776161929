/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain) */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font-style: inherit;
    font-variant-ligatures: inherit;
    font-variant-caps: inherit;
    font-variant-numeric: inherit;
    font-variant-east-asian: inherit;
    font-weight: inherit;
    font-stretch: inherit;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    vertical-align: baseline;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}


/* Custom */
*, *:after, *:before {
    box-sizing: border-box;
}
input, textarea, select {
    border: none;
    appearance: none;
    font-family: inherit;
    font-size: inherit;
    font-weight: inherit;
}
a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
    outline: none;
}
textarea {
    resize: none;
}
img {
    max-width: 100%;
    border-style: none;
}
a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
        text-decoration: none;
    }
    &:active {
        color: inherit;
    }
}