@charset "UTF-8";
/* http://meyerweb.com/eric/tools/css/reset/ 
   v2.0 | 20110126
   License: none (public domain) */
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font-style: inherit;
  font-variant-ligatures: inherit;
  font-variant-caps: inherit;
  font-variant-numeric: inherit;
  font-variant-east-asian: inherit;
  font-weight: inherit;
  font-stretch: inherit;
  font-size: inherit;
  line-height: inherit;
  font-family: inherit;
  vertical-align: baseline;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

/* Custom */
*, *:after, *:before {
  box-sizing: border-box; }

input, textarea, select {
  border: none;
  appearance: none;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit; }

a, input, textarea, button, select,
a:focus, input:focus, textarea:focus, button:focus, select:focus,
a:active, input:active, textarea:active, button:active, select:active {
  outline: none; }

textarea {
  resize: none; }

img {
  max-width: 100%;
  border-style: none; }

a {
  color: inherit;
  text-decoration: none; }
  a:hover {
    color: inherit;
    text-decoration: none; }
  a:active {
    color: inherit; }

[hidden] {
  display: none; }

.clearfix:after {
  content: '';
  display: table;
  clear: both; }

@font-face {
  font-family: 'Montserrat';
  src: url("../fonts/Montserrat/Montserrat-Light.eot");
  src: url("../fonts/Montserrat/Montserrat-Light.eot?#iefix") format("embedded-opentype"), url("../fonts/Montserrat/Montserrat-Light.woff") format("woff"), url("../fonts/Montserrat/Montserrat-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

svg, svg path, a, button {
  transition: all 0.25s ease-in-out 0s; }

h2 {
  font-size: 30px;
  font-weight: 300;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 40px; }

.tab_list-content li {
  display: none; }
  .tab_list-content li.active {
    display: block; }

* ::-webkit-input-placeholder {
  color: #1B1A16;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

* ::-moz-placeholder {
  color: #1B1A16;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

* :-ms-input-placeholder {
  color: #1B1A16;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

* :-moz-placeholder {
  color: #1B1A16;
  font-family: 'Montserrat', sans-serif;
  font-size: 13px; }

.relative {
  position: relative; }

.form {
  color: #1B1A16; }

.form_title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 25px; }

.form_field {
  font-size: 13px;
  color: #1B1A16;
  width: 100%;
  height: 35px;
  line-height: 34px;
  border: none;
  border-bottom: 1px solid #CDCDCD;
  margin-bottom: 15px;
  transition: all 0.25s ease-in-out 0s; }
  .form_field.is_invalid,
  .form_field select.is_invalid {
    border-color: red; }

.form_textarea {
  padding: 0; }

span.errMess {
  display: block;
  margin-top: -18px;
  font-size: 11px;
  margin-bottom: 10px;
  color: #f00; }

.form_select {
  margin-bottom: 25px;
  transition: all .25s ease-in-out 0s; }
  .form_select select {
    display: none !important; }
  .form_select .is_invalid + .select2 {
    border-color: red; }
  .form_select .select2 {
    width: 100% !important;
    border-bottom: 1px solid #cdcdcd;
    position: relative; }
    .form_select .select2:after {
      content: '';
      width: 6px;
      height: 6px;
      border-right: 1px solid #4C4B48;
      border-bottom: 1px solid #4C4B48;
      transform: rotate(45deg);
      margin: auto 0;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 5px;
      z-index: 1;
      transition: all 0.25s ease-in-out 0s; }
    .form_select .select2.select2-container--open:after {
      transform: rotate(-135deg);
      top: 5px; }
  .form_select .select2-selection__arrow {
    display: none; }
  .form_select .select2-selection--single {
    height: auto;
    border: none !important;
    outline: none !important; }
  .form_select .select2-selection__rendered {
    height: 34px;
    line-height: 34px !important;
    padding: 0 10px 0 0 !important;
    font-size: 13px;
    color: #1B1A16 !important;
    outline: none !important; }

.form_select_dd.select2-dropdown {
  margin-top: 0; }

.form_select_dd .select2-results {
  border: 1px solid #E7E6E6;
  margin-top: -1px; }

.form_select_dd .select2-results__option--highlighted[aria-selected] {
  background-color: #f8f8f8 !important; }

.form_select_dd .select2-results > .select2-results__options {
  padding: 0 !important; }

.form_select_dd .select2-results__option {
  padding: 6px 15px;
  font-size: 13px;
  font-weight: 300; }

.select2-container--default .select2-selection--multiple,
.select2-container--default .select2-selection--single {
  border-radius: 0; }

.form_submit {
  cursor: pointer;
  width: 240px;
  height: 50px;
  line-height: 46px;
  padding: 0 15px;
  background-color: #F3AD52;
  font-size: 14px;
  text-transform: uppercase;
  color: #FFFFFF;
  font-weight: 300;
  border: 2px solid transparent;
  transition: all 0.25s ease-in-out 0s; }
  .form_submit:hover {
    border-color: transparent;
    background-color: rgba(243, 173, 82, 0.78); }
  .form_submit:active {
    border-color: #F3AD52;
    background-color: #FFFFFF;
    color: #000000; }

.yellow_btn {
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 400 !important;
  border-color: #F3AD52 !important;
  background-color: #F3AD52 !important;
  color: #FFFFFF !important;
  transition: all .2s ease-in-out; }
  .yellow_btn:hover {
    border-color: transparent !important;
    background-color: rgba(243, 173, 82, 0.78) !important;
    color: #FFFFFF !important; }
  .yellow_btn:active {
    border-color: #F3AD52 !important;
    background-color: #FFFFFF !important;
    color: #000000 !important; }

@media (max-width: 991px) {
  .yellow_btn {
    font-size: 16px !important; } }

.form_subtitle {
  font-size: 14px;
  line-height: 24px;
  font-weight: 300;
  margin-bottom: 10px; }

.form_radio {
  display: inline-block;
  width: auto;
  height: auto;
  margin-right: 10px;
  margin-bottom: 10px;
  border-bottom: 0; }
  .form_radio input {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid #cecece;
    vertical-align: middle;
    margin-top: -2px;
    cursor: pointer;
    position: relative; }
    .form_radio input:checked:before {
      content: '';
      background: #f3ad52;
      width: 10px;
      height: 10px;
      position: absolute;
      border-radius: 50%;
      top: 4px;
      left: 4px; }

.other-site-sections {
  max-width: 928px;
  margin: 30px auto 0; }
  .other-site-sections h3, .other-site-sections h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px; }
  .other-site-sections ul {
    padding-left: 30px; }
    .other-site-sections ul li {
      color: #F3AD52;
      padding-left: 15px;
      position: relative;
      color: #000;
      margin-bottom: 12px; }
      .other-site-sections ul li:before {
        content: '';
        position: absolute;
        top: 11px;
        left: 0;
        background: #F3AD52;
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 50%; }
      .other-site-sections ul li a, .other-site-sections ul li span {
        font-size: 14px;
        font-weight: 300;
        line-height: 24px;
        color: #000; }
        .other-site-sections ul li a:hover, .other-site-sections ul li span:hover {
          color: #F3AD52; }

.simple_content ul {
  padding-left: 25px; }
  .simple_content ul li {
    color: #F3AD52;
    padding-left: 15px;
    position: relative;
    color: #000;
    margin-bottom: 15px; }
    .simple_content ul li:before {
      content: '';
      position: absolute;
      top: 11px;
      left: 0;
      background: #F3AD52;
      display: block;
      width: 4px;
      height: 4px;
      border-radius: 50%; }
    .simple_content ul li a, .simple_content ul li span {
      font-size: 14px;
      font-weight: 300;
      line-height: 24px;
      color: #000; }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat; }

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal; }

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 38px;
  width: 38px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
  z-index: 1; }
  .slick-prev:hover, .slick-prev:focus,
  .slick-next:hover,
  .slick-next:focus {
    outline: none;
    background: transparent;
    color: transparent; }
    .slick-prev:hover:before, .slick-prev:focus:before,
    .slick-next:hover:before,
    .slick-next:focus:before {
      opacity: 1; }
  .slick-prev.slick-disabled:before,
  .slick-next.slick-disabled:before {
    opacity: 0.25; }
  .slick-prev:before,
  .slick-next:before {
    font-family: "slick";
    font-size: 20px;
    line-height: 1;
    color: black;
    opacity: 0.75;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

.slick-prev {
  left: 57.5px; }
  [dir="rtl"] .slick-prev {
    left: auto;
    right: -25px; }
  .slick-prev:before {
    content: "←"; }
    [dir="rtl"] .slick-prev:before {
      content: "→"; }
  .slick-prev:hover {
    left: 52.5px; }

.slick-next {
  right: 57.5px; }
  [dir="rtl"] .slick-next {
    left: -25px;
    right: auto; }
  .slick-next:before {
    content: "→"; }
    [dir="rtl"] .slick-next:before {
      content: "←"; }
  .slick-next:hover {
    right: 52.5px; }

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px; }

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%; }
  .slick-dots li {
    position: relative;
    display: inline-block;
    height: 20px;
    width: 20px;
    margin: 0 5px;
    padding: 0;
    cursor: pointer; }
    .slick-dots li button {
      border: 0;
      background: transparent;
      display: block;
      height: 20px;
      width: 20px;
      outline: none;
      line-height: 0px;
      font-size: 0px;
      color: transparent;
      padding: 5px;
      cursor: pointer; }
      .slick-dots li button:hover, .slick-dots li button:focus {
        outline: none; }
        .slick-dots li button:hover:before, .slick-dots li button:focus:before {
          opacity: 1; }
      .slick-dots li button:before {
        position: absolute;
        top: 0;
        left: 0;
        content: "•";
        width: 20px;
        height: 20px;
        font-family: "slick";
        font-size: 6px;
        line-height: 20px;
        text-align: center;
        color: black;
        opacity: 0.25;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale; }
    .slick-dots li.slick-active button:before {
      color: black;
      opacity: 0.75; }

.fancybox-wrap,
.fancybox-skin,
.fancybox-outer,
.fancybox-inner,
.fancybox-image,
.fancybox-wrap iframe,
.fancybox-wrap object,
.fancybox-nav,
.fancybox-nav span,
.fancybox-tmp {
  padding: 0;
  margin: 0;
  border: 0;
  outline: none;
  vertical-align: top; }

.fancybox-wrap {
  position: absolute;
  top: 0;
  left: 0;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 8020; }

.fancybox-skin {
  position: relative;
  background: #f9f9f9;
  color: #444;
  text-shadow: none;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px; }

.fancybox-opened {
  z-index: 8030; }

.fancybox-opened .fancybox-skin {
  -webkit-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5); }

.fancybox-outer, .fancybox-inner {
  position: relative; }

.fancybox-inner {
  overflow: hidden; }

.fancybox-type-iframe .fancybox-inner {
  -webkit-overflow-scrolling: touch; }

.fancybox-error {
  color: #444;
  font: 14px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  margin: 0;
  padding: 15px;
  white-space: nowrap; }

.fancybox-image, .fancybox-iframe {
  display: block;
  width: 100%;
  height: 100%; }

.fancybox-image {
  max-width: 100%;
  max-height: 100%; }

#fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
  background-image: url(../fancybox/fancybox_sprite.png); }

#fancybox-loading {
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -22px;
  margin-left: -22px;
  background-position: 0 -108px;
  opacity: 0.8;
  cursor: pointer;
  z-index: 8060; }

#fancybox-loading div {
  width: 44px;
  height: 44px;
  background: url(../fancybox/fancybox_loading.gif) center center no-repeat; }

.fancybox-close {
  position: absolute;
  top: -18px;
  right: -18px;
  width: 36px;
  height: 36px;
  cursor: pointer;
  z-index: 8040; }

.fancybox-nav {
  position: absolute;
  top: 0;
  width: 40%;
  height: 100%;
  cursor: pointer;
  text-decoration: none;
  background: transparent url(../fancybox/blank.gif);
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  z-index: 8040; }

.fancybox-prev {
  left: 0; }

.fancybox-next {
  right: 0; }

.fancybox-nav span {
  position: absolute;
  top: 50%;
  width: 36px;
  height: 34px;
  margin-top: -18px;
  cursor: pointer;
  z-index: 8040;
  visibility: hidden; }

.fancybox-prev span {
  left: 10px;
  background-position: 0 -36px; }

.fancybox-next span {
  right: 10px;
  background-position: 0 -72px; }

.fancybox-nav:hover span {
  visibility: visible; }

.fancybox-tmp {
  position: absolute;
  top: -99999px;
  left: -99999px;
  max-width: 99999px;
  max-height: 99999px;
  overflow: visible !important; }

.fancybox-lock {
  overflow: visible !important;
  width: auto; }

.fancybox-lock body {
  overflow: hidden !important; }

.fancybox-lock-test {
  overflow-y: hidden !important; }

.fancybox-overlay {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  display: none;
  z-index: 8010;
  background: url(../fancybox/fancybox_overlay.png); }

.fancybox-overlay-fixed {
  position: fixed;
  bottom: 0;
  right: 0; }

.fancybox-lock .fancybox-overlay {
  overflow: auto;
  overflow-y: scroll; }

.fancybox-title {
  visibility: hidden;
  font: normal 13px/20px "Helvetica Neue",Helvetica,Arial,sans-serif;
  position: relative;
  text-shadow: none;
  z-index: 8050; }

.fancybox-opened .fancybox-title {
  visibility: visible; }

.fancybox-title-float-wrap {
  position: absolute;
  bottom: 0;
  right: 50%;
  margin-bottom: -35px;
  z-index: 8050;
  text-align: center; }

.fancybox-title-float-wrap .child {
  display: inline-block;
  margin-right: -100%;
  padding: 2px 20px;
  background: transparent;
  background: rgba(0, 0, 0, 0.8);
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  border-radius: 15px;
  text-shadow: 0 1px 2px #222;
  color: #FFF;
  font-weight: bold;
  line-height: 24px;
  white-space: nowrap; }

.fancybox-title-outside-wrap {
  position: relative;
  margin-top: 10px;
  color: #fff; }

.fancybox-title-inside-wrap {
  padding-top: 10px; }

.fancybox-title-over-wrap {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  padding: 10px;
  background: #000;
  background: rgba(0, 0, 0, 0.8); }

@media only screen and (-webkit-min-device-pixel-ratio: 1.5), only screen and (min--moz-device-pixel-ratio: 1.5), only screen and (min-device-pixel-ratio: 1.5) {
  #fancybox-loading, .fancybox-close, .fancybox-prev span, .fancybox-next span {
    background-image: url(../fancybox/fancybox_sprite@2x.png);
    background-size: 44px 152px; }
  #fancybox-loading div {
    background-image: url(../fancybox/fancybox_loading@2x.gif);
    background-size: 24px 24px; } }

body {
  font-family: 'Montserrat', sans-serif;
  font-size: 16px;
  background: #FFFFFF;
  color: #000000; }

@media (min-width: 1400px) {
  .container {
    max-width: 1390px; } }

img {
  height: auto;
  max-height: 100%; }

.container-big {
  max-width: 1430px;
  margin: 0 auto;
  position: relative;
  padding: 0 15px; }

/*header*/
header {
  position: fixed;
  padding: 22px 0 0;
  width: 100%;
  top: 0;
  left: 0;
  background: #FFFFFF;
  z-index: 10;
  border-bottom: 1px solid #E7E6E6; }

.hidden_block {
  display: none; }

.h-logo {
  text-align: center; }

.h-left {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-top: 10px; }

.h-map {
  display: flex;
  flex-direction: row;
  align-items: center; }
  .h-map-select {
    margin-left: 0px; }
    .h-map-select select {
      font-size: 12px;
      padding-right: 15px;
      background: url("../img/sel_arr.png") no-repeat 90% 50%;
      width: 60px;
      cursor: pointer; }
  .h-map-text {
    font-size: 12px;
    font-weight: 500;
    padding: 0 5px 0 8px; }

.h-midl {
  padding-top: 2px;
  position: relative;
  z-index: 2; }

.h-phns {
  min-height: 31px;
  padding-right: 20px;
  position: relative;
  z-index: 5;
  width: max-content;
  background: #FFFFFF;
  width: 215px; }
  .h-phns .drp {
    position: absolute;
    right: 18px;
    top: 15px;
    width: 18px;
    height: 18px;
    background: url("../img/sel_arr.png") no-repeat 50% 50%;
    cursor: pointer;
    z-index: 2; }
  .h-phns a {
    font-size: 14px;
    padding-left: 20px;
    display: inline-block; }
    .h-phns a:hover, .h-phns a:focus {
      color: #F3AD52; }
    .h-phns a.kvstr {
      background: url("../img/h-kvstr.png") no-repeat 0% 50%; }
    .h-phns a.vdf {
      background: url("../img/h-vdf.png") no-repeat 0% 50%; }
    .h-phns a.lfc {
      background: url("../img/h-lfc.png") no-repeat 0% 50%; }
  .h-phns-modl {
    display: none;
    background: #FFFFFF;
    padding-top: 5px; }
    .h-phns-modl a {
      margin: 5px 0; }
  .h-phns-cont {
    padding: 0 18px;
    padding-top: 15px;
    filter: grayscale(100%);
    border-radius: 0px; }
    .h-phns-cont:hover {
      filter: grayscale(0%); }
    .h-phns-cont.active {
      filter: grayscale(0%);
      padding: 15px 18px;
      position: absolute;
      width: 100%;
      background: #FFFFFF;
      box-shadow: 0 0 26px 2px #0000001c; }
      .h-phns-cont.active .h-phns-modl {
        display: block; }

.h-lang {
  margin-left: 10px;
  margin-left: 15px; }
  .h-lang .dropdown {
    position: relative; }
    .h-lang .dropdown > a, .h-lang .dropdown.span {
      display: block;
      padding: 12px 15px;
      text-transform: uppercase;
      font-size: 12px;
      position: relative;
      margin-top: 2px;
      font-weight: 500; }
      .h-lang .dropdown > a:after, .h-lang .dropdown.span:after {
        content: '';
        width: 10px;
        height: 6px;
        background: url("../img/sel_arr.png") no-repeat 50% 50%;
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -3px;
        display: none; }
    .h-lang .dropdown .h-submenu {
      display: none;
      position: absolute;
      top: 42px;
      left: 21px;
      background: #FFFFFF;
      padding: 10px 20px;
      box-shadow: 0px 8px 13px 2px #00000014;
      width: 60px;
      width: max-content;
      z-index: 2; }
      .h-lang .dropdown .h-submenu > li {
        display: block; }
        .h-lang .dropdown .h-submenu > li > a {
          font-size: 13px;
          font-weight: 500;
          line-height: 36px;
          text-transform: uppercase; }
          .h-lang .dropdown .h-submenu > li > a:hover, .h-lang .dropdown .h-submenu > li > a:focus {
            color: #F3AD52; }
      .h-lang .dropdown .h-submenu.active {
        display: block; }

.h-graf {
  font-size: 12px;
  padding-left: 38px;
  margin-top: 5px; }

.h-right {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
  padding-right: 8px;
  position: relative;
  z-index: 2; }
  .h-right button {
    border: 0;
    padding: 0;
    margin-left: 26px;
    width: 22px;
    height: 22px;
    cursor: pointer; }

.h-srch {
  position: relative;
  z-index: 4; }
  .h-srch button {
    background: url("../img/h-srch.png") no-repeat 50% 50%;
    position: relative;
    left: 0;
    z-index: 3;
    transition: 0.5s; }
    .h-srch button.active {
      left: -15px; }
  .h-srch-form {
    position: absolute;
    z-index: 2;
    right: 0;
    width: 0;
    transition: 0.7s;
    top: -9px; }
    .h-srch-form form {
      border: 1px solid transparent;
      background: #FFFFFF;
      align-items: center;
      padding: 9px 0px;
      border-radius: 0px;
      visibility: hidden;
      opacity: 0; }
      .h-srch-form form input {
        border: 0;
        flex: 1;
        font-weight: 300;
        font-size: 14px;
        color: #9e9d9b; }
      .h-srch-form form button {
        display: none;
        background: url("../img/h-srch.png") no-repeat 50% 50%;
        border: 0;
        padding: 0;
        margin-left: 26px;
        width: 22px;
        height: 22px;
        cursor: pointer; }
    .h-srch-form.active {
      width: 393px;
      transition: width 0.7s;
      border: 1px solid #E7E6E6; }
      .h-srch-form.active form {
        padding: 9px 15px;
        visibility: visible;
        opacity: 1; }
    .h-srch-form .main__search {
      display: flex; }
  .h-srch .main__dropDown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: #FFFFFF;
    margin-top: 1px;
    min-width: 100%;
    width: max-content;
    max-width: 370px;
    font-size: 14px;
    line-height: 1.33; }
  .h-srch .main__DD-list {
    padding: 15px;
    box-shadow: 3px 5px 10px rgba(0, 0, 0, 0.4); }
  .h-srch .main__DD-item {
    display: flex;
    padding: 10px 0; }
    .h-srch .main__DD-item + .main__DD-item {
      border-top: 1px solid #E7E6E6; }
  .h-srch .mini-img {
    flex-shrink: 0;
    width: 70px; }
    .h-srch .mini-img img {
      width: 100%; }
  .h-srch .main__DD-info {
    display: flex;
    width: 100%; }
  .h-srch .main__DD-name {
    flex-grow: 1;
    padding: 0 10px; }
  .h-srch .main__DD-price {
    flex-shrink: 0;
    width: 70px;
    text-align: right; }

.h-prof {
  flex-shrink: 0;
  position: relative;
  z-index: 1; }
  .h-prof .js_account {
    display: inline-block;
    flex-shrink: 0;
    line-height: 22px;
    margin-left: 16px;
    margin-right: -10px;
    font-size: 14px;
    font-weight: 500; }
  .h-prof button {
    background: url("../img/h-prof.png") no-repeat 50% 50%; }

.h-cart {
  position: relative; }
  .h-cart button {
    background: url("../img/h-cart.png") no-repeat 50% 50%; }
  .h-cart .count {
    font-family: 'Open Sans', sans-serif;
    position: absolute;
    font-size: 10px;
    font-weight: 700;
    color: #FFFFFF;
    background: #f85843;
    text-align: center;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    padding-top: 2px;
    top: -6px;
    right: -8px; }

.h-submenu {
  display: none;
  position: absolute;
  top: 42px;
  left: 37px;
  background: #FFFFFF;
  padding: 10px 20px;
  box-shadow: 0px 8px 13px 2px #00000014;
  width: max-content; }
  .h-submenu > li {
    display: block; }
    .h-submenu > li > a {
      font-size: 13px;
      line-height: 36px;
      font-weight: 500; }
      .h-submenu > li > a:hover, .h-submenu > li > a:focus {
        color: #F3AD52; }

.h-menu {
  margin: 0 auto;
  max-width: 1100px;
  margin-top: 10px;
  margin-bottom: 4px; }
  .h-menu > ul {
    display: flex;
    justify-content: space-between;
    align-items: center; }
    .h-menu > ul > li > a {
      display: block;
      padding: 15px 21px;
      text-transform: uppercase;
      font-size: 12px;
      position: relative;
      font-weight: 500; }
      .h-menu > ul > li > a:hover, .h-menu > ul > li > a:focus {
        color: #F3AD52; }
    .h-menu > ul > li.dropdown {
      position: relative; }
      .h-menu > ul > li.dropdown > a .dropdown_arrow, .h-menu > ul > li.dropdown.span .dropdown_arrow {
        width: 23px;
        height: 19px;
        background: url("../img/sel_arr.png") no-repeat 100% 50%;
        background-size: 10px 6px;
        position: absolute;
        z-index: 1;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -10px; }
      .h-menu > ul > li.dropdown:hover .h-submenu {
        display: block; }

.h-burg {
  display: none; }

/*header*/
@media (min-width: 992px) and (max-width: 1399px) {
  .h-srch-form.active {
    width: 270px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .h-phns a {
    font-size: 12px; } }

@media (max-width: 991px) {
  header {
    padding: 22px 0; }
  .h-logo {
    max-width: 180px; }
  .h-right {
    margin-top: 20px;
    justify-content: flex-start; }
  .h-srch button {
    margin-left: 0;
    margin-right: 26px; }
  .h-srch .main__dropDown {
    max-width: 450px; }
  .h-prof button {
    margin-left: 0;
    margin-right: 26px; }
  .h-cart button {
    margin-left: 0;
    margin-right: 26px; }
  .h-cart .count {
    right: 17px; }
  .h-burg {
    display: block;
    width: 20px;
    padding: 0;
    border: 0;
    background: none;
    margin-top: 2px; }
    .h-burg span {
      background: #000;
      height: 2px;
      width: 100%;
      display: block;
      margin: 4px 0; }
  .h-menu > ul {
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start; }
    .h-menu > ul > li > a {
      padding: 15px 0;
      padding-right: 21px; }
  .h-submenu {
    position: static;
    box-shadow: none;
    padding-top: 0;
    width: 100%; }
    .h-submenu > li > a {
      line-height: 20px;
      margin-bottom: 10px;
      display: inline-block; }
  .mob_only .h-logo {
    text-align: center;
    max-width: 184px;
    margin: 0 auto; }
  .mob_only .h-right {
    margin-top: 7px; } }

@media (max-width: 767px) {
  .h-srch .main__dropDown {
    max-width: 415px; } }

@media (max-width: 767px) {
  .h-srch .main__dropDown {
    width: 100%;
    max-width: none; }
  .h-srch .main__DD-info {
    display: block; }
  .h-srch .main__DD-price {
    padding: 5px 10px;
    width: auto;
    text-align: left; } }

.mob_overlay {
  display: none; }

.mob_cls {
  display: none; }

@media (min-width: 992px) {
  .pc_only {
    display: block !important; } }

@media (max-width: 991px) {
  .pc_only {
    display: none;
    position: fixed;
    height: 100vh;
    background: #FFFFFF;
    top: 0;
    left: 0;
    min-width: 290px;
    width: 75%;
    padding-top: 20px;
    padding-bottom: 20px;
    z-index: 2;
    overflow-y: auto; }
  .mob_overlay {
    position: fixed;
    z-index: 2;
    background: rgba(0, 0, 0, 0.47);
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0; }
  .mob_cls {
    width: 30px;
    height: 40px;
    background: #F3AD52;
    position: absolute;
    top: 0;
    right: auto;
    left: 75%;
    z-index: 3; }
    .mob_cls:before {
      content: '';
      display: block;
      width: 16px;
      height: 2px;
      top: 50%;
      position: absolute;
      transform: rotate(45deg);
      background: #000;
      left: 7px; }
    .mob_cls:after {
      content: '';
      display: block;
      width: 16px;
      height: 2px;
      top: 50%;
      position: absolute;
      transform: rotate(-45deg);
      background: #000;
      left: 7px; } }

@media (max-width: 480px) {
  .pc_only {
    width: calc(100% - 30px); }
  .mob_cls {
    left: auto;
    right: 0; } }

.wrapper {
  padding-top: 130px; }

@media (max-width: 767px) {
  .wrapper {
    padding-top: 80px; } }

.page-continue {
  text-align: center;
  padding-bottom: 90px; }
  .page-continue h1 {
    line-height: 1.33;
    margin-bottom: 25px; }
  .page-continue p {
    font-size: 18px;
    line-height: 1.33;
    margin-bottom: 25px; }
    .page-continue p + p {
      margin-top: -5px; }
  .page-continue .yellow_btn {
    display: inline-block;
    width: 160px;
    font-size: 14px;
    color: #fff;
    padding: 0 10px;
    border: 2px solid transparent;
    text-transform: uppercase;
    background: #f3ad52;
    line-height: 36px;
    text-align: center;
    transition: .3s;
    cursor: pointer; }

@media (max-width: 991px) {
  .page-continue h1 {
    font-size: 30px; } }

@media (max-width: 767px) {
  .page-continue h1 {
    padding: 0;
    font-size: 24px !important; }
  .page-continue p {
    font-size: 14px; } }

.account_reset_buttons {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .account_reset_buttons .back_linkt {
    text-decoration: underline; }
    .account_reset_buttons .back_linkt:hover {
      text-decoration: none; }

.template_content h1, .template_content h2, .template_content h3, .template_content h4, .template_content h5, .template_content h6, .template_content p, .template_content ul, .template_content ol {
  max-width: 928px !important;
  margin-left: auto !important;
  margin-right: auto !important; }

.template_content p {
  margin-bottom: 10px !important;
  font-size: 14px !important;
  line-height: 24px !important;
  font-weight: 300 !important; }

.template_content img {
  display: block !important;
  margin: 45px auto 60px !important;
  width: 100% !important; }

.template_content ul {
  padding-left: 30px !important; }
  .template_content ul li {
    font-size: 14px !important;
    font-weight: 300 !important;
    line-height: 24px !important;
    color: #000 !important;
    padding-left: 15px !important;
    position: relative !important;
    margin-bottom: 12px !important; }
    .template_content ul li:before {
      content: '' !important;
      position: absolute !important;
      top: 11px !important;
      left: 0 !important;
      background: #f3ad52 !important;
      display: block !important;
      width: 4px !important;
      height: 4px !important;
      border-radius: 50% !important; }
    .template_content ul li a {
      font-size: 14px !important;
      font-weight: 300 !important;
      line-height: 24px !important;
      color: #000 !important; }

.template_content hr {
  border-color: #E7E6E6 !important;
  max-width: 928px !important;
  margin: 40px auto 25px !important; }

@media (max-width: 991px) {
  .template_content div br,
  .template_content p br {
    display: none !important; } }

/*main*/
.main .container {
  padding: 0;
  max-width: 1440px; }

.main .row {
  margin: 0; }

.main .col-12, .main .col-6 {
  padding: 0; }

.main-slide {
  position: relative;
  font-size: 0; }

.main-slider-info {
  position: absolute;
  z-index: 2;
  width: 100%;
  top: 96px;
  text-align: center; }

.main-slider-date {
  font-size: 18px;
  font-weight: 500; }

.main-slider-ttl {
  color: #F3AD52;
  font-weight: 600;
  font-size: 35px;
  margin-top: 17px; }

.main-slider-txt {
  text-transform: uppercase;
  font-size: 20px;
  margin-top: 15px;
  font-weight: 500; }

.main-slider-img {
  position: relative;
  z-index: 1; }

.main-slider .slick-slide {
  font-size: 0; }

.main-slider .slick-prev {
  left: 25px; }
  .main-slider .slick-prev:hover path {
    stroke: #F3AD52; }

.main-slider .slick-next {
  right: 32px; }
  .main-slider .slick-next:hover path {
    stroke: #F3AD52; }

.main-slider .slick-dots {
  width: auto;
  left: 50%;
  transform: translateX(-50%); }

.main-bl {
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column; }
  .main-bl-ttl {
    font-size: 20px;
    font-weight: 500;
    text-align: center;
    padding-bottom: 13px;
    margin-bottom: 13px;
    position: relative;
    line-height: 1.3; }
    .main-bl-ttl:after {
      content: '';
      position: absolute;
      display: block;
      height: 1px;
      width: 43%;
      background: #f8af43;
      margin: 0 auto;
      bottom: 0;
      left: 0;
      right: 0; }
  .main-bl-txt {
    font-size: 16px; }
  .main-bl.yellow {
    background: #f6eee1; }
  .main-bl.green {
    background: #c8e6e4; }
  .main-bl-wp {
    opacity: 1;
    transition: 0.3s; }
    .main-bl-wp:hover, .main-bl-wp:focus {
      opacity: 0.7;
      transition: 0.3s; }

.main .slick-dotted.slick-slider {
  margin-bottom: 0;
  padding-bottom: 0; }

.main .slick-dots {
  top: auto;
  bottom: 20px; }

.main .slick-dots li button:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 1; }

/*main*/
@media (min-width: 1300px) {
  .main-bl-wp {
    max-height: 400px; } }

@media (max-width: 991px) {
  .main-slider-info {
    top: 35px; }
  .main-slider-date {
    font-size: 16px; }
  .main-slider-ttl {
    font-size: 26px; }
  .main-slider-txt {
    font-size: 18px;
    margin-top: 22px; }
  .main-bl-wp {
    max-height: 56vw; }
  .main-bl-ttl {
    font-size: 14px;
    line-height: 18px;
    padding: 0 10px; }
    .main-bl-ttl:after {
      bottom: -10px; }
  .main-bl-txt {
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    padding: 0 10px; } }

@media (min-width: 718px) and (max-width: 991px) {
  .main {
    max-width: 718px;
    margin: 0 auto; } }

/*categories*/
.categories {
  padding: 68px 0 40px;
  background: #f8f8f8; }
  .categories + .novelty {
    margin-top: -70px; }
  .categories-ttl {
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 32px; }
  .categories-bl {
    padding: 0 15px;
    margin-bottom: 30px;
    width: 100%; }
    .categories-bl-in {
      height: 100%;
      background: #FFFFFF;
      padding: 29px 24px;
      padding-right: 16px;
      display: flex;
      flex-direction: column;
      justify-content: space-between; }
      .categories-bl-in:hover, .categories-bl-in:focus {
        box-shadow: 8px 8px 18px 2px #0000002b; }
    .categories-bl-ttl {
      font-size: 18px;
      padding-bottom: 16px;
      position: relative;
      line-height: 24px;
      max-height: 64px;
      font-weight: 400;
      transition: 0.3s; }
      .categories-bl-ttl:before {
        content: '';
        position: absolute;
        display: block;
        height: 1px;
        width: 60px;
        background: #f8af43;
        margin: 0 0;
        bottom: 0;
        left: 0; }
      .categories-bl-ttl:hover, .categories-bl-ttl:focus {
        color: #f8af43;
        transition: 0.3s; }
    .categories-bl-img {
      padding: 19px 0;
      text-align: center; }
      .categories-bl-img img {
        max-width: 100%;
        width: auto; }

/*categories*/
@media (min-width: 1400px) {
  .categories-bl {
    max-width: 22.2%;
    width: 100%; }
    .categories-bl.big {
      max-width: 33.4%; }
    .categories-bl.middle {
      max-width: 27.8%; }
    .categories-bl-in {
      height: 270px; } }

@media (min-width: 992px) and (max-width: 1399px) {
  .categories-bl {
    max-width: 22.2%;
    width: 100%; }
    .categories-bl.big {
      max-width: 33.4%; }
    .categories-bl.middle {
      max-width: 27.8%; }
    .categories-bl-in {
      height: 100%;
      padding: 29px 18px;
      padding-right: 10px; }
    .categories-bl-ttl {
      height: auto;
      font: 1;
      font-size: 16px;
      max-height: 1000px; } }

@media (max-width: 991px) {
  .categories {
    padding-top: 50px; }
    .categories-ttl {
      font-size: 24px;
      font-weight: 400; }
    .categories-bl {
      width: 50%; }
      .categories-bl-in {
        flex-direction: row;
        padding: 11px 11px;
        align-items: center;
        justify-content: flex-start;
        padding-right: 5px; }
      .categories-bl-ttl {
        order: 2;
        flex: 0 0 1;
        padding: 0;
        font-size: 16px;
        font-weight: 300; }
        .categories-bl-ttl:before {
          display: none; }
        .categories-bl-ttl br {
          display: none; }
      .categories-bl-img {
        order: 1;
        padding: 0;
        margin-right: 15px;
        height: 35px;
        text-align: left;
        width: auto !important; } }

@media (max-width: 767px) {
  .categories-bl {
    width: 100%; } }

/*product-slider*/
.product-slide {
  padding: 20px 15px; }

.product-slider {
  height: 540px; }
  .product-slider-bl {
    padding: 40px 10px 30px;
    position: relative;
    max-width: 340px;
    margin: 0 auto;
    background: #FFFFFF; }
    .product-slider-bl-stickers {
      position: absolute;
      right: 15px;
      top: 15px; }
      .product-slider-bl-stickers .new {
        background: url("../img/new.png");
        width: 80px;
        height: 26px;
        margin-bottom: 5px;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 4px 4px 9px 15px; }
      .product-slider-bl-stickers .akc {
        background: url("../img/akc.png");
        width: 66px;
        height: 26px;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 2px 4px 9px 15px; }
    .product-slider-bl-ttl {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 0px;
      display: block;
      transition: 0.3s; }
      .product-slider-bl-ttl:hover, .product-slider-bl-ttl:focus {
        color: #f8af43;
        transition: 0.3s; }
    .product-slider-bl-subttl {
      text-align: center;
      font-size: 13px;
      line-height: 20px;
      display: block; }
    .product-slider-bl-img {
      text-align: center;
      padding-top: 14px;
      display: block; }
      .product-slider-bl-img img {
        display: inline; }
    .product-slider-bl-prices {
      text-align: center; }
      .product-slider-bl-prices .old {
        font-size: 14px;
        line-height: 24px;
        color: #626260;
        text-decoration: line-through; }
      .product-slider-bl-prices .cur {
        font-size: 18px;
        line-height: 24px;
        margin-top: 2px;
        transition: 0.3s; }
        .product-slider-bl-prices .cur span {
          font-size: 24px; }
        .product-slider-bl-prices .cur:hover {
          color: #f8af43;
          transition: 0.3s; }
    .product-slider-bl-props {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      margin: 28px 0 10px; }
      .product-slider-bl-props span {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 24px;
        padding: 0 7px;
        margin-bottom: 1px; }
        .product-slider-bl-props span img {
          margin-right: 7px; }
    .product-slider-bl-btn {
      display: none;
      text-align: center;
      margin-top: 15px; }
      .product-slider-bl-btn a {
        font-size: 14px;
        font-weight: 300;
        line-height: 36px;
        padding: 0 26px;
        color: #1B1A16;
        text-transform: uppercase;
        border: 2px solid #F3AD52;
        display: inline-block; }
        .product-slider-bl-btn a:hover {
          border-color: #f7b132; }
    .product-slider-bl:hover {
      background: #FFFFFF;
      box-shadow: 0 0 26px 2px #0000001c; }
      .product-slider-bl:hover .product-slider-bl-props {
        display: flex; }
      .product-slider-bl:hover .product-slider-bl-btn {
        display: block; }
    .product-slider-bl-timer {
      display: flex;
      justify-content: center;
      margin: 5px 0;
      align-items: center; }
      .product-slider-bl-timer > span {
        border: 1px solid #f00;
        font-size: 12px;
        padding: 15px 5px;
        width: 72px;
        text-align: center; }
        .product-slider-bl-timer > span.sep {
          border: 0;
          font-size: 20px;
          font-weight: 600;
          padding: 0;
          margin: 0 9px;
          width: auto; }
  .product-slider.slick-dotted.slick-slider {
    margin-bottom: 30px; }
  .product-slider .slick-list {
    margin: 0 -10px;
    padding: 0 10px; }
    .product-slider .slick-list:before {
      content: '';
      position: absolute;
      height: 100%;
      width: calc((100vw - 1160px)/2);
      z-index: 2;
      top: 0;
      right: calc(100% - 20px); }
    .product-slider .slick-list:after {
      content: '';
      position: absolute;
      height: 100%;
      width: calc((100vw - 1160px)/2);
      z-index: 2;
      top: 0;
      left: calc(100% - 20px); }
  .product-slider-arrs {
    width: 96px;
    display: flex;
    position: absolute;
    top: 8px;
    right: 0; }
    .product-slider-arrs a {
      position: static; }
      .product-slider-arrs a svg {
        position: static; }
  .product-slider .slick-prev {
    left: auto;
    right: 43px;
    top: -30px; }
    .product-slider .slick-prev:hover path {
      stroke: #F3AD52; }
  .product-slider .slick-next {
    left: auto;
    right: 2px;
    top: -30px; }
    .product-slider .slick-next:hover path {
      stroke: #F3AD52; }

.button_like {
  position: relative; }
  .button_like:hover .like_hint {
    opacity: 1;
    visibility: visible; }
  .button_like.active .after-added-text {
    opacity: 1;
    position: static;
    visibility: visible;
    transition: all 0.25s ease-in-out 0s; }
  .button_like.active .add-text {
    opacity: 0;
    position: absolute;
    visibility: hidden; }
  .button_like.hint .like_hint {
    opacity: 1;
    visibility: visible; }
  .button_like.hint .added-text {
    opacity: 1;
    position: static;
    visibility: visible;
    transition: all 0.25s ease-in-out 0s; }
  .button_like.hint .after-added-text {
    opacity: 0;
    position: absolute;
    visibility: hidden; }

.like_hint {
  cursor: default;
  width: 148px;
  position: absolute;
  top: 10px;
  left: -5px;
  transform: translate(-100%, -50%);
  text-align: center;
  font-size: 13px;
  line-height: 1.33;
  background-color: #FFFFFF;
  border: 1px solid #f8af43;
  padding: 5px;
  text-transform: initial;
  border-radius: 3px;
  transition: all 0.25s ease-in-out 0s;
  opacity: 0;
  visibility: hidden; }
  .like_hint:before, .like_hint:after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto 0;
    display: block;
    width: 0;
    height: 0; }
  .like_hint:before {
    border-style: solid;
    border-width: 5px 0 5px 8px;
    border-color: transparent transparent transparent #f8af43;
    right: -8px; }
  .like_hint:after {
    border-style: solid;
    border-width: 4px 0 4px 7px;
    border-color: transparent transparent transparent #FFFFFF;
    right: -7px; }
  .like_hint span {
    position: absolute;
    opacity: 0;
    visibility: hidden; }
  .like_hint .add-text {
    position: static;
    opacity: 1;
    visibility: visible;
    transition: all 0.25s ease-in-out 0s; }

/*product-slider*/
@media (max-width: 991px) {
  .product-slider {
    height: auto; }
    .product-slider .slick-dots {
      top: auto;
      bottom: 0px; }
    .product-slider .slick-dots li button:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #c8c9c9;
      border-radius: 50%;
      opacity: 1; }
    .product-slider .slick-dots li.slick-active button:before {
      background: #000; }
    .product-slider-arrs {
      display: none; }
    .product-slider-bl-props {
      display: flex; }
      .product-slider-bl-props span img {
        margin-right: 6px; }
    .product-slider-bl:hover {
      background: none;
      box-shadow: none; }
      .product-slider-bl:hover .product-slider-bl-btn {
        display: none; }
    .product-slider .slick-list {
      margin: 0;
      padding: 0; } }

/*popular*/
.popular {
  padding: 68px 0 0px;
  position: relative;
  z-index: 1; }
  .popular:hover {
    z-index: 3; }
  .popular .container {
    position: relative; }
  .popular-ttl {
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px; }

/*popular*/
@media (max-width: 991px) {
  .popular {
    padding: 68px 0; }
    .popular .container {
      padding: 0; }
    .popular-ttl {
      font-size: 24px;
      font-weight: 400;
      overflow: hidden;
      max-width: 191px;
      margin: 0 auto 12px;
      white-space: nowrap; } }

/*novelty*/
.novelty {
  padding: 68px 0 0px;
  position: relative;
  z-index: 1;
  margin-bottom: -79px; }
  .novelty:before {
    content: '';
    background: #f8f8f8;
    position: absolute;
    z-index: -1;
    width: 100%;
    height: 100%;
    height: calc(100% - 79px);
    top: 0px; }
  .novelty:hover {
    z-index: 3; }
  .novelty .container {
    position: relative; }
  .novelty-ttl {
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px; }

/*novelty*/
@media (max-width: 991px) {
  .novelty {
    padding: 68px 0; }
    .novelty .container {
      padding: 0; }
    .novelty-ttl {
      font-size: 24px;
      font-weight: 400; } }

/*stock*/
.stock {
  padding: 68px 0 0px;
  position: relative;
  z-index: 1;
  margin-bottom: -79px; }
  .stock:hover {
    z-index: 3; }
  .stock .container {
    position: relative; }
  .stock-ttl {
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 12px; }

/*stock*/
@media (max-width: 991px) {
  .stock {
    padding: 68px 0; }
    .stock .container {
      padding: 0; }
    .stock-ttl {
      font-size: 24px;
      font-weight: 400; } }

/*about*/
.about {
  padding: 68px 0;
  position: relative;
  z-index: 1;
  background: #f8f8f8; }
  .about-ttl {
    font-size: 40px;
    font-weight: 300;
    text-transform: uppercase;
    margin-bottom: 25px;
    text-align: left; }
  .about-cont {
    display: flex;
    margin-bottom: 26px; }
  .about-col-sm {
    width: 132px;
    flex: 0 0 132px;
    margin-right: 40px; }
  .about-img {
    text-align: center;
    padding-top: 7px; }
  .about-txt {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.9px; }
  .about-bl {
    margin-bottom: 25px; }
    .about-bl h3 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px; }
    .about-bl p {
      font-size: 12px;
      line-height: 24px;
      font-weight: 400; }

/*about*/
@media (min-width: 1200px) {
  .about .container {
    max-width: 928px; } }

@media (max-width: 991px) {
  .about-cont {
    flex-wrap: wrap; }
  .about-col-sm {
    width: 100%;
    margin-bottom: 20px; }
  .about-col-big {
    width: 100%; } }

/*breadcrumbs*/
.breadcrumbs {
  padding: 18px 0; }
  .breadcrumbs a, .breadcrumbs span {
    font-size: 12px;
    color: #626260; }
    .breadcrumbs a.cur, .breadcrumbs span.cur {
      color: #000; }
    .breadcrumbs a.sep, .breadcrumbs span.sep {
      margin: 0 8px; }
  .breadcrumbs span {
    color: #1B1A16; }

/*breadcrumbs*/
h1 {
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 300;
  margin: 5px 0; }

@media (max-width: 991px) {
  h1 {
    font-size: 24px;
    font-weight: 400;
    text-align: center; } }

h3 {
  font-size: 30px;
  line-height: 24px;
  margin-bottom: 15px; }

h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px; }

/*sidebar_left*/
.sidebar_left {
  border-right: 1px solid #E7E6E6;
  padding-right: 20px;
  max-width: 290px;
  padding-top: 30px;
  padding-bottom: 21px; }
  .sidebar_left-bl {
    border-bottom: 1px solid #E7E6E6;
    margin-bottom: 30px; }
    .sidebar_left-bl:last-child {
      border-bottom: 0; }
    .sidebar_left-bl-ttl {
      font-size: 18px;
      display: block;
      position: relative;
      cursor: pointer;
      margin-bottom: 21px;
      color: #1B1A16; }
      .sidebar_left-bl-ttl:before {
        content: '';
        width: 10px;
        height: 6px;
        background: url(../img/sel_arr.png) no-repeat 50% 50%;
        position: absolute;
        display: block;
        right: 0;
        top: 50%;
        margin-top: -3px;
        transition: 0.3s; }
      .sidebar_left-bl-ttl.active:before {
        transform: rotate(180deg);
        transition: 0.3s; }
    .sidebar_left-bl-subttl {
      font-size: 18px;
      display: block;
      position: relative;
      cursor: pointer;
      margin-bottom: 21px;
      width: 100%;
      margin-top: 15px; }
    .sidebar_left-bl-filt {
      display: none;
      margin-bottom: 21px; }
      .sidebar_left-bl-filt-range {
        display: flex;
        flex-wrap: wrap; }
        .sidebar_left-bl-filt-range label {
          display: flex;
          width: 50%;
          font-size: 14px;
          align-items: center; }
          .sidebar_left-bl-filt-range label input {
            border: 1px solid #E7E6E6;
            margin-left: 10px;
            width: 85px;
            padding: 8px 9px; }
        .sidebar_left-bl-filt-range .irs {
          width: 100%; }
          .sidebar_left-bl-filt-range .irs--flat .irs-line {
            height: 3px;
            border-radius: 0; }
          .sidebar_left-bl-filt-range .irs--flat .irs-bar {
            height: 3px;
            border-radius: 0;
            background: #F3AD52; }
          .sidebar_left-bl-filt-range .irs--flat .irs-handle {
            width: 14px;
            height: 14px;
            top: 19px;
            transform: translateX(-6px);
            cursor: pointer; }
            .sidebar_left-bl-filt-range .irs--flat .irs-handle > i:first-child {
              width: 14px;
              height: 14px;
              border-radius: 50%;
              background: #F3AD52; }
      .sidebar_left-bl-filt-chk {
        margin-bottom: 11px; }
        .sidebar_left-bl-filt-chk label {
          font-size: 13px;
          cursor: pointer; }
          .sidebar_left-bl-filt-chk label span.chbx {
            border: 1px solid #E7E6E6;
            width: 20px;
            height: 20px;
            margin-right: 6px;
            display: inline-block;
            vertical-align: middle; }
          .sidebar_left-bl-filt-chk label span.cnt {
            color: #959595; }
        .sidebar_left-bl-filt-chk input {
          display: none; }
          .sidebar_left-bl-filt-chk input:checked + label span.chbx {
            border: 1px solid #F3AD52;
            background: url("../img/filt_chk.png") no-repeat 50% 50%, #F3AD52; }
        .sidebar_left-bl-filt-chk:last-child {
          margin-bottom: 0; }
    .sidebar_left-bl .filter__title span {
      font-size: 18px;
      display: block;
      position: relative;
      cursor: pointer;
      margin-bottom: 21px;
      color: #1b1a16; }
    .sidebar_left-bl .flex-block {
      display: flex; }
    .sidebar_left-bl .content-between {
      justify-content: space-between; }
    .sidebar_left-bl .form-control {
      border: 1px solid #e7e6e6;
      width: 85px;
      padding: 8px 9px;
      border-radius: 0;
      font-size: 14px;
      margin-left: 10px; }
    .sidebar_left-bl .noUi-target {
      margin-top: 25px;
      box-shadow: none;
      height: 3px;
      border-radius: 0;
      border: 0; }
    .sidebar_left-bl .noUi-connect {
      background: #f3ad52; }
    .sidebar_left-bl .noUi-horizontal .noUi-handle {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      background: #f3ad52;
      box-shadow: none;
      border: 0;
      right: -9px;
      top: -6px;
      cursor: pointer; }
      .sidebar_left-bl .noUi-horizontal .noUi-handle:focus {
        outline: 0; }
    .sidebar_left-bl .noUi-handle:before {
      display: none; }
    .sidebar_left-bl .noUi-handle:after {
      display: none; }
    .sidebar_left-bl .noUi-connects {
      background-color: #e1e4e9;
      border-radius: 0; }
    .sidebar_left-bl .collapse {
      margin-bottom: 26px; }
      .sidebar_left-bl .collapse label {
        width: 50%;
        font-size: 14px;
        align-items: center;
        display: flex; }

/*sidebar_left*/
@media (min-width: 992px) {
  .sidebar_left {
    display: block !important; } }

@media (max-width: 991px) {
  .sidebar_left {
    border-left: 1px solid #E7E6E6;
    border-top: 1px solid #E7E6E6;
    padding-left: 20px;
    margin: 0 auto;
    display: none; } }

/*page_catalog*/
.page_catalog-remove {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 6px;
  font-size: 40px;
  font-weight: 400;
  color: #AEAEAE;
  transition: all 0.25s ease-in-out 0s; }
  .page_catalog-remove:hover {
    color: #000000; }

.page_catalog-top {
  display: flex;
  justify-content: center; }
  .page_catalog-top-fltr {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center; }
    .page_catalog-top-fltr > span {
      font-size: 12px;
      padding: 7px 10px 4px;
      border: 1px solid #000;
      border-radius: 0px;
      margin-right: 10px; }
      .page_catalog-top-fltr > span.del-all {
        border-color: #F75B45;
        cursor: pointer; }
      .page_catalog-top-fltr > span.item {
        border-color: #E7E6E6; }
        .page_catalog-top-fltr > span.item span {
          position: relative;
          width: 8px;
          height: 8px;
          display: inline-block;
          vertical-align: middle;
          cursor: pointer; }
          .page_catalog-top-fltr > span.item span:before {
            content: '';
            background: #000;
            height: 1px;
            width: 100%;
            display: block;
            position: absolute;
            transform: rotate(45deg);
            top: 3px; }
          .page_catalog-top-fltr > span.item span:after {
            content: '';
            background: #000;
            height: 1px;
            width: 100%;
            display: block;
            position: absolute;
            transform: rotate(-45deg);
            top: 3px; }
  .page_catalog-top-sort {
    display: flex;
    align-items: center; }
    .page_catalog-top-sort span {
      font-size: 12px; }
    .page_catalog-top-sort select {
      font-size: 12px;
      padding: 6px 10px 5px;
      padding-right: 20px;
      border: 1px solid #E7E6E6;
      margin-left: 10px;
      background: url(../img/sel_arr.png) no-repeat 95% 50%;
      cursor: pointer; }
      .page_catalog-top-sort select option {
        padding: 5px 10px; }
        .page_catalog-top-sort select option:hover {
          background: #E7E6E6; }
    .page_catalog-top-sort .select2 {
      margin-left: 10px; }
      .page_catalog-top-sort .select2-container .select2-selection--single {
        border: 1px solid #f8af43; }

.page_catalog-cont {
  margin-top: 25px; }

.page_catalog-bnnr {
  margin: 40px auto 30px;
  position: relative;
  max-width: 340px;
  text-align: center;
  display: block; }
  .page_catalog-bnnr-img {
    display: block;
    background: #f6eee1;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    min-height: 369px;
    z-index: 1; }
  .page_catalog-bnnr-info {
    display: block;
    position: relative;
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    padding: 40px 0; }
  .page_catalog-bnnr-ttl {
    display: block;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    color: #F75B45;
    opacity: 1;
    transition: 0.3s; }
    .page_catalog-bnnr-ttl:hover {
      opacity: 0.7;
      transition: 0.3s; }
  .page_catalog-bnnr-date {
    display: block;
    font-size: 13px;
    line-height: 24px; }
  .page_catalog-bnnr-skid {
    display: block;
    font-size: 72px;
    font-weight: 700;
    color: #F75B45;
    padding-top: 43%; }

.page_catalog-bnnr2 {
  padding: 40px 0px 30px;
  position: relative;
  max-width: 340px;
  margin: 0 auto;
  text-align: center; }

.page_catalog-bl {
  padding: 40px 10px 30px;
  position: relative;
  max-width: 340px;
  margin: 0 auto; }
  .page_catalog-bl-wp {
    height: 400px;
    position: relative;
    max-width: 340px;
    margin: 0 auto; }
  .page_catalog-bl-stickers {
    position: absolute;
    right: 20px;
    top: 15px; }
    .page_catalog-bl-stickers .new {
      background: url("../img/new.png");
      width: 80px;
      height: 26px;
      margin-bottom: 5px;
      font-size: 11px;
      font-weight: 500;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 4px 4px 9px 15px; }
    .page_catalog-bl-stickers .akc {
      background: url("../img/akc.png");
      width: 66px;
      height: 26px;
      margin-bottom: 5px;
      font-size: 15px;
      font-weight: 600;
      text-transform: uppercase;
      color: #FFFFFF;
      padding: 2px 4px 9px 15px; }
  .page_catalog-bl-ttl {
    text-align: center;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 0px;
    display: block;
    font-weight: 500; }
    .page_catalog-bl-ttl:hover, .page_catalog-bl-ttl:focus {
      color: #f8af43; }
  .page_catalog-bl-subttl {
    text-align: center;
    font-size: 13px;
    line-height: 20px;
    display: block;
    font-weight: 400; }
  .page_catalog-bl-img {
    text-align: center;
    padding-top: 14px;
    display: block;
    min-height: 204px; }
    .page_catalog-bl-img img {
      display: inline; }
  .page_catalog-bl-prices {
    text-align: center;
    display: block; }
    .page_catalog-bl-prices .old {
      font-size: 14px;
      line-height: 24px;
      color: #626260;
      text-decoration: line-through;
      display: block; }
    .page_catalog-bl-prices .cur {
      font-size: 18px;
      line-height: 24px;
      margin-top: 2px;
      display: block; }
      .page_catalog-bl-prices .cur span {
        font-size: 24px; }
      .page_catalog-bl-prices .cur:hover, .page_catalog-bl-prices .cur:focus {
        color: #f8af43; }
  .page_catalog-bl-props {
    display: none;
    flex-wrap: wrap;
    justify-content: center;
    margin: 28px 0 10px; }
    .page_catalog-bl-props span {
      display: flex;
      align-items: center;
      font-size: 13px;
      line-height: 24px;
      padding: 0 7px; }
      .page_catalog-bl-props span img {
        margin-right: 7px; }
  .page_catalog-bl-btn {
    display: none;
    text-align: center;
    margin-top: 15px; }
    .page_catalog-bl-btn a {
      font-size: 14px;
      line-height: 36px;
      border: 2px solid transparent;
      padding: 0 26px;
      color: #FFFFFF;
      text-transform: uppercase;
      background: #F3AD52;
      display: inline-block;
      font-weight: 300; }
      .page_catalog-bl-btn a:hover {
        background: #f7b132; }
  .page_catalog-bl:hover {
    background: #FFFFFF;
    box-shadow: 0 0 26px 2px #0000001c;
    position: absolute;
    z-index: 3;
    display: block;
    width: 100%; }
    .page_catalog-bl:hover .page_catalog-bl-props {
      display: flex; }
    .page_catalog-bl:hover .page_catalog-bl-btn {
      display: block; }

.page_catalog-show_more {
  display: block;
  text-align: center;
  margin-top: 20px; }
  .page_catalog-show_more button {
    background: url("../img/show_more.png") no-repeat 80% 50%, #f8f8f8;
    border: 0;
    font-size: 20px;
    font-weight: 300;
    font-family: 'Montserrat',sans-serif;
    cursor: pointer;
    padding: 16px 75px;
    padding-right: 100px;
    display: inline-block; }
    .page_catalog-show_more button:hover, .page_catalog-show_more button:focus {
      background-color: #E7E6E6; }

.page_catalog-about {
  padding: 68px 0 40px;
  position: relative;
  z-index: 1;
  max-width: 928px; }
  .page_catalog-about-ttl {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 45px;
    text-transform: none;
    text-align: left; }
  .page_catalog-about-cont {
    display: flex;
    margin-bottom: 26px; }
  .page_catalog-about-col-sm {
    width: 132px;
    flex: 0 0 132px;
    margin-right: 40px; }
  .page_catalog-about-img {
    text-align: center;
    padding-top: 7px; }
  .page_catalog-about-txt {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.9px; }
  .page_catalog-about-seo h2, .page_catalog-about-seo h3 {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    margin-bottom: 10px;
    text-transform: none; }
  .page_catalog-about-seo p {
    font-size: 12px;
    line-height: 24px;
    font-weight: 300; }
    .page_catalog-about-seo p:last-child {
      margin-bottom: 25px; }
    .page_catalog-about-seo p + p {
      margin-top: 10px; }
    .page_catalog-about-seo p + h2,
    .page_catalog-about-seo p + h2 {
      margin-top: 25px; }
  .page_catalog-about-bl {
    margin-bottom: 25px; }
    .page_catalog-about-bl h2, .page_catalog-about-bl h3 {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      text-align: left;
      margin-bottom: 10px;
      text-transform: none; }
    .page_catalog-about-bl p {
      font-size: 12px;
      line-height: 24px;
      font-weight: 300; }
      .page_catalog-about-bl p + p {
        margin-top: 10px; }

.page_catalog-showroom {
  background: url("../img/showroom.jpg");
  background-size: cover;
  padding: 40px 0; }
  .page_catalog-showroom-ttl {
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 300; }
  .page_catalog-showroom-city {
    margin-top: 26px;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex;
    display: -o-flex;
    display: flex;
    align-items: center; }
    .page_catalog-showroom-city span {
      font-size: 14px;
      margin-right: 19px; }
    .page_catalog-showroom-city select {
      width: 100%;
      max-width: 318px;
      font-size: 14px;
      padding: 11px 10px;
      background: url(../img/sel_arr.png) no-repeat 95% 50%, #FFFFFF;
      cursor: pointer; }
    .page_catalog-showroom-city .select2-container--default .select2-selection--single .select2-selection__rendered {
      line-height: 18px; }
  .page_catalog-showroom-list {
    margin-top: 45px; }
  .page_catalog-showroom-bl {
    margin-bottom: 17px;
    display: block; }
    .page_catalog-showroom-bl-ttl {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      margin-bottom: 2px;
      display: block; }
    .page_catalog-showroom-bl-txt {
      font-size: 14px;
      line-height: 24px;
      display: block; }
  .page_catalog-showroom .select2-container--open .select2-selection__rendered {
    box-shadow: inset 0px 0px 0px 1px #f8af43; }
  .page_catalog-showroom span span.select2-selection.select2-selection--single.shopIon {
    margin-right: 0; }
    .page_catalog-showroom span span.select2-selection.select2-selection--single.shopIon:focus {
      outline: 0;
      box-shadow: none; }

.sorting_mdl.select2-dropdown {
  margin-top: 0px; }
  .sorting_mdl.select2-dropdown .select2-results__options {
    padding: 0 !important; }
    .sorting_mdl.select2-dropdown .select2-results__options .select2-results__option {
      padding: 6px 5px 6px 10px;
      white-space: nowrap; }

.shopSel_mdl.select2-dropdown {
  margin-top: -2px; }
  .shopSel_mdl.select2-dropdown .select2-results {
    border: 0; }
  .shopSel_mdl.select2-dropdown .select2-results__options {
    padding: 0 !important; }
    .shopSel_mdl.select2-dropdown .select2-results__options .select2-results__option {
      padding: 6px 5px 6px 10px; }

.ion_mdl.select2-dropdown {
  margin-top: 0px;
  width: max-content !important; }
  .ion_mdl.select2-dropdown .select2-results__options {
    padding: 0 !important; }
    .ion_mdl.select2-dropdown .select2-results__options .select2-results__option {
      padding: 6px 5px 6px 10px; }

.headIon {
  border: 0 !important; }
  .headIon .select2-selection__arrow {
    display: none; }
  .headIon .select2-selection__rendered {
    background: url(../img/sel_arr.png) no-repeat 90% 50%;
    font-size: 12px;
    font-weight: 500; }

.shopIon {
  border: 0 !important; }
  .shopIon .select2-selection__arrow {
    display: none; }
  .shopIon .select2-selection__rendered {
    width: 100%;
    max-width: 318px;
    font-size: 14px;
    padding: 11px 10px;
    background: url(../img/sel_arr.png) no-repeat 95% 50%, #fff;
    cursor: pointer;
    border: 0; }

/*page_catalog*/
@media (max-width: 991px) {
  .page_catalog-top {
    flex-direction: column; }
    .page_catalog-top-fltr {
      flex-wrap: wrap;
      display: none; }
      .page_catalog-top-fltr > span {
        margin-bottom: 10px; }
  .page_catalog-bl-wp {
    height: auto; }
  .page_catalog-bl-props {
    display: flex; }
    .page_catalog-bl-props span {
      margin-bottom: 5px; }
      .page_catalog-bl-props span img {
        margin-right: 5px; }
  .page_catalog-bl:hover {
    background: none;
    box-shadow: none;
    position: static; }
    .page_catalog-bl:hover .page_catalog-bl-btn {
      display: none; }
  .page_catalog-about-cont {
    flex-wrap: wrap; }
  .page_catalog-about-col-sm {
    width: 100%;
    margin-bottom: 20px; }
  .page_catalog-about-col-big {
    width: 100%; }
  .page_catalog-show_more button {
    padding: 16px 100px 16px 48px; } }

.filt_open {
  display: none; }

@media (max-width: 991px) {
  .filt_open {
    display: block;
    border: 2px solid #F3AD52;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    text-transform: uppercase;
    max-width: 290px;
    margin: 0 auto 30px;
    cursor: pointer;
    padding: 10px 15px; }
    .filt_open.active {
      background: #F3AD52;
      color: #FFFFFF; } }

/*page_catalog*/
.page_search {
  padding: 50px 0; }
  .page_search-ttl {
    text-align: center; }
  .page_search-cont {
    margin-top: 25px; }
  .page_search-form {
    margin-top: 30px;
    background: #f8f8f8;
    padding: 20px 0; }
    .page_search-form form {
      display: flex;
      border: 1px solid transparent;
      background: #FFFFFF;
      align-items: center;
      padding: 9px 0px;
      margin: 0 auto;
      max-width: 666px;
      padding: 9px 15px;
      border: 1px solid #E7E6E6; }
      .page_search-form form input {
        border: 0;
        flex: 1;
        font-weight: 300;
        font-size: 14px;
        color: #9e9d9b; }
      .page_search-form form button {
        background: url("../img/h-srch.png") no-repeat 50% 50%;
        border: 0;
        padding: 0;
        margin-left: 26px;
        width: 22px;
        height: 22px;
        cursor: pointer; }
    .page_search-form.active {
      width: 393px;
      transition: 0.7s; }
      .page_search-form.active form {
        padding: 9px 15px;
        border: 1px solid #E7E6E6; }
  .page_search-bl {
    padding: 40px 10px 30px;
    position: relative;
    max-width: 340px;
    margin: 0 auto; }
    .page_search-bl-wp {
      height: 400px;
      position: relative;
      max-width: 340px;
      margin: 0 auto; }
    .page_search-bl-stickers {
      position: absolute;
      right: 20px;
      top: 15px; }
      .page_search-bl-stickers .new {
        background: url("../img/new.png");
        width: 80px;
        height: 26px;
        margin-bottom: 5px;
        font-size: 11px;
        font-weight: 500;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 4px 4px 9px 15px; }
      .page_search-bl-stickers .akc {
        background: url("../img/akc.png");
        width: 66px;
        height: 26px;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: #FFFFFF;
        padding: 2px 4px 9px 15px; }
    .page_search-bl-ttl {
      text-align: center;
      font-size: 20px;
      line-height: 24px;
      margin-bottom: 0px;
      display: block;
      font-weight: 500; }
    .page_search-bl-subttl {
      text-align: center;
      font-size: 13px;
      line-height: 20px;
      display: block; }
    .page_search-bl-img {
      text-align: center;
      padding-top: 14px;
      display: block; }
      .page_search-bl-img img {
        display: inline; }
    .page_search-bl-prices {
      text-align: center; }
      .page_search-bl-prices .old {
        font-size: 14px;
        line-height: 24px;
        color: #626260;
        text-decoration: line-through; }
      .page_search-bl-prices .cur {
        font-size: 18px;
        line-height: 24px;
        margin-top: 2px; }
        .page_search-bl-prices .cur span {
          font-size: 24px; }
        .page_search-bl-prices .cur:hover {
          color: #f8af43; }
    .page_search-bl-props {
      display: none;
      flex-wrap: wrap;
      justify-content: center;
      margin: 28px 0 10px; }
      .page_search-bl-props span {
        display: flex;
        align-items: center;
        font-size: 13px;
        line-height: 24px;
        padding: 0 7px; }
        .page_search-bl-props span img {
          margin-right: 7px; }
    .page_search-bl-btn {
      display: none;
      text-align: center;
      margin-top: 15px; }
      .page_search-bl-btn a {
        font-size: 14px;
        line-height: 36px;
        padding: 0 26px;
        border: 2px solid transparent;
        color: #FFFFFF;
        text-transform: uppercase;
        background: #F3AD52;
        display: inline-block; }
        .page_search-bl-btn a:hover {
          background: #f7b132; }
    .page_search-bl:hover {
      background: #FFFFFF;
      box-shadow: 0 0 26px 2px #0000001c;
      position: absolute;
      z-index: 3; }
      .page_search-bl:hover .page_search-bl-props {
        display: flex; }
      .page_search-bl:hover .page_search-bl-btn {
        display: block; }
  .page_search-show_more {
    display: block;
    text-align: center;
    margin-top: 20px; }
    .page_search-show_more button {
      background: url("../img/show_more.png") no-repeat 80% 50%, #f8f8f8;
      border: 0;
      font-size: 20px;
      font-weight: 300;
      font-family: 'Montserrat',sans-serif;
      cursor: pointer;
      padding: 16px 75px;
      padding-right: 100px;
      display: inline-block; }
      .page_search-show_more button:hover, .page_search-show_more button:focus {
        background-color: #E7E6E6; }

/*page_catalog*/
@media (max-width: 991px) {
  .page_search-bl-wp {
    height: auto; }
  .page_search-bl-props {
    display: flex; }
    .page_search-bl-props span {
      margin-bottom: 5px; }
      .page_search-bl-props span img {
        margin-right: 5px; }
  .page_search-bl:hover {
    background: none;
    box-shadow: none;
    position: static; }
    .page_search-bl:hover .page_search-bl-btn {
      display: none; }
  .page_search-show_more button {
    padding: 16px 100px 16px 48px; } }

/*page_collections*/
.page_collections {
  padding: 0 0 50px; }
  .page_collections-top {
    max-width: 928px;
    margin: 25px auto;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300; }
  .page_collections-ttl {
    text-align: center; }
  .page_collections-cont {
    margin-top: 25px;
    margin-top: 55px; }
  .page_collections-bl {
    position: relative;
    margin-bottom: 100px; }
    .page_collections-bl-img {
      display: block; }
    .page_collections-bl-info {
      max-width: 500px;
      margin: 0 auto;
      position: absolute;
      bottom: -40px;
      background: #FFFFFF;
      padding: 30px 40px 37px;
      left: 0;
      right: 0;
      margin: 0 auto;
      box-shadow: 0 0 26px 2px #0000001c; }
      .page_collections-bl-info.yellow {
        background: #f6eee1; }
      .page_collections-bl-info.blue {
        background: #d7e2e8; }
      .page_collections-bl-info.green {
        background: #d6fadd; }
      .page_collections-bl-info.red {
        background: #f9deda; }
    .page_collections-bl-ttl {
      font-size: 20px;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 5px;
      display: block; }
    .page_collections-bl-txt {
      font-size: 14px;
      line-height: 22px;
      font-weight: 300; }
    .page_collections-bl-link {
      display: block;
      position: absolute;
      bottom: 16px;
      right: 20px; }
  .page_collections-about {
    padding: 0px 0 10px;
    position: relative;
    z-index: 1;
    max-width: 928px;
    margin: 0 auto; }
    .page_collections-about-ttl {
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 45px; }
    .page_collections-about-cont {
      display: flex;
      margin-bottom: 26px; }
    .page_collections-about-col-sm {
      width: 132px;
      flex: 0 0 132px;
      margin-right: 40px; }
    .page_collections-about-img {
      text-align: center;
      padding-top: 7px; }
    .page_collections-about-txt {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.9px; }
    .page_collections-about-bl {
      margin-bottom: 25px; }
    .page_collections-about h2 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      margin-top: 20px;
      font-weight: 400;
      text-align: left;
      text-transform: none; }
    .page_collections-about h3 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px; }
    .page_collections-about p {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300; }
      .page_collections-about p + p {
        margin-top: 10px; }

/*page_collections*/
@media (min-width: 992px) and (max-width: 1199px) {
  .page_collections-bl-ttl {
    font-size: 16px; }
  .page_collections-bl-txt {
    font-size: 12px; } }

@media (max-width: 991px) {
  .page_collections-bl-info {
    padding: 10px;
    padding-right: 40px;
    width: 96%; }
  .page_collections-bl-ttl {
    font-size: 16px; }
  .page_collections-bl-txt {
    font-size: 12px; }
  .page_collections-about-cont {
    flex-wrap: wrap; }
  .page_collections-about-col-sm {
    width: 100%;
    margin-bottom: 20px; }
  .page_collections-about-col-big {
    width: 100%; } }

/*page_single_collections*/
.page_single_collections h1 {
  margin-top: 30px; }

.page_single_collections-infoblock {
  padding-bottom: 20px; }

.page_single_collections-slider {
  text-align: center;
  padding: 0 40px; }

.page_single_collections-slide {
  text-align: center;
  max-width: 750px; }
  .page_single_collections-slide img {
    display: inline-block; }

.page_single_collections-txt {
  font-size: 12px;
  line-height: 24px;
  font-weight: 400; }

.page_single_collections .slick-dotted.slick-slider {
  margin: 18px 0 30px; }

.page_single_collections .slick-prev {
  left: 0; }
  .page_single_collections .slick-prev:hover path {
    stroke: #F3AD52; }

.page_single_collections .slick-next {
  right: 0; }
  .page_single_collections .slick-next:hover path {
    stroke: #F3AD52; }

.page_single_collections .slick-dots {
  top: auto;
  bottom: 20px;
  left: 0; }

.page_single_collections .slick-dots li {
  margin: 0 2px; }

.page_single_collections .slick-dots li button:before {
  content: '';
  width: 10px;
  height: 10px;
  background: #FFFFFF;
  border-radius: 50%;
  opacity: 1; }

.page_single_collections .slick-dots li.slick-active button:before {
  background: #F3AD52; }

/*page_single_collections*/
@media (min-width: 1300px) {
  .page_single_collections-slider {
    text-align: center;
    padding: 0 75px; }
  .page_single_collections .slick-prev {
    left: 0; }
  .page_single_collections .slick-next {
    right: 10px; }
  .page_single_collections .slick-dotted.slick-slider {
    margin: 18px auto 30px; } }

@media (max-width: 991px) {
  .page_single_collections h1 {
    margin: 20px 0; } }

/*page_promotionList*/
.page_promotionList {
  padding: 0px 0; }
  .page_promotionList-ttl {
    text-align: center; }
  .page_promotionList-bl {
    padding: 40px 0; }
    .page_promotionList-bl-img {
      display: block; }
    .page_promotionList-bl-date {
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      margin-bottom: 16px;
      margin-top: 20px; }
    .page_promotionList-bl-ttl {
      display: block;
      font-size: 24px;
      line-height: 24px;
      font-weight: 500;
      color: #F75B45;
      margin-bottom: 20px;
      text-transform: none; }
    .page_promotionList-bl-txt {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300; }
    .page_promotionList-bl-more {
      padding-top: 15px; }
      .page_promotionList-bl-more a {
        font-size: 13px;
        line-height: 24px;
        border-bottom: 1px solid #000; }
        .page_promotionList-bl-more a:hover {
          border-bottom: 1px solid transparent; }
    .page_promotionList-bl:nth-child(2n) {
      background: #f8f8f8; }
      .page_promotionList-bl:nth-child(2n) .page_promotionList-bl_l {
        order: 2; }
      .page_promotionList-bl:nth-child(2n) .page_promotionList-bl_r {
        order: 1; }
      .page_promotionList-bl:nth-child(2n) .page_promotionList-bl-top {
        padding-right: 10px; }
    .page_promotionList-bl:nth-child(2n+1) .page_promotionList-bl-top {
      padding-left: 10px; }

/*page_promotionList*/
@media (min-width: 1400px) {
  .page_promotionList-bl .row {
    max-width: 1160px;
    margin: 0 auto; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .page_promotionList-bl-ttl {
    font-size: 22px; } }

@media (max-width: 991px) {
  .page_promotionList-bl-top {
    padding: 0 !important; }
  .page_promotionList-bl-ttl {
    text-align: center;
    font-size: 18px; }
  .page_promotionList-bl-img {
    text-align: center; }
  .page_promotionList-bl-date {
    margin-top: 25px;
    text-align: center;
    font-size: 14px; }
  .page_promotionList-bl:nth-child(2n) .page_promotionList-bl_l {
    order: 1; }
  .page_promotionList-bl:nth-child(2n) .page_promotionList-bl_r {
    order: 2; } }

/*page_promotions*/
.page_promotions-top_content {
  display: flex;
  align-items: center; }

.page_promotions-top_img {
  margin-right: 40px;
  width: 100%;
  max-width: 460px; }
  .page_promotions-top_img img {
    display: block;
    margin: 0 auto; }

.page_promotions-date {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 7px; }

.page_promotions-ttl {
  font-size: 30px;
  line-height: 36px;
  font-weight: 500;
  color: #F75B45;
  margin-bottom: 20px;
  text-transform: none; }

.page_promotions-end {
  color: #F75B45;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
  margin-bottom: 10px; }

.page_promotions-timer {
  display: flex;
  justify-content: flex-start;
  margin: 5px 0;
  align-items: center; }
  .page_promotions-timer > span {
    border: 1px solid #f00;
    font-size: 12px;
    padding: 15px 5px;
    width: 72px;
    text-align: center; }
    .page_promotions-timer > span.sep {
      border: 0;
      font-size: 20px;
      font-weight: 600;
      padding: 0;
      margin: 0 9px;
      width: auto; }

.page_promotions-txt {
  font-size: 16px;
  line-height: 24px;
  margin: 50px 0 55px; }
  .page_promotions-txt p {
    margin-bottom: 25px;
    font-size: 14px; }
  .page_promotions-txt b {
    font-weight: 600;
    font-size: 16px;
    margin-bottom: 10px;
    display: inline-block; }
  .page_promotions-txt a {
    opacity: 1; }
    .page_promotions-txt a:hover {
      opacity: 0.7; }

.page_promotions-about {
  padding: 40px 0 40px;
  position: relative;
  z-index: 1;
  margin: 0 auto;
  background: #f8f8f8;
  color: #1b1a16; }
  .page_promotions-about-ttl {
    font-size: 36px;
    font-weight: 300;
    margin-bottom: 45px; }
  .page_promotions-about-cont {
    display: flex;
    margin-bottom: 26px; }
  .page_promotions-about-col-sm {
    width: 132px;
    flex: 0 0 132px;
    margin-right: 40px; }
  .page_promotions-about-img {
    text-align: center;
    padding-top: 7px; }
  .page_promotions-about-txt {
    font-size: 16px;
    font-weight: 500;
    line-height: 26px;
    letter-spacing: -0.9px; }
  .page_promotions-about-bl {
    margin-bottom: 25px; }
    .page_promotions-about-bl h3 {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 10px;
      font-weight: 500; }
    .page_promotions-about-bl p {
      font-size: 12px;
      line-height: 24px;
      font-weight: 400;
      margin-bottom: 10px; }

/*page_promotions*/
@media (min-width: 1200px) {
  .page_promotions-about .container {
    max-width: 927px; } }

@media (min-width: 992px) and (max-width: 1199px) {
  .page_promotions-ttl {
    font-size: 22px; } }

@media (max-width: 991px) {
  .page_promotions-top_content {
    display: block; }
  .page_promotions-top_img {
    margin-right: 0;
    max-width: none;
    text-align: center; }
  .page_promotions-ttl {
    text-align: center;
    font-size: 18px; }
  .page_promotions-end {
    text-align: center; }
  .page_promotions-date {
    margin-top: 25px;
    text-align: center;
    font-size: 14px; }
  .page_promotions-timer {
    justify-content: center;
    margin-top: 20px; }
  .page_promotions-about-cont {
    flex-wrap: wrap; }
  .page_promotions-about-col-sm {
    width: 100%;
    margin-bottom: 20px; }
  .page_promotions-about-col-big {
    width: 100%; } }

/*page_cooperation*/
.page_cooperation {
  padding: 0 0 50px; }
  .page_cooperation-top {
    max-width: 928px;
    margin: 20px auto 45px;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300; }
    .page_cooperation-top p {
      margin-bottom: 10px; }
  .page_cooperation-ttl {
    text-align: center; }
  .page_cooperation-img {
    margin-top: 25px;
    text-align: center;
    margin-bottom: 30px; }
  .page_cooperation-about {
    padding: 40px 0;
    position: relative;
    z-index: 1;
    max-width: 928px;
    margin: 0 auto;
    border-bottom: 1px solid #e7e6e6; }
    .page_cooperation-about-ttl {
      font-size: 36px;
      font-weight: 300;
      margin-bottom: 45px; }
    .page_cooperation-about-cont {
      display: flex;
      margin-bottom: 26px; }
    .page_cooperation-about-col-sm {
      width: 132px;
      flex: 0 0 132px;
      margin-right: 40px; }
    .page_cooperation-about-img {
      text-align: center;
      padding-top: 7px; }
    .page_cooperation-about-txt {
      font-size: 16px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: -0.9px; }
    .page_cooperation-about-bl {
      margin-bottom: 25px; }
      .page_cooperation-about-bl h2, .page_cooperation-about-bl h3 {
        font-size: 30px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 22px;
        text-transform: none;
        text-align: left; }
      .page_cooperation-about-bl p {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300;
        margin-bottom: 15px; }
        .page_cooperation-about-bl p:last-child {
          margin-bottom: 0; }

/*page_cooperation*/
@media (min-width: 992px) and (max-width: 1199px) {
  .page_cooperation-bl-ttl {
    font-size: 16px; }
  .page_cooperation-bl-txt {
    font-size: 12px; } }

@media (max-width: 991px) {
  .page_cooperation-bl-info {
    padding: 10px;
    padding-right: 40px;
    width: 96%; }
  .page_cooperation-bl-ttl {
    font-size: 16px; }
  .page_cooperation-bl-txt {
    font-size: 12px; }
  .page_cooperation-about-cont {
    flex-wrap: wrap; }
  .page_cooperation-about-col-sm {
    width: 100%;
    margin-bottom: 20px; }
  .page_cooperation-about-col-big {
    width: 100%; } }

/*page_clients*/
.page_clients-ttl {
  text-align: center; }

.page_clients h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px; }

.page_clients-cont {
  padding-bottom: 50px;
  border-bottom: 1px solid #E7E6E6; }
  .page_clients-cont p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 10px; }
    .page_clients-cont p.dfl {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .page_clients-cont p.dfl img {
        margin-bottom: 15px;
        max-width: 30%; }
  .page_clients-cont h2 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 25px;
    margin-top: 40px;
    text-align: left;
    text-transform: none; }
  .page_clients-cont h3 {
    font-size: 25px;
    line-height: 24px;
    margin-bottom: 15px; }
  .page_clients-cont h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }

.page_clients .info_sidebar {
  border-right: 1px solid #e7e6e6; }

.page_clients-list {
  margin-bottom: 50px; }

.page_clients .inner_bottom-seemore {
  margin-top: 20px; }

.page_clients .info_sidebar_title {
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px; }

/*page_clients*/
@media (max-width: 767px) {
  .page_clients-ttl {
    margin-top: 40px; }
  .page_clients-seemore-ttl {
    font-size: 24px;
    font-weight: 400;
    text-align: center; } }

/*about_products*/
.about_products-top {
  max-width: 928px;
  margin: 25px auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400; }
  .about_products-top p {
    font-weight: 300;
    margin-bottom: 10px; }
  .about_products-top h2 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 25px;
    margin-top: 35px;
    text-transform: none;
    text-align: left;
    font-weight: 400; }
  .about_products-top h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }

.about_products-ttl {
  text-align: center; }

.about_products-img {
  margin-top: 45px;
  text-align: center;
  margin-bottom: 60px; }

.about_products h3 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px; }

.about_products-cont {
  padding-bottom: 30px;
  border-bottom: 1px solid #E7E6E6;
  max-width: 928px;
  margin: 25px auto; }
  .about_products-cont p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px; }
  .about_products-cont h2 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 15px;
    margin-top: 15px;
    text-transform: none;
    text-align: left; }
  .about_products-cont h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }

.about_products-slider {
  max-width: 928px;
  margin: 40px auto 60px; }
  .about_products-slider .slick-list {
    margin: 0 -15px; }
  .about_products-slider .slick-prev {
    left: -65px;
    text-align: center; }
    .about_products-slider .slick-prev:hover path {
      stroke: #F3AD52; }
  .about_products-slider .slick-next {
    right: -50px;
    text-align: center; }
    .about_products-slider .slick-next:hover path {
      stroke: #F3AD52; }
  .about_products-slider img {
    display: inline-block;
    width: 100%;
    margin: 0 auto !important; }
  .about_products-slider .slick-dots li {
    padding: 0; }
    .about_products-slider .slick-dots li:before {
      display: none; }

.about_products-slide {
  text-align: center;
  padding: 0 15px; }

/*about_products*/
@media (max-width: 1199px) and (min-width: 992px) {
  .about_products-slider .slick-prev {
    left: -85px; }
  .about_products-slider .slick-next {
    right: -50px; } }

@media (max-width: 991px) {
  .about_products-slider .slick-dots {
    padding: 0; }
    .about_products-slider .slick-dots li button:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #c8c9c9;
      border-radius: 50%;
      opacity: 1; }
    .about_products-slider .slick-dots li.slick-active button:before {
      background: #000; } }

@media (max-width: 767px) {
  .about_products-seemore-ttl {
    font-size: 24px;
    font-weight: 400;
    text-align: center; } }

/*inner_bottom*/
.inner_bottom-seemore {
  padding: 35px 0 75px;
  margin-top: 50px;
  background: #f8f8f8; }
  .inner_bottom-seemore-ttl {
    margin-bottom: 25px;
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 300;
    text-align: center; }
  .inner_bottom-seemore-bl {
    margin: 0 auto 20px;
    display: block;
    max-width: 318px; }
    .inner_bottom-seemore-bl-img {
      display: block;
      text-align: center;
      max-height: 210px;
      overflow: hidden; }
      .inner_bottom-seemore-bl-img img {
        transform: scale(1);
        transition: 0.3s; }
    .inner_bottom-seemore-bl-ttl {
      font-size: 18px;
      line-height: 24px;
      font-weight: 300;
      display: block;
      margin-top: 10px;
      transition: 0.3s; }
    .inner_bottom-seemore-bl:hover .inner_bottom-seemore-bl-img img {
      transform: scale(1.2);
      transition: 0.3s; }
    .inner_bottom-seemore-bl:hover .inner_bottom-seemore-bl-ttl {
      color: #F3AD52;
      transition: 0.3s; }

/*inner_bottom*/
@media (max-width: 767px) {
  .inner_bottom-seemore-ttl {
    font-size: 24px;
    font-weight: 400;
    text-align: center; } }

/*info_sidebar*/
.info_sidebar {
  margin-top: 20px;
  min-height: 100%; }
  .info_sidebar h3 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }
  .info_sidebar-list a, .info_sidebar-list li {
    cursor: pointer;
    font-size: 13px;
    line-height: 24px;
    text-transform: uppercase;
    position: relative;
    border: 2px solid #f3f3f3;
    background: #f3f3f3;
    display: block;
    padding: 6px 10px;
    margin-bottom: 19px;
    padding-right: 27px;
    max-width: 287px; }
    .info_sidebar-list a:last-child, .info_sidebar-list li:last-child {
      margin-bottom: 0; }
    .info_sidebar-list a:hover, .info_sidebar-list a:focus, .info_sidebar-list a.active, .info_sidebar-list li:hover, .info_sidebar-list li:focus, .info_sidebar-list li.active {
      background: transparent;
      border-color: #f9c25e; }
    .info_sidebar-list a:after, .info_sidebar-list li:after {
      content: '';
      width: 10px;
      height: 6px;
      background: url(../img/sel_arr.png) no-repeat 50% 50%;
      position: absolute;
      display: block;
      right: 15px;
      top: 50%;
      margin-top: -3px;
      transform: rotate(-90deg); }

@media (max-width: 991px) {
  .info_sidebar {
    border: none !important; }
    .info_sidebar-list {
      font-size: 0;
      margin-left: -15px; }
      .info_sidebar-list li, .info_sidebar-list a {
        display: inline-block;
        width: 50%;
        margin-left: 15px; } }

@media (max-width: 767px) {
  .info_sidebar-list li, .info_sidebar-list a {
    width: auto; } }

@media (max-width: 480px) {
  .info_sidebar-list {
    margin-left: 0; }
    .info_sidebar-list li, .info_sidebar-list a {
      margin-left: 0;
      width: 100%;
      max-width: none; } }

/*info_sidebar*/
/*page_shop*/
.page_shop-top {
  max-width: 928px;
  margin: 25px auto;
  font-size: 14px;
  line-height: 24px;
  font-weight: 300; }
  .page_shop-top p {
    margin-bottom: 10px; }

.page_shop-slider {
  max-width: 825px;
  margin: 55px auto 10px;
  text-align: center; }
  .page_shop-slider .slick-prev {
    left: -85px;
    text-align: center; }
    .page_shop-slider .slick-prev:hover path {
      stroke: #F3AD52; }
  .page_shop-slider .slick-next {
    right: -68px;
    text-align: center; }
    .page_shop-slider .slick-next:hover path {
      stroke: #F3AD52; }
  .page_shop-slider img {
    display: inline-block; }

.page_shop-slider_pag {
  max-width: 825px;
  margin: 10px auto 25px;
  text-align: center;
  overflow: hidden; }
  .page_shop-slider_pag .slick-list {
    margin: 0 -6px; }
  .page_shop-slider_pag img {
    display: inline-block;
    cursor: pointer; }
  .page_shop-slider_pag .page_shop-slide {
    padding: 0 6px;
    opacity: 0.6; }
  .page_shop-slider_pag .slick-current .page_shop-slide {
    opacity: 1; }

.page_shop-slide {
  text-align: center; }

.page_shop-ttl {
  text-align: center; }

.page_shop h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px; }

.page_shop-cont {
  padding-bottom: 30px;
  border-bottom: 1px solid #E7E6E6;
  max-width: 928px;
  margin: 25px auto 0; }
  .page_shop-cont p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 10px; }
    .page_shop-cont p.dfl {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .page_shop-cont p.dfl img {
        margin-bottom: 15px;
        max-width: 30%; }
  .page_shop-cont h3 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 15px; }
  .page_shop-cont h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }
  .page_shop-cont ul {
    padding-left: 25px; }
    .page_shop-cont ul li {
      color: #F3AD52;
      padding-left: 35px;
      position: relative;
      color: #000;
      font-size: 14px;
      line-height: 36px;
      font-weight: 400; }
      .page_shop-cont ul li:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -2px;
        left: 15px;
        background: #F3AD52;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%; }
      .page_shop-cont ul li a {
        color: #000; }
        .page_shop-cont ul li a:hover {
          color: #F3AD52; }

.page_shop-btm {
  padding-top: 40px;
  padding-bottom: 90px;
  max-width: 928px;
  margin: 0 auto; }
  .page_shop-btm-bl {
    margin-bottom: 15px; }
    .page_shop-btm-bl-ttl {
      color: #F3AD52;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      margin-bottom: 18px; }
    .page_shop-btm-bl-info {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 8px; }
      .page_shop-btm-bl-info span {
        font-weight: 300; }

.page_shop-map .map {
  height: 550px; }

/*page_shop*/
@media (max-width: 991px) {
  .page_shop-slider .slick-dots {
    padding: 0; }
    .page_shop-slider .slick-dots li button:before {
      content: '';
      width: 10px;
      height: 10px;
      background: #c8c9c9;
      border-radius: 50%;
      opacity: 1; }
    .page_shop-slider .slick-dots li.slick-active button:before {
      background: #000; }
  .page_shop-slider_pag {
    margin: 0 auto; }
    .page_shop-slider_pag .slick-list {
      margin: 0 -10px; }
    .page_shop-slider_pag .page_shop-slide {
      padding: 0 10px; } }

@media (max-width: 991px) {
  .page_shop-map .map {
    height: 80vw; } }

@media (max-width: 991px) {
  .page_catalog_shop-map .map {
    height: 80vw; } }

@media (max-width: 767px) {
  .page_shop-slider_pag {
    display: none; } }

/*page_mattress*/
.page_mattress-top {
  max-width: 928px;
  margin: 25px auto 45px;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400; }
  .page_mattress-top p {
    font-weight: 400;
    margin-bottom: 10px; }

.page_mattress-anchors {
  text-align: center;
  background: #f8f8f8;
  padding: 40px 0; }
  .page_mattress-anchors-ttl {
    font-size: 18px;
    text-transform: uppercase;
    margin-bottom: 40px; }
  .page_mattress-anchors-line {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    max-width: 706px;
    margin: 0 auto 35px;
    position: relative; }
    .page_mattress-anchors-line:before {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      top: 14px;
      left: 0; }
    .page_mattress-anchors-line-num {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      background: #FFFFFF;
      border-radius: 50%;
      max-width: 27px;
      border: 1px solid #e5e5e5;
      margin: 0 auto 5px;
      padding: 3px 5px 0px;
      transition: 0.3s; }
    .page_mattress-anchors-line-txt {
      display: block;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      transition: 0.3s; }
    .page_mattress-anchors-line-bl {
      display: block;
      margin: 0 25px;
      z-index: 2;
      width: 106px; }
      .page_mattress-anchors-line-bl:hover .page_mattress-anchors-line-num, .page_mattress-anchors-line-bl:focus .page_mattress-anchors-line-num {
        background: #F3AD52;
        border-color: #F3AD52;
        color: #FFFFFF;
        transform: scale(1.2, 1.2);
        transition: 0.3s; }
      .page_mattress-anchors-line-bl:hover .page_mattress-anchors-line-txt, .page_mattress-anchors-line-bl:focus .page_mattress-anchors-line-txt {
        font-weight: 500;
        transition: 0.3s; }
  .page_mattress-anchors-btn {
    text-align: center; }
    .page_mattress-anchors-btn a {
      max-width: 228px;
      font-size: 15px;
      text-transform: uppercase;
      color: #FFFFFF;
      font-weight: 300;
      background: #F3AD52;
      text-align: center;
      display: inline-block;
      padding: 10px 24px;
      border: 2px solid transparent;
      opacity: 1;
      transition: 0.3s; }
      .page_mattress-anchors-btn a:hover, .page_mattress-anchors-btn a:focus {
        opacity: 0.7;
        transition: 0.3s; }

.page_mattress-ttl {
  text-align: center; }

.page_mattress h4 {
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  margin-bottom: 20px; }

.page_mattress-cont {
  padding-bottom: 30px;
  max-width: 928px;
  margin: 65px auto 25px; }
  .page_mattress-cont p {
    font-size: 14px;
    font-weight: 300;
    line-height: 24px;
    margin-bottom: 15px; }
    .page_mattress-cont p.dfl {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap; }
      .page_mattress-cont p.dfl img {
        margin-bottom: 15px;
        max-width: 30%; }
  .page_mattress-cont h2 {
    font-size: 30px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 22px;
    text-align: left;
    text-transform: none; }
  .page_mattress-cont h3 {
    font-size: 30px;
    line-height: 24px;
    margin-bottom: 25px; }
  .page_mattress-cont h4 {
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 20px; }

.page_mattress-table {
  background: #f8f8f8;
  padding: 50px 0; }
  .page_mattress-table-wp {
    max-width: 100%;
    overflow-x: auto;
    overflow-y: hidden; }
    .page_mattress-table-wp table {
      width: 100%;
      text-align: left; }
      .page_mattress-table-wp table th {
        vertical-align: middle;
        font-size: 14px;
        line-height: 36px;
        color: #F3AD52;
        text-transform: uppercase;
        padding: 5px 0;
        padding-right: 15px;
        border-bottom: 1px solid #d7d7d6; }
      .page_mattress-table-wp table td {
        padding: 18px 0;
        padding-right: 15px;
        border-bottom: 1px solid #d7d7d6; }
        .page_mattress-table-wp table td.name {
          font-size: 16px;
          line-height: 36px; }
          .page_mattress-table-wp table td.name b {
            font-weight: 500; }
        .page_mattress-table-wp table td.step {
          font-size: 14px;
          line-height: 24px; }
          .page_mattress-table-wp table td.step b {
            font-weight: 500; }
        .page_mattress-table-wp table td.chars {
          color: #626260;
          font-size: 14px;
          line-height: 18px; }
          .page_mattress-table-wp table td.chars b {
            font-weight: 600;
            color: #000; }
        .page_mattress-table-wp table td.for {
          font-size: 14px;
          line-height: 24px; }
      .page_mattress-table-wp table .name {
        min-width: 140px; }
      .page_mattress-table-wp table .step {
        min-width: 240px; }
      .page_mattress-table-wp table .chars {
        min-width: 260px; }
      .page_mattress-table-wp table .for {
        min-width: 490px; }
      .page_mattress-table-wp table tbody tr:last-child td {
        border-bottom: 0; }

.page_mattress-kinds {
  padding: 50px 0;
  position: relative; }
  .page_mattress-kinds:after {
    content: '';
    max-width: 1130px;
    margin: 0 auto;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 1px;
    background: #E7E6E6; }
  .page_mattress-kinds-ttl {
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 300;
    margin-bottom: 50px;
    text-align: center; }
  .page_mattress-kinds-bl {
    margin-bottom: 40px; }
    .page_mattress-kinds-bl-ttl {
      color: #F3AD52;
      font-size: 30px;
      font-weight: 500;
      margin-bottom: 15px; }
    .page_mattress-kinds-bl-top {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 15px; }
      .page_mattress-kinds-bl-top b {
        font-weight: 500; }
    .page_mattress-kinds-bl-img {
      text-align: center;
      margin-bottom: 25px; }
      .page_mattress-kinds-bl-img img {
        width: 100%;
        max-width: 550px; }
    .page_mattress-kinds-bl-full {
      font-size: 13px;
      line-height: 20px;
      margin-bottom: 15px; }
      .page_mattress-kinds-bl-full span {
        text-transform: uppercase;
        font-weight: 500; }
    .page_mattress-kinds-bl-txt {
      font-size: 14px;
      line-height: 24px;
      font-weight: 300;
      margin-bottom: 15px;
      height: 96px;
      overflow: hidden; }
      .page_mattress-kinds-bl-txt.active {
        height: auto; }
    .page_mattress-kinds-bl-url {
      text-align: right; }
      .page_mattress-kinds-bl-url a {
        font-size: 13px;
        line-height: 24px;
        text-decoration: underline; }
        .page_mattress-kinds-bl-url a:focus {
          text-decoration: underline; }
        .page_mattress-kinds-bl-url a:hover {
          text-decoration: none; }
  .page_mattress-kinds .col-lg-6:nth-child(2n+1) {
    padding-right: 35px; }
  .page_mattress-kinds .col-lg-6:nth-child(2n) {
    padding-left: 35px; }

/*page_mattress*/
@media (min-width: 768px) and (max-width: 991px) {
  .page_mattress-table-wp table .name {
    min-width: 90px; }
  .page_mattress-table-wp table .step {
    min-width: 150px; }
  .page_mattress-table-wp table .chars {
    min-width: 220px; }
  .page_mattress-table-wp table .for {
    min-width: 220px; }
  .page_mattress-table-wp table th {
    font-size: 11px; } }

@media (max-width: 767px) {
  .page_mattress-anchors-line-bl {
    margin: 0 5px;
    width: 80px; }
  .page_mattress-anchors-line-txt {
    font-weight: 300;
    font-size: 8px; } }

@media (max-width: 991px) {
  .page_mattress-kinds .col-lg-6:nth-child(2n+1) {
    padding-right: 15px; }
  .page_mattress-kinds .col-lg-6:nth-child(2n) {
    padding-left: 15px; } }

@media (max-width: 767px) {
  .page_mattress-table .container {
    max-width: 100%; }
  .page_mattress-table-wp table th {
    font-size: 14px;
    line-height: 1.5; }
  .page_mattress-table-wp table td.name {
    font-size: 14px;
    line-height: 1.5; }
  .page_mattress-table-wp table td.step {
    font-size: 14px;
    line-height: 1.5; }
  .page_mattress-table-wp table td.chars {
    font-size: 14px;
    line-height: 1.5; }
  .page_mattress-table-wp table td.for {
    font-size: 14px;
    line-height: 1.5; }
  .page_mattress-table-wp table .name {
    min-width: 12vw; }
  .page_mattress-table-wp table .step {
    min-width: 12vw; }
  .page_mattress-table-wp table .for {
    min-width: 12vw; }
  .page_mattress-table-wp table .chars {
    min-width: 12vw; } }

@media (max-width: 480px) {
  .page_mattress-table-wp table th {
    font-size: 3.3vw;
    line-height: 1.5; }
  .page_mattress-table-wp table td.name {
    font-size: 3.3vw;
    line-height: 1.5; }
  .page_mattress-table-wp table td.step {
    font-size: 3.3vw;
    line-height: 1.5; }
  .page_mattress-table-wp table td.chars {
    font-size: 3.3vw;
    line-height: 1.5; }
  .page_mattress-table-wp table td.for {
    font-size: 3.3vw;
    line-height: 1.5; } }

/*checkout-page*/
.checkout-page {
  padding: 25px 0 70px; }
  .checkout-page .info_sidebar {
    margin-left: auto;
    margin-top: 0;
    padding-top: 7px;
    max-width: 320px;
    position: relative; }
    .checkout-page .info_sidebar h3 {
      font-size: 18px;
      font-weight: 300; }
  .checkout-page-ttl {
    text-align: center;
    margin-top: 31px; }
  .checkout-page .private_office-content {
    padding-top: 7px;
    padding-right: 77px; }
  .checkout-page .form_title {
    margin-bottom: 11px; }
  .checkout-page .form_select {
    margin-bottom: 15px; }
  .checkout-page .form_textarea {
    margin-bottom: 25px; }
  .checkout-page-cart-img {
    text-align: center;
    margin-bottom: 30px;
    padding-top: 40px; }
  .checkout-page-cart-ttl {
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    text-decoration: underline;
    margin-bottom: 20px;
    display: block; }
  .checkout-page-cart .left {
    width: 58%;
    padding-right: 10px; }
  .checkout-page-cart .right {
    width: 42%; }
  .checkout-page-cart-params {
    margin-bottom: 25px; }
  .checkout-page-cart-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px; }
  .checkout-page-cart-cnt {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 5px; }
    .checkout-page-cart-cnt .right {
      text-align: right; }
      .checkout-page-cart-cnt .right .num {
        background: none;
        vertical-align: middle;
        color: #000; }
      .checkout-page-cart-cnt .right .minus, .checkout-page-cart-cnt .right .plus {
        border-radius: 50%;
        font-size: 22px;
        width: 30px;
        height: 30px;
        vertical-align: middle;
        cursor: pointer; }
  .checkout-page-cart-price {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 10px; }
    .checkout-page-cart-price .right {
      text-align: right; }
  .checkout-page-cart-deliv {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px; }
    .checkout-page-cart-deliv .right {
      text-align: right; }
  .checkout-page-cart-all {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px; }
    .checkout-page-cart-all .right {
      font-size: 18px;
      font-weight: 600;
      text-align: right; }
  .checkout-page-cart-prepay {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    margin-bottom: 15px; }
    .checkout-page-cart-prepay .right {
      font-size: 18px;
      font-weight: 600;
      text-align: right; }
  .checkout-page-cart-und {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    font-size: 13px;
    line-height: 24px;
    margin-top: -20px; }
    .checkout-page-cart-und .right {
      text-align: right; }
  .checkout-page-cart-info {
    margin-top: 30px;
    font-size: 14px;
    line-height: 1.33; }
  .checkout-page .form_submit {
    margin-top: 35px; }

/*checkout-page*/
@media (min-width: 992px) {
  .checkout-page .info_sidebar:before {
    content: '';
    background: #e7e6e6;
    width: 1px;
    height: 100%;
    position: absolute;
    left: -14px;
    top: 40px; } }

@media (min-width: 1200px) {
  .checkout-page .info_sidebar:before {
    left: -60px; }
  .checkout-page .container {
    max-width: 1163px; } }

@media (max-width: 991px) {
  .checkout-page {
    padding: 25px 0 70px; }
    .checkout-page .info_sidebar {
      margin: 0 auto; }
      .checkout-page .info_sidebar h3 {
        text-align: center; } }

/*modal_window*/
.modal_window {
  max-width: 320px;
  width: 100%; }
  .modal_window.from_button_like .modal_window-ttl {
    margin-bottom: 20px; }
  .modal_window.from_button_like .modal_window-sub_ttl {
    display: block;
    font-size: 13px;
    line-height: 1.33;
    margin-bottom: 25px; }
  .modal_window.mattr {
    max-width: 600px; }
    .modal_window.mattr img {
      max-width: 100%; }
  .modal_window-wp .fancybox-skin {
    border-radius: 0px;
    background: #FFFFFF;
    padding: 50px 58px !important; }
  .modal_window-wp .fancybox-close {
    background: none;
    right: 5px;
    top: 5px; }
    .modal_window-wp .fancybox-close:before {
      content: '×';
      font-size: 40px;
      font-weight: 400;
      color: #AEAEAE; }
  .modal_window-wp .fancybox-title.fancybox-title-float-wrap {
    display: none; }
  .modal_window-wp.options_2 .page_mattress-kinds-bl-img {
    display: none; }
  .modal_window-wp.options_2 .js_modal_mattress_content {
    margin: 1px 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 18px; }
  .modal_window-wp.options_3 .modal_window {
    width: auto;
    max-width: 100%; }
  .modal_window-wp.options_3 .page_mattress-kinds-bl-img {
    margin-bottom: 0; }
  .modal_window-wp.options_3 .js_modal_mattress_content {
    display: none; }
  .modal_window-ttl {
    color: #1B1A16;
    font-size: 24px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 40px;
    text-transform: uppercase; }
  .modal_window-sub_ttl {
    display: none; }
  .modal_window-txt {
    color: #1B1A16;
    font-size: 13px;
    line-height: 18px;
    font-weight: 300;
    margin-bottom: 20px; }
  .modal_window-soc {
    color: #1B1A16;
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-top: 20px; }
    .modal_window-soc span {
      display: block;
      margin-bottom: 10px; }
  .modal_window-urls {
    margin-bottom: 40px; }
    .modal_window-urls a {
      font-size: 13px;
      line-height: 24px;
      font-weight: 300;
      border-bottom: 1px dashed #000;
      margin-right: 15px; }
    .modal_window-urls span {
      font-size: 13px;
      line-height: 24px;
      font-weight: 300; }
  .modal_window .message {
    font-size: 13px;
    line-height: 1.33;
    margin-bottom: 5px; }

#modal_forgot .modal_window-ttl {
  margin-bottom: 15px; }

.modal_forgot .staticErrMsg {
  font-size: 13px;
  line-height: 1.33;
  margin-bottom: 5px; }

/*modal_window*/
@media (max-width: 991px) {
  .modal_window-wp .fancybox-skin {
    padding: 45px 20px 30px !important; } }

/*page_product*/
.page_product-ttl {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
  text-transform: none;
  padding: 15px 0;
  padding-left: 60px; }
  .page_product-ttl span {
    font-size: 15px;
    line-height: 24px;
    font-weight: 400; }

.page_product-price {
  text-align: center;
  padding-left: 24px; }
  .page_product-price .cur {
    font-size: 18px;
    line-height: 24px;
    font-weight: 500; }
    .page_product-price .cur span {
      font-size: 24px; }
  .page_product-price .old {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    color: #626260;
    text-decoration: line-through; }

.page_product-actions {
  display: flex;
  width: 189px;
  float: right;
  flex-wrap: wrap; }

.page_product-parts {
  position: relative; }
  .page_product-parts-btn {
    width: 40px;
    height: 40px;
    border: 1px solid #f8af43;
    position: relative;
    display: block; }
    .page_product-parts-btn:before {
      content: '';
      width: 24px;
      height: 24px;
      background: url("../img/pay_part.png") no-repeat 50% 0%;
      position: absolute;
      top: 7px;
      left: 7px; }
    .page_product-parts-btn:hover, .page_product-parts-btn:focus {
      background: #F3AD52;
      border-color: #F3AD52; }
      .page_product-parts-btn:hover:before, .page_product-parts-btn:focus:before {
        background: url("../img/pay_part.png") no-repeat 50% 100%; }
  .page_product-parts-hidden {
    cursor: pointer;
    overflow: hidden;
    width: 0;
    position: absolute;
    left: 100%;
    margin-left: 0px;
    top: 0;
    font-size: 13px;
    border-color: #f8af43;
    border-style: solid;
    border-width: 1px 0px;
    line-height: 36px;
    text-align: center;
    padding: 1px 0;
    transition: 0.3s;
    white-space: nowrap; }
    .page_product-parts-hidden:hover {
      background: #F3AD52 !important;
      border-color: #F3AD52;
      color: #FFFFFF; }
  .page_product-parts:hover .page_product-parts-hidden {
    width: 140px;
    margin-left: 9px;
    border-width: 1px;
    padding: 1px 10px;
    background: #FFFFFF;
    transition: 0.3s; }

.page_product-buy button {
  width: 140px;
  margin-left: 9px;
  font-size: 14px;
  line-height: 36px;
  border: 0;
  color: #FFFFFF;
  text-align: center;
  padding: 1px 0;
  transition: 0.3s;
  padding: 0 8px;
  border: 2px solid transparent;
  text-transform: uppercase;
  background: #F3AD52;
  cursor: pointer; }
  .page_product-buy button:hover {
    background: #f7b132; }

.page_product-mnth {
  width: 100%;
  text-align: right;
  font-size: 14px;
  margin-top: 12px;
  padding-right: 17px; }

.page_product-thumbs {
  max-width: 96px;
  margin: 0px 0;
  padding: 20px 0; }
  .page_product-thumbs-slide {
    margin: 10px 0;
    cursor: pointer;
    position: relative; }
    .page_product-thumbs-slide .img-origin {
      position: relative;
      z-index: 2; }
    .page_product-thumbs-slide .img-mask {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .page_product-thumbs-slide .canvas-mask {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .95;
      filter: brightness(0.9) brightness(0.9) contrast(1.3) contrast(1.3) saturate(150%); }
  .page_product-thumbs .slick-arrow {
    width: 18px;
    height: 10px;
    background: url("../img/thumb_arrs.png") no-repeat;
    left: 0;
    right: 0;
    margin: 0 auto; }
  .page_product-thumbs .slick-prev {
    background-position: 50% 0%;
    top: 5px; }
    .page_product-thumbs .slick-prev:before {
      display: none; }
  .page_product-thumbs .slick-next {
    background-position: 50% 100%;
    top: auto;
    bottom: 0; }
    .page_product-thumbs .slick-next:before {
      display: none; }
  .page_product-thumbs .slick-slide {
    opacity: 0.33; }
  .page_product-thumbs .slick-current {
    opacity: 1; }

.page_product-link {
  max-width: 96px;
  margin-top: 30px;
  text-align: center; }
  .page_product-link a {
    opacity: 1;
    transition: 0.3s;
    display: inline-block; }
    .page_product-link a:hover, .page_product-link a:focus {
      opacity: 0.7;
      transition: 0.3s; }

.page_product-slider_block {
  font-size: 0; }
  .page_product-slider_block:before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    height: 100%; }

.page_product-sliders {
  margin-bottom: 50px; }

.page_product-slider {
  margin-top: 0px;
  display: inline-block;
  vertical-align: middle;
  width: 100%;
  position: relative;
  z-index: 1; }
  .page_product-slider-slide {
    text-align: center;
    position: relative; }
    .page_product-slider-slide img {
      display: inline-block; }
    .page_product-slider-slide .img-origin {
      position: relative;
      z-index: 2; }
    .page_product-slider-slide .img-mask {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0; }
    .page_product-slider-slide .canvas-mask {
      z-index: 1;
      max-width: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      opacity: .95;
      filter: brightness(0.9) brightness(0.9) contrast(1.3) contrast(1.3) saturate(150%); }
  .page_product-slider .slick-arrow {
    width: 20px;
    height: 36px;
    background: url("../img/pp-sldr_arrs.png") no-repeat;
    top: 0;
    bottom: 0;
    margin: auto 0; }
  .page_product-slider .slick-prev {
    background-position: 0% 50%;
    left: 0;
    right: auto; }
    .page_product-slider .slick-prev:before {
      display: none; }
  .page_product-slider .slick-next {
    background-position: 100% 50%;
    left: auto;
    right: 0; }
    .page_product-slider .slick-next:before {
      display: none; }

.page_product-like {
  text-align: right;
  position: absolute;
  top: 0;
  right: 15px;
  z-index: 2; }
  .page_product-like button {
    background: none;
    border: 0; }
    .page_product-like button:hover svg path {
      stroke: #F3AD52; }
    .page_product-like button.active svg path {
      stroke: #F3AD52;
      fill: #F3AD52; }

.page_product-size {
  padding-bottom: 5px;
  max-width: 840px;
  margin: 0 auto;
  margin-top: 20px; }
  .page_product-size-ttl {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 11px; }
  .page_product-size-line .irs-line {
    background: none;
    height: 7px;
    top: 32px;
    cursor: pointer; }
  .page_product-size-line .irs-handle.single {
    top: 25px;
    margin-left: -8px;
    background: #F3AD52;
    border-color: #F3AD52;
    color: #fff;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    cursor: pointer;
    transform: translateX(6px); }
  .page_product-size-line .irs-handle.single i {
    display: none; }
  .page_product-size-line .irs-bar.irs-bar--single {
    display: none; }
  .page_product-size-line .irs-grid-text {
    top: -37px;
    bottom: auto;
    font-weight: 300;
    font-size: 14px;
    color: #000;
    font-family: 'Montserrat', sans-serif; }
  .page_product-size-line .irs-grid-pol {
    background: #fff;
    margin-left: -7px;
    border-radius: 50%;
    max-width: 27px;
    border: 1px solid #bdbdbd;
    padding: 3px 5px 0;
    width: 12px;
    height: 12px;
    font-weight: 500;
    top: -10px;
    pointer-events: none; }
  .page_product-size-line .irs-grid-pol.small {
    display: none; }
  .page_product-size-line .irs-line:before {
    background: #E5E5E4;
    height: 1px;
    top: 50%;
    content: '';
    width: 100%;
    display: block;
    left: 0;
    position: absolute;
    margin-top: 0px; }
  .page_product-size-line .irs--flat .irs-single {
    background: #fff;
    z-index: 33;
    bottom: auto;
    top: -3px;
    transform: translateX(0px);
    padding-left: 8px;
    padding-right: 4px;
    font-size: 14px;
    color: #000;
    font-weight: 500;
    font-family: 'Montserrat',sans-serif; }
  .page_product-size-line .irs--flat .irs-single:before {
    display: none; }
  .page_product-size-line2 {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin: 0 auto 35px;
    position: relative; }
    .page_product-size-line2:before {
      content: '';
      z-index: 1;
      position: absolute;
      width: 100%;
      height: 1px;
      background: #e5e5e5;
      bottom: 6px;
      left: 0; }
    .page_product-size-line2-circ {
      display: block;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
      background: #FFFFFF;
      border-radius: 50%;
      max-width: 27px;
      border: 1px solid #e5e5e5;
      margin: 15px auto 0px;
      padding: 3px 5px 0px;
      width: 12px;
      height: 12px;
      transition: 0.3s; }
    .page_product-size-line2-txt {
      display: block;
      font-weight: 300;
      font-size: 14px;
      line-height: 22px;
      transition: 0.3s; }
    .page_product-size-line2-bl {
      display: block;
      margin: 0 5px;
      z-index: 2;
      width: 70px;
      cursor: pointer;
      text-align: center; }
      .page_product-size-line2-bl:hover .page_product-size-line2-circ, .page_product-size-line2-bl:focus .page_product-size-line2-circ, .page_product-size-line2-bl.active .page_product-size-line2-circ {
        background: #F3AD52;
        border-color: #F3AD52;
        color: #FFFFFF;
        transform: scale(1.4, 1.4);
        transition: 0.3s; }
      .page_product-size-line2-bl:hover .page_product-size-line2-txt, .page_product-size-line2-bl:focus .page_product-size-line2-txt, .page_product-size-line2-bl.active .page_product-size-line2-txt {
        font-weight: 500;
        transition: 0.3s; }

.page_product-colors {
  margin-top: 20px;
  padding-bottom: 5px;
  max-width: 840px;
  margin: 0 auto;
  margin-top: 20px; }
  .page_product-colors-ttl {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 13px; }
  .page_product-colors-tabs {
    display: flex;
    justify-content: center;
    align-items: flex-start; }
  .page_product-colors-tab {
    font-size: 14px;
    line-height: 25px;
    flex-grow: 1;
    border-bottom: 1px solid #E7E6E6;
    margin-bottom: 1px;
    text-align: center;
    padding-bottom: 2px;
    height: 28px; }
    .page_product-colors-tab.active {
      margin-bottom: 0px;
      border-width: 2px;
      border-color: #F3AD52;
      font-weight: 600;
      height: 29px; }
    .page_product-colors-tab:hover {
      border-color: #f8af43; }
  .page_product-colors-cont {
    display: none;
    padding-top: 24px; }
    .page_product-colors-cont.active {
      display: block; }
  .page_product-colors-subttl {
    text-transform: uppercase;
    font-weight: 600;
    font-size: 14px; }
    .page_product-colors-subttl img {
      display: inline-block;
      vertical-align: middle;
      transform: translateY(-1px); }
  .page_product-colors-row {
    display: flex;
    flex-wrap: wrap;
    width: calc(100% + 10px);
    margin: 0 -5px;
    margin-top: 8px; }
  .page_product-colors-hidden {
    display: none;
    position: absolute;
    padding: 30px;
    padding-bottom: 15px;
    background: #fff;
    bottom: 120%;
    width: auto;
    width: max-content;
    z-index: 34;
    box-shadow: 0 0 26px 2px #0000001c;
    left: 50%;
    transform: translateX(-50%); }
    .page_product-colors-hidden-ttl {
      text-align: center;
      font-size: 14px;
      font-weight: 300;
      line-height: 36px;
      margin-top: 10px; }
  .page_product-colors-bl {
    border: 2px solid transparent;
    padding: 3px;
    width: 67px;
    height: 67px;
    margin: 0 5px 5px;
    cursor: pointer;
    position: relative; }
    .page_product-colors-bl > img {
      width: 57px;
      height: 57px; }
    .page_product-colors-bl:hover, .page_product-colors-bl.active {
      border-color: #F3AD52; }
    .page_product-colors-bl:hover .page_product-colors-hidden {
      display: block; }
  .page_product-colors-mob-more {
    display: none; }

.page_product-cloth_type {
  display: none; }

.page_product-bok {
  padding-bottom: 5px;
  max-width: 840px;
  margin: 0 auto;
  margin-top: 20px; }
  .page_product-bok-ttl {
    text-transform: uppercase;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 11px; }
  .page_product-bok-row {
    display: flex;
    flex-wrap: wrap; }
  .page_product-bok-bl {
    padding: 7px;
    width: 168px;
    cursor: pointer;
    position: relative; }
    .page_product-bok-bl-img {
      display: block;
      text-align: center; }
      .page_product-bok-bl-img img {
        width: 100%;
        max-width: 123px; }
    .page_product-bok-bl-ttl {
      display: block;
      text-align: center;
      font-size: 13px;
      line-height: 36px;
      font-weight: 400; }
    .page_product-bok-bl-hidden {
      display: none;
      position: absolute;
      padding: 30px 30px;
      background: #fff;
      bottom: 120%;
      width: auto;
      width: max-content;
      z-index: 3;
      box-shadow: 0 0 26px 2px #0000001c;
      left: 50%;
      transform: translateX(-50%);
      max-width: 400px; }
      .page_product-bok-bl-hidden-ttl {
        font-size: 16px;
        line-height: 36px;
        font-weight: 500;
        text-transform: uppercase; }
      .page_product-bok-bl-hidden-subttl {
        font-weight: 500;
        font-size: 13px;
        line-height: 18px;
        margin-bottom: 10px; }
      .page_product-bok-bl-hidden-txt {
        font-size: 14px;
        line-height: 24px;
        font-weight: 300; }
    .page_product-bok-bl:hover {
      box-shadow: inset 0px 0px 0px 1px #F3AD52; }
      .page_product-bok-bl:hover .page_product-bok-bl-hidden {
        display: block; }
    .page_product-bok-bl.active {
      box-shadow: inset 0px 0px 0px 2px #F3AD52; }

.page_product-data {
  background: #f8f8f8;
  padding: 45px 0 67px; }
  .page_product-data-tabs {
    padding-left: 19px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-right: 73px;
    max-width: 920px;
    position: relative; }
    .page_product-data-tabs:before {
      content: '';
      position: absolute;
      bottom: 2px;
      height: 1px;
      background: #E7E6E6;
      width: calc(100% - 15px);
      z-index: 1; }
  .page_product-data-tab {
    font-size: 16px;
    line-height: 25px;
    border-bottom: 2px solid transparent;
    margin-bottom: 1px;
    text-align: center;
    padding-bottom: 13px;
    position: relative;
    z-index: 2; }
    .page_product-data-tab.active {
      margin-bottom: 0px;
      border-width: 2px;
      border-color: #F3AD52;
      font-weight: 600; }
    .page_product-data-tab:hover {
      border-color: #F3AD52; }
  .page_product-data-text {
    display: none; }
    .page_product-data-text > * {
      display: none;
      margin-bottom: 10px; }
    .page_product-data-text > *:first-child {
      display: block; }
    .page_product-data-text .show_more {
      text-align: right;
      padding-top: 14px;
      display: block; }
      .page_product-data-text .show_more span {
        font-size: 13px;
        line-height: 24px;
        font-weight: 400;
        border-bottom: 1px solid #000;
        cursor: pointer;
        display: inline !important;
        transition: 0.3s; }
        .page_product-data-text .show_more span:hover {
          color: #F3AD52;
          border-bottom: 1px solid #F3AD52;
          transition: 0.3s; }
  .page_product-data-video-wrap {
    position: relative;
    padding-bottom: 56.25%;
    height: 0; }
    .page_product-data-video-wrap iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%; }
  .page_product-data-cont {
    font-size: 13px;
    line-height: 24px;
    font-weight: 300;
    padding-left: 19px;
    margin-top: 30px;
    max-width: 920px; }
    .page_product-data-cont-mob {
      display: none;
      position: relative; }
      .page_product-data-cont-mob:after {
        content: '';
        width: 14px;
        height: 14px;
        background: url(../img/pps-arr.png) no-repeat 50% 50%;
        position: absolute;
        top: 50%;
        right: 12px;
        margin-top: -7px;
        transform: rotate(0deg);
        transition: .3s; }
    .page_product-data-cont.active .page_product-data-text {
      display: block; }
    .page_product-data-cont.active .page_product-data-cont-mob:after {
      transform: rotate(180deg); }
    .page_product-data-cont.full .page_product-data-text > * {
      display: block; }
    .page_product-data-cont ul li {
      color: #F3AD52;
      padding-left: 35px;
      position: relative;
      color: #000000;
      font-size: 13px;
      line-height: 26px;
      font-weight: 300; }
      .page_product-data-cont ul li:before {
        content: '';
        position: absolute;
        top: 50%;
        margin-top: -2px;
        left: 15px;
        background: #F3AD52;
        display: block;
        width: 5px;
        height: 5px;
        border-radius: 50%; }
      .page_product-data-cont ul li a {
        color: #000; }
        .page_product-data-cont ul li a:hover {
          color: #F3AD52; }
  .page_product-data-sticker {
    display: block;
    padding-top: 36px; }
    .page_product-data-sticker img {
      margin-right: 6px; }
  .page_product-data-change {
    max-width: 400px; }
    .page_product-data-change .left {
      width: 58%;
      padding-right: 10px; }
    .page_product-data-change .right {
      width: 42%; }
    .page_product-data-change-row {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      font-size: 13px;
      line-height: 16px;
      margin-bottom: 10px; }

.page_product-cheap {
  padding: 70px 0 0px; }
  .page_product-cheap-cont {
    max-width: 898px;
    margin: 0 auto;
    padding: 24px 49px;
    border: 1px solid #E7E6E6; }
  .page_product-cheap-ttl {
    color: #F75B45;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px; }
  .page_product-cheap-row {
    display: flex;
    padding: 15px 0;
    justify-content: space-between;
    align-items: flex-start; }
  .page_product-cheap-bl {
    max-width: 188px;
    width: 100%; }
    .page_product-cheap-bl-img {
      position: relative;
      padding: 28px 0;
      text-align: center;
      margin-bottom: 15px;
      display: block;
      opacity: 1;
      transition: 0.3s; }
      .page_product-cheap-bl-img:hover {
        opacity: 0.7;
        transition: 0.3s; }
    .page_product-cheap-bl-stickers {
      position: absolute;
      right: -6px;
      top: 0px;
      width: 100%;
      text-align: right; }
      .page_product-cheap-bl-stickers .akc {
        background: url(../img/akc.png);
        width: 66px;
        height: 26px;
        margin-bottom: 5px;
        font-size: 15px;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        padding: 2px 4px 9px 15px;
        display: inline-block;
        text-align: center; }
    .page_product-cheap-bl-ttl {
      font-size: 14px;
      line-height: 18px;
      display: block; }
      .page_product-cheap-bl-ttl:hover {
        color: #F3AD52; }
    .page_product-cheap-bl-price {
      font-size: 14px;
      font-weight: 500;
      padding-top: 11px; }
      .page_product-cheap-bl-price span {
        font-size: 18px;
        line-height: 24px;
        font-weight: 600; }
      .page_product-cheap-bl-price .old {
        color: #626260;
        font-size: 14px;
        line-height: 16px;
        text-decoration: line-through;
        font-weight: 300; }
      .page_product-cheap-bl-price .cur {
        font-size: 14px;
        font-weight: 500;
        line-height: 24px;
        color: #F75B45; }
        .page_product-cheap-bl-price .cur span {
          font-size: 18px;
          line-height: 24px;
          font-weight: 600; }
  .page_product-cheap-plus {
    margin: 0 15px;
    padding-top: 59px; }
  .page_product-cheap-equal {
    margin: 0 15px;
    padding-top: 59px; }
  .page_product-cheap-result {
    max-width: 225px;
    width: 100%;
    padding: 15px 0; }
    .page_product-cheap-result-price {
      font-size: 30px;
      line-height: 24px;
      font-weight: 700;
      color: #F75B45;
      text-align: center; }
    .page_product-cheap-result-btn {
      font-size: 14px;
      line-height: 36px;
      padding: 0 26px;
      border: 2px solid transparent;
      color: #FFFFFF;
      text-transform: uppercase;
      background: #F3AD52;
      display: inline-block;
      cursor: pointer;
      width: 100%;
      max-width: 225px;
      margin: 15px auto;
      font-weight: 300;
      text-align: center; }
      .page_product-cheap-result-btn:hover {
        background: #f7b132; }
    .page_product-cheap-result-econ {
      font-size: 14px;
      line-height: 24px;
      color: #F75B45;
      text-align: center; }

.page_product .popular-ttl {
  font-size: 30px; }

.page_product .novelty-ttl {
  font-size: 30px; }

.page_product-sidebar {
  border-left: 1px solid #E7E6E6;
  padding-left: 29px;
  margin-top: 22px;
  padding-top: 3px;
  margin-bottom: 50px;
  cursor: pointer; }
  .page_product-sidebar-prom {
    padding: 5px 0 20px;
    border-bottom: 1px solid #E7E6E6;
    margin-bottom: 15px; }
    .page_product-sidebar-prom .title {
      font-size: 18px;
      font-weight: 500;
      color: #F75B45;
      line-height: 1.33;
      margin-bottom: 10px; }
    .page_product-sidebar-prom .terms_link {
      display: inline-block;
      font-size: 13px;
      font-weight: 300;
      line-height: 1.33;
      color: #1B1A16;
      border-bottom: 1px dashed #1B1A16;
      margin-bottom: 10px;
      transition: all 0.25s ease-in-out 0s; }
      .page_product-sidebar-prom .terms_link:hover {
        border-color: transparent; }
    .page_product-sidebar-prom .terms_text {
      display: none;
      font-size: 14px;
      font-weight: 300;
      line-height: 1.5;
      padding-bottom: 10px; }
    .page_product-sidebar-prom .promo_timer {
      margin: 0; }
      .page_product-sidebar-prom .promo_timer > span {
        width: 60px; }
      .page_product-sidebar-prom .promo_timer > span.sep {
        width: auto;
        margin: 0 8px; }
  .page_product-sidebar-ttl {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 23px; }
  .page_product-sidebar-options {
    margin-bottom: 30px; }
  .page_product-sidebar-burg {
    margin-bottom: 19px; }
    .page_product-sidebar-burg:last-child {
      margin-bottom: 0; }
    .page_product-sidebar-burg-name {
      background: #f3f3f3;
      border: 2px solid #f3f3f3;
      font-size: 13px;
      line-height: 24px;
      text-transform: uppercase;
      display: block;
      padding: 4px 6px;
      padding-left: 32px;
      position: relative;
      cursor: pointer;
      transition: 0.3s; }
      .page_product-sidebar-burg-name:hover, .page_product-sidebar-burg-name:focus {
        border-color: #F3AD52;
        background: #FFFFFF;
        transition: 0.3s; }
      .page_product-sidebar-burg-name:before {
        content: '';
        width: 14px;
        height: 14px;
        background: url("../img/pps-arr.png") no-repeat 50% 50%;
        position: absolute;
        top: 50%;
        left: 12px;
        margin-top: -7px;
        transform: rotate(-90deg);
        transition: 0.3s; }
      .page_product-sidebar-burg-name.active:before {
        transform: rotate(0deg);
        transition: 0.3s; }
    .page_product-sidebar-burg-cont {
      display: none;
      padding: 27px 0 14px;
      border-bottom: 1px solid #E7E6E6; }
      .page_product-sidebar-burg-cont .col-6:nth-child(2n + 1) {
        padding-right: 5px; }
      .page_product-sidebar-burg-cont .col-6:nth-child(2n) {
        padding-left: 5px; }
  .page_product-sidebar-bl-img {
    text-align: center;
    padding: 2px; }
  .page_product-sidebar-bl-ttl {
    text-align: center;
    font-size: 12px;
    line-height: 1.1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3px; }
    .page_product-sidebar-bl-ttl span {
      margin-right: 5px; }
    .page_product-sidebar-bl-ttl a {
      opacity: 1;
      transition: 0.3s;
      flex-shrink: 0;
      font-size: 0;
      line-height: 1; }
      .page_product-sidebar-bl-ttl a:hover {
        opacity: 0.6;
        transition: 0.3s; }
      .page_product-sidebar-bl-ttl a img {
        width: 100%; }
  .page_product-sidebar-bl:hover .page_product-sidebar-bl-img {
    box-shadow: inset 0px 0px 0px 1px #f8af43; }
  .page_product-sidebar-bl.active .page_product-sidebar-bl-img {
    box-shadow: inset 0px 0px 0px 2px #f8af43; }
  .page_product-sidebar-bl-htc {
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #FFFFFF;
    background: #F3AD52;
    display: block;
    text-transform: uppercase;
    padding: 10px 0px;
    border: 2px solid transparent;
    margin-top: 34px;
    transition: 0.3s; }
    .page_product-sidebar-bl-htc:hover, .page_product-sidebar-bl-htc:focus {
      background: #f7b132;
      color: #FFFFFF;
      transition: 0.3s; }
  .page_product-sidebar-change-ttl {
    font-size: 18px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 20px; }
  .page_product-sidebar-change .left {
    width: 58%;
    padding-right: 10px; }
  .page_product-sidebar-change .right {
    width: 42%; }
  .page_product-sidebar-change-row {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    font-size: 13px;
    line-height: 16px;
    margin-bottom: 10px; }

.page_product .page_catalog-showroom {
  z-index: 2;
  position: relative; }

.page_product-size-line {
  position: relative; }
  .page_product-size-line .points {
    width: 460px;
    max-width: 100%;
    margin: 0 auto; }
    .page_product-size-line .points:before {
      content: '';
      width: 100%;
      max-width: 666px;
      height: 1px;
      position: absolute;
      top: 10px;
      bottom: 0;
      right: 0;
      left: 0;
      margin: auto;
      background-color: #e5e5e4; }
  .page_product-size-line .irs-line:before {
    display: none; }

/*page_product*/
@media (min-width: 992px) and (max-width: 1199px) {
  .page_product-slider-slide {
    padding: 0 20px; }
  .page_product-slider .slick-arrow {
    top: 50%;
    margin-top: -18px;
    margin-bottom: 0; } }

@media (max-width: 991px) {
  .page_product-slider .slick-dots li button:before {
    content: '';
    width: 10px;
    height: 10px;
    background: #FFFFFF;
    border-radius: 50%;
    opacity: 1; }
  .page_product-slider .slick-dots li.slick-active button:before {
    background: #F3AD52;
    border: 1px solid #F3AD52; }
  .page_product-ttl {
    padding: 15px 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500; }
    .page_product-ttl span {
      font-size: 14px; }
  .page_product-size-ttl {
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    line-height: 36px; }
  .page_product-size-line {
    margin: 0px 0px;
    flex-wrap: wrap;
    justify-content: flex-start; }
    .page_product-size-line:before {
      display: none; }
    .page_product-size-line .page_product-size-line-circ {
      display: none; }
    .page_product-size-line-bl {
      margin: 0 4px 10px;
      border: 1px solid #bebebe;
      padding: 5px 3px;
      width: 59px; }
      .page_product-size-line-bl.active {
        border: 1px solid #F3AD52;
        background: #F3AD52;
        font-weight: 600; }
  .page_product-colors {
    border-bottom: 1px solid #E7E6E6;
    position: relative;
    overflow: hidden;
    padding-bottom: 70px; }
    .page_product-colors.full {
      max-height: 9999px; }
    .page_product-colors-ttl {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
      line-height: 20px;
      margin-bottom: 0; }
    .page_product-colors-subttl {
      font-size: 13px;
      line-height: 20px;
      font-weight: 400;
      text-transform: none; }
      .page_product-colors-subttl img {
        display: none; }
    .page_product-colors-bl {
      width: 46px;
      height: 46px; }
      .page_product-colors-bl > img {
        width: 36px;
        height: 36px; }
    .page_product-colors-cont {
      padding-top: 15px; }
    .page_product-colors-hidden {
      display: none !important; }
    .page_product-colors-mob-more {
      display: block;
      width: calc(100% + 30px);
      margin: 0 -15px;
      background: #fff;
      position: absolute;
      bottom: 0;
      text-align: center;
      padding-bottom: 23px;
      box-shadow: -1px -16px 16px 1px #fff;
      z-index: 2; }
      .page_product-colors-mob-more button {
        border: 2px solid #f3ad52;
        background: #fff;
        font-size: 16px;
        line-height: 24px;
        text-transform: uppercase;
        padding: 6px 10px;
        width: 100%;
        max-width: 281px; }
  .page_product-bok-row {
    position: relative; }
  .page_product-bok-bl {
    position: static;
    width: 144px; }
    .page_product-bok-bl-hidden {
      max-width: calc(100vw - 30px);
      left: 0;
      transform: translate(0);
      bottom: 100%; }
  .page_product-data-cont {
    padding-left: 0; }
    .page_product-data-cont-mob {
      display: block;
      font-size: 16px;
      line-height: 24px;
      font-weight: 300;
      border-bottom: 1px solid #E7E6E6;
      padding: 5px 0;
      margin-bottom: 10px; }
  .page_product-data-tabs {
    display: none; }
  .page_product-data-sticker {
    padding-left: 0; }
  .page_product-sidebar {
    border: 0;
    padding-left: 0; }
    .page_product-sidebar-ttl {
      font-size: 14px;
      line-height: 24px; }
  .page_product-cheap-cont {
    padding: 20px 9px; }
  .page_product-cheap-row {
    flex-wrap: wrap;
    justify-content: center; }
  .page_product-cheap-bl {
    max-width: 106px; }
    .page_product-cheap-bl-img {
      height: 159px;
      display: flex;
      align-items: center;
      text-align: center;
      width: 100%; }
    .page_product-cheap-bl-ttl {
      height: 37px;
      -webkit-line-clamp: 2;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden; }
  .page_product-cheap-plus {
    margin: 0 11px; }
  .page_product-cheap-equal {
    width: 100%;
    text-align: center; }
  .page_product .popular-ttl {
    font-size: 24px;
    font-weight: 400;
    overflow: visible;
    max-width: 9999px;
    white-space: normal; } }

@media (max-width: 991px) {
  .page_product-size-line .irs-grid-text {
    font-weight: 400;
    font-size: 8px;
    visibility: visible !important; } }

i {
  font-style: italic; }

/*footer*/
footer {
  background: #2a2924;
  color: #FFFFFF;
  padding: 40px 0 0px; }

.f-small {
  font-size: 12px;
  line-height: 30px;
  margin-bottom: 4px; }

.f-phn a {
  font-weight: 500;
  font-size: 22px;
  line-height: 30px; }

.f-ml a {
  font-weight: 600;
  font-size: 14px;
  line-height: 18px; }

.f-adr {
  font-size: 12px;
  line-height: 24px;
  margin-top: 29px; }

.f-soc {
  margin-top: 30px; }
  .f-soc a {
    margin-right: 10px; }

.f-bl {
  margin-bottom: 20px; }
  .f-bl1 {
    margin-left: -27px; }
  .f-bl2 {
    margin-left: -6px; }
  .f-bl3 {
    margin-left: 13px; }

.f-ttl {
  font-size: 16px;
  line-height: 30px;
  margin-bottom: 10px; }

.f-menu li a {
  font-size: 12px;
  line-height: 30px;
  font-weight: 300; }
  .f-menu li a:hover, .f-menu li a:focus {
    text-decoration: underline; }

.f-medal {
  max-width: 70px;
  margin-top: 20px; }

.f-btm {
  margin-top: 20px;
  background: #1B1A16;
  padding: 14px 0; }
  .f-btm .container {
    justify-content: space-between;
    display: flex; }

.f-copy {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300; }

.f-dev {
  font-size: 12px;
  line-height: 18px;
  font-weight: 300; }
  .f-dev img {
    transform: translateY(5px); }

/*footer*/
@media (min-width: 1200px) {
  .f-bl {
    margin-bottom: 20px; }
    .f-bl1 {
      margin-left: -27px; }
    .f-bl2 {
      margin-left: -6px; }
    .f-bl3 {
      margin-left: 13px; } }

@media (max-width: 991px) {
  footer {
    padding-top: 30px; }
  .f-copy {
    font-size: 10px;
    margin-bottom: 10px; }
  .f-bl1, .f-bl3 {
    margin-left: 0; } }

@media (max-width: 767px) {
  .f-btm .container {
    flex-direction: column; }
  .f-bl1, .f-bl2, .f-bl3 {
    margin-left: 0; } }

.slick-dots li {
  margin: 0 2px; }
  .slick-dots li.slick-active button:before {
    background: #F3AD52; }

.private_office-page {
  padding: 25px 0 70px; }
  .private_office-page .info_sidebar {
    margin-left: auto;
    margin-top: 0; }
  .private_office-page .page_catalog-cont {
    margin: 0 0 -70px; }

.private_office-content {
  padding-top: 30px; }

.private_office-content h2 {
  padding: 10px 0;
  text-transform: uppercase;
  font-size: 40px;
  font-weight: 300;
  margin: 5px 0 15px; }

.po_form_addres {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .po_form_addres .form_field:nth-child(1) {
    width: 40px; }
  .po_form_addres .form_field:nth-child(2) {
    width: 70px; }
  .po_form_addres .form_field:nth-child(3) {
    width: 60px; }
  .po_form_addres .form_field:nth-child(4) {
    width: 40px; }

.po_form .form_textarea {
  margin-bottom: 50px; }

.my_orders_item {
  border-bottom: 1px solid #CDCDCD; }

.my_orders_item-title {
  display: flex;
  align-items: center;
  height: 45px;
  padding-right: 80px;
  font-size: 14px;
  font-weight: 300;
  color: #1B1A16;
  position: relative; }
  .my_orders_item-title .number {
    width: 145px;
    padding-right: 15px; }
  .my_orders_item-title .date {
    width: 145px;
    padding-right: 15px; }
  .my_orders_item-title .status {
    width: 175px;
    padding-right: 15px; }
    .my_orders_item-title .status.done {
      color: #66BB78; }
    .my_orders_item-title .status.cancel {
      color: #F75B45; }
  .my_orders_item-title .price {
    padding-right: 15px;
    flex-shrink: 0; }
  .my_orders_item-title .more_details {
    cursor: pointer;
    position: absolute;
    right: 0;
    font-size: 13px;
    font-weight: 400;
    padding-bottom: 2px;
    border-bottom: 1px solid #272223;
    transition: all 0.25s ease-in-out 0s; }
    .my_orders_item-title .more_details:hover, .my_orders_item-title .more_details.active {
      color: #F3AD52;
      border-color: #F3AD52; }

.my_orders_item-hide_content {
  display: none; }

.my_orders_item-descr {
  display: flex;
  align-items: flex-start;
  padding: 15px 0;
  font-size: 13px;
  padding-left: 210px;
  position: relative; }
  .my_orders_item-descr .photo {
    position: absolute;
    top: 0;
    left: 0;
    width: 200px; }
    .my_orders_item-descr .photo img {
      max-width: 100%; }
  .my_orders_item-descr .characteristics {
    flex-grow: 1;
    padding-right: 10px; }
    .my_orders_item-descr .characteristics-title {
      display: inline-block;
      font-size: 16px;
      font-weight: 600;
      border-bottom: 1px solid #282828;
      margin-bottom: 20px; }
    .my_orders_item-descr .characteristics-item {
      display: flex;
      align-items: flex-start;
      line-height: 1.33;
      margin-bottom: 10px; }
      .my_orders_item-descr .characteristics-item:last-child {
        margin-bottom: 0; }
    .my_orders_item-descr .characteristics .name {
      width: 170px;
      min-width: 170px;
      padding-right: 10px; }
    .my_orders_item-descr .characteristics .value {
      max-width: 140px; }
  .my_orders_item-descr .customer_info {
    border-left: 1px solid #CDCDCD;
    padding-left: 20px;
    max-width: 100%; }
    .my_orders_item-descr .customer_info-item {
      display: flex;
      align-items: flex-start;
      line-height: 1.5;
      margin-bottom: 10px; }
    .my_orders_item-descr .customer_info .name {
      font-weight: 500;
      width: 135px;
      min-width: 135px;
      padding-right: 10px; }
    .my_orders_item-descr .customer_info .value {
      max-width: 225px; }

@media (min-width: 992px) {
  .private_office-page .info_sidebar {
    padding-left: 40px; }
    .private_office-page .info_sidebar:before {
      content: '';
      background: #e7e6e6;
      width: 1px;
      height: 100%;
      position: absolute;
      left: 22px;
      top: 40px; } }

.page_catalog_shop {
  padding-bottom: 35px; }
  .page_catalog_shop-ttl {
    text-align: center;
    margin-bottom: 40px; }
  .page_catalog_shop-map .map {
    height: 550px;
    margin-bottom: 70px; }

.gm-style .gm-style-iw {
  font-family: 'Montserrat', sans-serif;
  width: 285px;
  padding: 10px 0 5px 20px !important;
  border-radius: 0;
  box-shadow: 0px 0px 20px 0px rgba(15, 15, 12, 0.1); }
  .gm-style .gm-style-iw button {
    display: none !important; }

.gm-style .gm-style-iw-t {
  bottom: 51px !important; }
  .gm-style .gm-style-iw-t:after {
    display: none; }

.gm-iw-title {
  font-size: 14px;
  font-weight: 500;
  color: #1B1A16;
  line-height: 1;
  padding-bottom: 10px;
  display: flex;
  align-items: flex-end;
  justify-content: space-between; }
  .gm-iw-title span {
    display: inline-block;
    vertical-align: bottom; }

.gm-iw-company-icon {
  flex-shrink: 0;
  width: 24px;
  margin-left: 10px; }

.gm-iw-info-item {
  font-size: 12px;
  font-weight: 400;
  color: #626260;
  margin-top: 3px; }

.form-catalog_shop {
  width: 100%;
  max-width: 565px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px; }
  .form-catalog_shop .select2-container {
    flex-grow: 1;
    margin-right: 20px;
    height: 40px; }
    .form-catalog_shop .select2-container .select2-selection--single {
      height: 100%;
      border: 1px solid #E7E6E6;
      outline: none; }
      .form-catalog_shop .select2-container .select2-selection--single .select2-selection__rendered {
        height: 100%;
        line-height: 38px;
        outline: none;
        padding: 0 30px 0 20px;
        font-size: 14px;
        font-weight: 300;
        color: #9E9D9B;
        position: relative; }
        .form-catalog_shop .select2-container .select2-selection--single .select2-selection__rendered:before {
          content: '';
          width: 6px;
          height: 6px;
          border-right: 1px solid #4c4b48;
          border-bottom: 1px solid #4c4b48;
          transform: rotate(45deg);
          margin: auto 0;
          position: absolute;
          top: -2px;
          bottom: 0;
          right: 11px;
          z-index: 1; }
      .form-catalog_shop .select2-container .select2-selection--single .select2-selection__arrow {
        display: none; }
    .form-catalog_shop .select2-container.select2-container--focus .select2-selection--single {
      border-color: #E7E6E6; }
  .form-catalog_shop .form_submit {
    flex-grow: 0;
    width: 130px;
    min-width: auto;
    height: 38px;
    line-height: 34px; }

.select2-dropdown {
  margin-top: -40px;
  border: none; }

.select2-search--dropdown {
  padding: 0; }

.select2-container--form .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field {
  border: 1px solid #E7E6E6;
  height: 40px;
  line-height: 38px;
  padding: 0 30px 0 20px;
  margin-bottom: 2px; }

.select2-container--form .select2-search--dropdown .select2-search__field {
  border: none;
  border-bottom: 1px solid #CDCDCD; }

.select2-results {
  border: 1px solid #E7E6E6; }

.select2-container--form .select2-results {
  border: 1px solid #CDCDCD; }

.select2-results__options {
  font-size: 13px;
  font-weight: 400;
  color: #1B1A16; }

.select2-container--form .select2-results > .select2-results__options,
.select2-container--default .select2-results > .select2-results__options {
  padding: 16px 0 18px 0;
  max-height: 330px; }

.select2-results__option {
  padding: 8px 5px 8px 20px; }

.select2-container--form .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true] {
  background-color: #f8f8f8; }

.select2-container--form .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #f8f8f8;
  color: #000000; }

.catalog_shop-item {
  margin-bottom: 35px; }
  .catalog_shop-item_link {
    display: block;
    height: 100%;
    max-width: 370px;
    position: relative;
    padding-bottom: 31px;
    padding-right: 40px; }
    .catalog_shop-item_link:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 1px;
      background-color: #CDCDCD; }
  .catalog_shop-item .company_icon {
    cursor: default;
    position: absolute;
    top: 5px;
    right: 0;
    width: 35px; }
    .catalog_shop-item .company_icon svg {
      width: 100%; }
    .catalog_shop-item .company_icon:hover .company_icon-hover {
      opacity: 1; }
    .catalog_shop-item .company_icon-hover {
      position: absolute;
      bottom: 30px;
      left: 14px;
      white-space: nowrap;
      height: 30px;
      line-height: 30px;
      padding: 0 15px 0 12px;
      font-size: 12px;
      font-weight: 300;
      color: #626260;
      background-color: #FFFFFF;
      box-shadow: 0px 0px 20px 0px rgba(15, 15, 12, 0.1);
      transition: all 0.25s ease-in-out 0s;
      opacity: 0; }
  .catalog_shop-item .title {
    font-size: 20px;
    color: #1B1A16;
    line-height: 1.33;
    margin-bottom: 11px;
    display: block;
    transition: all 0.25s ease-in-out 0s; }
  .catalog_shop-item .info {
    margin-bottom: 15px; }
  .catalog_shop-item .info_item {
    font-size: 14px;
    color: #626260;
    line-height: 1.33;
    transition: all 0.25s ease-in-out 0s;
    margin-bottom: 10px; }
    .catalog_shop-item .info_item:last-child {
      margin-bottom: 0; }
  .catalog_shop-item .shown_on_map {
    cursor: pointer;
    display: inline-block;
    font-size: 13px;
    text-decoration: underline;
    transition: all 0.25s ease-in-out 0s; }
    .catalog_shop-item .shown_on_map:hover {
      text-decoration: none; }

.form_select .select2-container--default .select2-search--dropdown .select2-search__field {
  border: none;
  border-bottom: 1px solid #1B1A16; }

.information-page {
  padding-bottom: 90px; }

@media (max-width: 1399px) {
  .my_orders_item-descr {
    flex-wrap: wrap; }
    .my_orders_item-descr .characteristics,
    .my_orders_item-descr .customer_info {
      width: 100%; }
      .my_orders_item-descr .characteristics .value,
      .my_orders_item-descr .customer_info .value {
        max-width: none; }
    .my_orders_item-descr .characteristics {
      padding-right: 0; }
    .my_orders_item-descr .customer_info {
      border-left: none;
      padding-left: 0;
      margin-top: 20px;
      padding-top: 20px;
      border-top: 1px solid #cdcdcd; } }

@media (max-width: 991px) {
  .h-srch button {
    z-index: 1; }
    .h-srch button.active {
      left: auto; }
  .h-srch-form {
    right: auto;
    left: 0; }
    .h-srch-form.active {
      width: 260px; }
    .h-srch-form form button {
      display: block;
      margin-right: 0; }
  .private_office-page .left_block {
    order: 2; }
  .private_office-page .right_block {
    order: 1; }
  .private_office-page .info_sidebar {
    margin-left: 0; }
  .checkout-page .private_office-content {
    padding-right: 0; }
  .form_submit {
    display: block;
    margin: 0 auto 50px; }
  .page_mattress-kinds-bl-url a:hover,
  .catalog_shop-item .shown_on_map:hover,
  .checkout-page-cart-ttl:hover {
    text-decoration: underline; }
  .page_product-data-text .show_more span:hover {
    color: #000000;
    border-bottom: 1px solid #000000; }
  .my_orders_item-title .more_details.active,
  .my_orders_item-title .more_details:hover {
    color: #1B1A16;
    border-color: #1B1A16; }
  .page_product-price {
    padding-left: 0; }
  .page_product-top {
    margin-bottom: 30px; }
  .h-menu > ul > li > a {
    display: inline-block; }
    .h-menu > ul > li > a:hover {
      color: inherit; }
  .h-menu > ul > li.dropdown:hover .h-submenu {
    display: none; }
  .h-menu > ul > li.dropdown > a.active {
    color: #F3AD52; }
    .h-menu > ul > li.dropdown > a.active + .h-submenu {
      display: block; }
  .slick-dotted.slick-slider {
    padding-bottom: 25px; }
  .slick-dotted.slick-slider {
    margin-bottom: 20px; }
  .page_single_collections .slick-dotted.slick-slider,
  .page_shop-slider.slick-dotted {
    margin-bottom: 20px; }
  .slick-dots,
  .page_single_collections .slick-dots {
    bottom: -5px; }
  .main .slick-dots li button:before,
  .page_single_collections .slick-dots li button:before,
  .page_product-slider .slick-dots li button:before {
    background-color: #C7C7C7;
    border-color: #C7C7C7; }
  .slick-dots li.slick-active button:before,
  .page_single_collections .slick-dots li.slick-active button:before,
  .page_product-slider .slick-dots li.slick-active button:before {
    background-color: #000000;
    border-color: #000000; }
  .page_single_collections-slider {
    padding-left: 0;
    padding-right: 0; }
  .page_product-cloth_type {
    cursor: pointer;
    display: block;
    background: #f3f3f3;
    border: 2px solid #f3f3f3;
    font-size: 13px;
    line-height: 24px;
    text-transform: uppercase;
    display: block;
    padding: 4px 6px 4px 32px;
    margin-top: 15px;
    position: relative;
    transition: .3s; }
    .page_product-cloth_type:before {
      content: '';
      width: 14px;
      height: 14px;
      background: url(../img/pps-arr.png) no-repeat 50% 50%;
      position: absolute;
      top: 50%;
      left: 12px;
      margin-top: -7px;
      transform: rotate(-90deg);
      -webkit-transition: .3s;
      transition: .3s; }
    .page_product-cloth_type.active:before {
      transform: rotate(0deg);
      transition: .3s; }
  .page_product-colors-tabs {
    flex-direction: column;
    align-items: flex-start;
    padding-left: 15px;
    padding-bottom: 5px;
    border-bottom: 1px solid #E7E6E6; }
  .page_product-colors-tabs-wrapper {
    display: none; }
  .page_product-colors-tab {
    border: none;
    padding-bottom: 0;
    margin-top: 5px;
    margin-bottom: 0;
    line-height: 30px;
    height: auto; }
    .page_product-colors-tab.active {
      height: auto;
      font-weight: normal;
      color: #F3AD52; }
  .page_product-colors-conts {
    max-height: 300px; }
    .page_product-colors-conts.full {
      max-height: none; } }

@media (max-width: 767px) {
  .my_orders_item-title {
    padding-left: 135px;
    align-items: flex-start;
    flex-wrap: wrap;
    height: auto;
    padding-top: 10px; }
    .my_orders_item-title .number {
      position: absolute;
      left: 0;
      width: auto; }
    .my_orders_item-title .date,
    .my_orders_item-title .status,
    .my_orders_item-title .price {
      width: 100%;
      padding-right: 0;
      margin-bottom: 10px; }
  .my_orders_item-descr {
    padding-left: 0; }
    .my_orders_item-descr .photo {
      width: 290px;
      position: static; }
  .catalog_shop-item_link {
    max-width: none; }
  .company_icon-hover {
    display: none; }
  .categories-bl {
    margin-bottom: 10px; } }

@media (max-width: 767px) {
  h1 {
    font-size: 30px !important; }
  h2 {
    font-size: 24px !important; }
  h3 {
    font-size: 18px !important; }
  h1, h2, h3 {
    font-weight: 400 !important; }
  .h-menu > ul > li > a,
  .h-graf,
  .headIon .select2-selection__rendered,
  .h-lang .dropdown.span,
  .h-lang .dropdown > a,
  .product-slider-bl-props span,
  .product-slider-bl-subttl,
  .product-slider-bl-timer > span,
  .about-bl p,
  .f-small,
  .f-adr,
  .filt_open,
  .page_catalog-bl-props span,
  .page_catalog-top-sort span,
  .page_catalog-top-sort span,
  .inner_bottom-seemore-bl-ttl,
  .page_catalog-bl-subttl,
  .page_catalog-bnnr-date,
  .form_select .select2-selection__rendered,
  .form_field,
  .checkout-page-cart-row,
  .checkout-page-cart-und,
  .info_sidebar-list a,
  .info_sidebar-list li,
  .page_mattress-kinds-bl-full span,
  .page_mattress-kinds-bl-full,
  .page_product-colors-subttl,
  .page_product-sidebar-burg-name,
  .page_product-sidebar-change-row,
  .page_product-sidebar-bl-ttl,
  .page_product-data-change-row,
  .page_promotions-about-bl p,
  .page_search-bl-subttl,
  .page_search-bl-props span,
  .page_single_collections-txt {
    font-size: 14px; }
  .f-menu li a,
  .page_catalog-about-seo p,
  .page_catalog-about-bl p,
  .form_select_dd .select2-results__option,
  .form_submit,
  .page_collections-bl-txt,
  .page_product-data-cont,
  .page_product-bok-bl-ttl {
    font-size: 14px;
    font-weight: 400; }
  .categories-bl-ttl,
  .about_products-top p,
  .page_clients-cont p,
  .about_products-cont p,
  .other-site-sections ul li a,
  .other-site-sections ul li span,
  .simple_content ul li a,
  .simple_content ul li span,
  .page_catalog-show_more button,
  .form_subtitle,
  .page_collections-top,
  .page_cooperation-top,
  .page_mattress-cont p,
  .page_mattress-kinds-bl-top,
  .page_mattress-kinds-bl-txt,
  .inner_bottom-seemore-bl-ttl,
  .my_orders_item-title,
  .page_product-data-cont-mob,
  .page_catalog-showroom-ttl,
  .page_promotionList-bl-txt,
  .page_shop-top {
    font-weight: 400; }
  .h-map-select .select2-container {
    min-width: 70px; }
  .page_catalog-top-sort .select2 {
    min-width: 175px; }
  .page_mattress-anchors-line-txt {
    font-weight: 400;
    font-size: 10px; }
  .filt_open,
  .form-catalog_shop .form_submit,
  .form_submit,
  .page_mattress-anchors-btn a,
  .page_product-sidebar-bl-htc,
  .page_product-cheap-result-btn {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    line-height: 38px;
    border-width: 1px;
    font-size: 16px; }
  .filt_open,
  .page_product-colors-mob-more button {
    border-width: 2px; }
  .page_mattress-anchors-btn a {
    max-width: none; }
  .page_catalog-show_more button,
  .page_search-show_more button {
    padding-top: 0;
    padding-bottom: 0;
    height: 40px;
    line-height: 40px;
    font-size: 16px; }
  .page_catalog-show_more button,
  .page_search-show_more button {
    background-size: 22px;
    padding-left: 85px; }
  .page_mattress-table-wp table {
    display: block; }
    .page_mattress-table-wp table tr {
      display: flex;
      flex-wrap: wrap; }
    .page_mattress-table-wp table .for {
      font-size: 0; }
    .page_mattress-table-wp table td, .page_mattress-table-wp table th {
      border: none; }
      .page_mattress-table-wp table td:nth-child(1), .page_mattress-table-wp table th:nth-child(1) {
        width: 29%; }
      .page_mattress-table-wp table td:nth-child(2), .page_mattress-table-wp table th:nth-child(2) {
        width: 31%; }
      .page_mattress-table-wp table td:nth-child(3), .page_mattress-table-wp table th:nth-child(3) {
        width: 40%;
        padding-right: 0; }
      .page_mattress-table-wp table td:nth-child(4), .page_mattress-table-wp table th:nth-child(4) {
        width: 100%;
        border-bottom: 1px solid #d7d7d6;
        padding-top: 7px;
        padding-right: 0; }
        .page_mattress-table-wp table td:nth-child(4) br, .page_mattress-table-wp table th:nth-child(4) br {
          display: none; } }

@media (max-width: 575px) {
  .page_product-actions {
    float: none;
    margin: 15px auto; } }

@media (max-width: 480px) {
  .my_orders_item-title {
    padding-right: 0; }
    .my_orders_item-title .more_details {
      position: static;
      margin-bottom: 15px; }
  .form-catalog_shop .select2-container .select2-selection--single .select2-selection__rendered {
    background: url(../img/sel_arr.png) no-repeat 96% 50%, #fff; }
  .form-catalog_shop {
    flex-wrap: wrap; }
    .form-catalog_shop .select2-container {
      width: 100%;
      margin-right: 0;
      margin-bottom: 20px; }
    .form-catalog_shop .form_submit {
      width: 100%; }
  .page_catalog_shop-map .map {
    height: 400px;
    margin-bottom: 50px; }
  .catalog_shop-item .title {
    font-size: 16px; } }

@media (max-width: 375px) {
  .page_mattress-anchors-line-txt {
    font-size: 8px; } }

.interior_images .fancybox-inner {
  overflow: visible !important; }

.modal_interior {
  width: 100%;
  max-width: 900px; }

.modal_interior_slider {
  padding: 0 5px !important;
  margin: 0 !important; }
  .modal_interior_slider .slick-arrow {
    width: inherit;
    height: inherit; }
  .modal_interior_slider .slick-prev {
    left: -30px; }
  .modal_interior_slider .slick-next {
    right: -30px; }

.interior_image_item img {
  margin: 0 auto; }

@media (max-width: 991px) {
  .modal_interior_slider {
    margin-bottom: 20px !important; }
    .modal_interior_slider .slick-dots {
      bottom: -30px !important; } }
