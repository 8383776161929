body {font-family: 'Montserrat', sans-serif; font-size: 16px; background: $c_White;color: $c_Black;}
@media(min-width: 1400px){
	.container {max-width: 1390px;}
}
img {height: auto; max-height: 100%}

.container-big {max-width: 1430px;margin: 0 auto;position: relative;padding: 0 15px;}
/*header*/
header {position: fixed;padding: 22px 0 0;width: 100%;top: 0;left: 0;background: $c_White;z-index: 10;border-bottom: 1px solid $c_Mercury;}

.hidden_block {
	display: none;
}


.h {
	&-logo {text-align: center;}
	&-left { display: flex; flex-direction: row; align-items: center; padding-top: 10px; }
	&-map { display: flex; flex-direction: row; align-items: center;
		&-select { margin-left: 0px;
			select { font-size: 12px; padding-right: 15px; background: url('../img/sel_arr.png')no-repeat 90% 50%; width: 60px; cursor: pointer;}
		}
		&-text {font-size: 12px; font-weight: 500; padding: 0 5px 0 8px;}
	}
	&-midl {padding-top: 2px;
      position: relative;
      z-index: 2;}
	&-phns {min-height: 31px; padding-right: 20px; position: relative; z-index: 5; width: max-content; background: $c_White; width: 215px;
		.drp {position: absolute;right: 18px; top: 15px; width: 18px;	height: 18px; background: url('../img/sel_arr.png')no-repeat 50% 50%; cursor: pointer; z-index: 2;}
		a {font-size: 14px; padding-left: 20px; display: inline-block;
			&:hover, &:focus {color: $c_SandBrown;}
			&.kvstr { background: url('../img/h-kvstr.png')no-repeat 0% 50%;}
			&.vdf { background: url('../img/h-vdf.png')no-repeat 0% 50%;}
			&.lfc { background: url('../img/h-lfc.png')no-repeat 0% 50%;}
		}
		&-modl {display: none;background: $c_White; padding-top: 5px;
			a {margin: 5px 0;}
		}
		&-cont {padding: 0 18px;padding-top: 15px; filter: grayscale(100%); border-radius: 0px;
			&:hover {filter: grayscale(0%); }
			&.active { filter: grayscale(0%); padding: 15px 18px; position: absolute; width: 100%;background: $c_White; box-shadow: 0 0 26px 2px #0000001c;
				.h-phns-modl {display: block;}
			}
		}
	}
	&-lang { margin-left: 10px; margin-left: 15px;
		.dropdown{ position: relative;
			&>a, &.span {display: block;padding: 12px 15px;text-transform: uppercase;font-size: 12px; position: relative;margin-top: 2px; font-weight: 500;
				&:after {content: '';width: 10px;height: 6px;background: url('../img/sel_arr.png')no-repeat 50% 50%;position: absolute;display: block;right: 0;top: 50%; margin-top: -3px;
					display: none;}
			}
			.h-submenu {display: none; position: absolute; top: 42px;left: 21px; background: $c_White; padding: 10px 20px; box-shadow: 0px 8px 13px 2px #00000014; width: 60px; width: max-content; z-index: 2;
				&>li { display: block;
					&>a {font-size: 13px;font-weight: 500;line-height: 36px; text-transform: uppercase;
						&:hover, &:focus {color: $c_SandBrown;}
					}
				}
				&.active {display: block;}
			}
		}
	}
	&-graf { font-size: 12px; padding-left: 38px; margin-top: 5px; }
	&-right { display: flex; justify-content: flex-end; margin-top: 20px; padding-right: 8px;
      position: relative;
      z-index: 2;
		button { border: 0; padding: 0; margin-left: 26px; width: 22px; height: 22px; cursor: pointer;}
	}
	&-srch{ position: relative;z-index: 4;
		button {background: url('../img/h-srch.png')no-repeat 50% 50%; position: relative; left: 0; z-index: 3; transition: 0.5s;
			&.active {
				left: -15px;
			}
		}
		&-form {position: absolute;z-index: 2;right: 0;width: 0; transition: 0.7s;top:-9px;
			form {border: 1px solid transparent; background: $c_White; align-items: center; padding: 9px 0px; border-radius: 0px; visibility: hidden; opacity: 0;
				input {border:0;flex:1;font-weight: 300;font-size: 14px;color: #9e9d9b;}
				button {display: none; background: url('../img/h-srch.png')no-repeat 50% 50%;border: 0; padding: 0; margin-left: 26px; width: 22px; height: 22px; cursor: pointer;}
			}
			&.active {width: 393px; transition: width 0.7s; border: 1px solid $c_Mercury;
				form {padding: 9px 15px; visibility: visible; opacity: 1;}
			}
			.main__search {
				display: flex;
			}
		}
		.main__dropDown {
			position: absolute;
			top: 100%;
			left: 0;
			background-color: $c_White;
			margin-top: 1px;
			min-width: 100%;
			width: max-content;
			max-width: 370px;
			font-size: 14px;
			line-height: 1.33;
		}
		.main__DD-list {
			padding: 15px;
			box-shadow: 3px 5px 10px rgba(0,0,0,.4);
		}
		.main__DD-item {
			display: flex;
			padding: 10px 0;
			& + .main__DD-item {
				border-top: 1px solid $c_Mercury;
			}
		}
		.mini-img {
			flex-shrink: 0;
			width: 70px;
			img {
				width: 100%;
			}
		}
		.main__DD-info {
			display: flex;
			width: 100%;
		}
		.main__DD-name {
			flex-grow: 1;
			padding: 0 10px;
		}
		.main__DD-price {
			flex-shrink: 0;
			width: 70px;
			text-align: right;
		}
	}
	&-prof{
		flex-shrink: 0;
		position: relative;
		z-index: 1;
		.js_account {
			display: inline-block;
			flex-shrink: 0;
			line-height: 22px;
			margin-left: 16px;
			margin-right: -10px;
			font-size: 14px;
			font-weight: 500;
		}
		button {background: url('../img/h-prof.png')no-repeat 50% 50%;}
	}
	&-cart{position: relative;
		button {background: url('../img/h-cart.png')no-repeat 50% 50%;}
		.count { font-family: 'Open Sans', sans-serif; position: absolute; font-size: 10px; font-weight: 700; color: $c_White; background: #f85843; text-align: center; width: 16px; height: 16px; border-radius: 50%; padding-top: 2px; top: -6px; right: -8px; }
	}
	&-submenu {display: none; position: absolute; top: 42px;left: 37px; background: $c_White; padding: 10px 20px; box-shadow: 0px 8px 13px 2px #00000014; width: max-content;
		&>li { display: block;
			&>a {font-size: 13px;line-height: 36px;font-weight: 500;
				&:hover, &:focus {color: $c_SandBrown;}
			}
		}
	}
	&-menu {margin: 0 auto;max-width: 1100px;margin-top: 10px;margin-bottom: 4px;
		&>ul {display: flex;justify-content: space-between; align-items: center;
			&>li {
				&>a {display: block;padding: 15px 21px;text-transform: uppercase;font-size: 12px; position: relative; font-weight: 500;
					&:hover, &:focus {color: $c_SandBrown;}
				}
				&.dropdown{ position: relative;
					&>a, &.span {
						.dropdown_arrow {width: 23px;height: 19px;background: url('../img/sel_arr.png') no-repeat 100% 50%;background-size: 10px 6px;position: absolute;z-index: 1;display: block;right: 0;top: 50%; margin-top: -10px;}
						// &:after {content: '';width: 10px;height: 6px;background: url('../img/sel_arr.png')no-repeat 50% 50%;position: absolute;display: block;right: 0;top: 50%; margin-top: -3px;}
					}
					&:hover {
						.h-submenu {display: block;}
					}
				}
			}
		}
	}
	&-burg {display: none;}
}
/*header*/
@media(min-width: 992px) and (max-width: 1399px){
	.h {
		&-srch{
			&-form {
				&.active {
					width: 270px;
				}
			}
		}
	}
}
@media(min-width: 992px) and (max-width: 1199px){
	.h {
		&-phns {
			a {font-size: 12px;}
		}
	}
}
@media(max-width: 991px){
	header {padding: 22px 0;}
	.h {
		&-logo {max-width: 180px;}
		&-right {margin-top: 20px;justify-content: flex-start;}
		&-srch{
			button {margin-left: 0; margin-right: 26px;}
			.main__dropDown {
				max-width: 450px;
			}
		}
		&-prof{
			button {margin-left: 0; margin-right: 26px;}
		}
		&-cart{
			button {margin-left: 0; margin-right: 26px;}
			.count {right: 17px;}
		}
		&-burg { display: block;width: 20px; padding: 0; border: 0; background: none; margin-top: 2px;
			span { background: #000; height: 2px; width: 100%; display: block; margin: 4px 0; }
		}
		&-menu {
			&>ul {flex-direction: column; justify-content: flex-start; align-items: flex-start;
				&>li {
					&>a {padding: 15px 0;padding-right: 21px;}
				}
			}
		}
		&-submenu {position: static; box-shadow: none; padding-top: 0; width: 100%;
			&>li {
				&>a {line-height: 20px;margin-bottom: 10px; display: inline-block;}
			}
		}
	}
	.mob_only {
		.h {
			&-logo {text-align: center;max-width: 184px;margin: 0 auto;}
			&-right {margin-top: 7px;}
		}
	}
}

@media(max-width: 767px){
	.h {
		&-srch{
			.main__dropDown {
				max-width: 415px;
			}
		}
	}
}

@media(max-width: 767px){
	.h {
		&-srch{
			.main__dropDown {
				width: 100%;
				max-width: none;
			}
			.main__DD-info {
				display: block;
			}
			.main__DD-price {
				padding: 5px 10px;
				width: auto;
				text-align: left;
			}
		}
	}
}

.mob {
	&_overlay {display: none;}
	&_cls {display: none;}
}

@media(min-width: 992px){
	.pc_only {
		display: block!important;
	}
}
@media(max-width: 991px){
	.pc_only {display: none;position: fixed;height: 100vh;background: $c_White;top: 0;left: 0;min-width: 290px; width: 75%; padding-top: 20px; padding-bottom: 20px; z-index: 2; overflow-y: auto;}
	.mob {
		&_overlay {position: fixed;z-index: 2;background: rgba(0, 0, 0, 0.47);width: 100vw; height: 100vh;top: 0;left: 0;}
		&_cls {width: 30px;height: 40px;background: $c_SandBrown;position: absolute;top: 0;right: auto; left: 75%; z-index: 3;
			&:before {content:'';display:block;width: 16px;height: 2px;top: 50%;position: absolute;transform: rotate(45deg);background:#000;left: 7px;}
			&:after {content:'';display:block;width: 16px;height: 2px;top: 50%;position: absolute;transform: rotate(-45deg);background:#000;left: 7px;}
		}
	}
}

@media(max-width: 480px) {
	.pc_only {
		width: calc(100% - 30px);
	}
	.mob_cls {
		left: auto;
		right: 0;
	}
}

.wrapper {padding-top: 130px;}
@media(max-width: 767px){
	.wrapper {padding-top: 80px;}
}

.page-continue {
	text-align: center;
	padding-bottom : 90px;
	h1 {
		line-height: 1.33;
		margin-bottom: 25px;
	}
	p {
		font-size: 18px;
		line-height: 1.33;
		margin-bottom: 25px;
		& + p {
			margin-top: -5px;
		}
	}
	.yellow_btn {
		display: inline-block;
		width: 160px;
		font-size: 14px;
		color: #fff;
		padding: 0 10px;
		border: 2px solid transparent;
		text-transform: uppercase;
		background: #f3ad52;
		line-height: 36px;
		text-align: center;
		transition: .3s;
		cursor: pointer;
	}
}

@media(max-width: 991px) {
	.page-continue {
		h1 {
			font-size: 30px;
		}
	}
}

@media(max-width: 767px) {
	.page-continue {
		h1 {
			padding: 0;
			font-size: 24px !important;
		}
		p {
			font-size: 14px;
		}
	}
}

.account_reset_buttons {
	display: flex;
	align-items: center;
	justify-content: space-between;
	.back_linkt {
		text-decoration: underline;
		&:hover {
			text-decoration: none;
		}
	}
}

.template_content {
	h1, h2, h3, h4, h5, h6, p, ul, ol {
		max-width: 928px !important;
		margin-left: auto !important;
		margin-right: auto !important;
	}
	p {
		margin-bottom: 10px !important;
		font-size: 14px !important;
		line-height: 24px !important;
		font-weight: 300 !important;
	}
	img {
		display: block !important;
		margin: 45px auto 60px !important;
		width: 100% !important;
	}
	ul {
		padding-left: 30px !important;
		li {
			font-size: 14px !important;
			font-weight: 300 !important;
			line-height: 24px !important;
			color: #000 !important;
			padding-left: 15px !important;
			position: relative !important;
			margin-bottom: 12px !important;
			&:before {
				content: '' !important;
				position: absolute !important;
				top: 11px !important;
				left: 0 !important;
				background: #f3ad52 !important;
				display: block !important;
				width: 4px !important;
				height: 4px !important;
				border-radius: 50% !important;
			}
			a {
				font-size: 14px !important;
				font-weight: 300 !important;
				line-height: 24px !important;
				color: #000 !important;
			}
		}
	}

	hr {
		border-color: $c_Mercury !important;
		max-width: 928px !important;
		margin: 40px auto 25px !important;
	}
}

@media(max-width: 991px){
	.template_content {
		div,
		p {
			br {
				display: none !important;
			}
		}
	}
}

/*main*/
.main {
	.container {padding: 0;max-width: 1440px;}
	.row {margin: 0;}
	.col-12, .col-6 {padding: 0;}
	&-slide {position: relative; font-size: 0;}
	&-slider {
		&-info {position: absolute;z-index: 2;width: 100%;top: 96px;text-align: center;}
		&-date {font-size: 18px;font-weight: 500;}
		&-ttl {color: $c_SandBrown;font-weight: 600;font-size: 35px;margin-top: 17px;}
		&-txt {text-transform: uppercase;font-size: 20px;margin-top: 15px;font-weight: 500;}
		&-img {position: relative;z-index: 1;}
		.slick {
			&-slide { font-size: 0;}
			&-prev {left: 25px;
				&:hover {
					path {stroke:$c_SandBrown;}
				}
			}
			&-next {right: 32px;
				&:hover {
					path {stroke:$c_SandBrown;}
				}
			}
			&-dots {
				width: auto;
				left: 50%;
				transform: translateX(-50%);
			}
		}
	}
	&-bl {display: flex;width: 100%;height: 100%;justify-content: center;align-items: center;flex-direction: column;
		&-ttl {font-size: 20px; font-weight: 500; text-align: center; padding-bottom: 13px; margin-bottom: 13px; position: relative; line-height: 1.3;
			&:after {content: ''; position: absolute; display: block; height: 1px; width: 43%; background: $c_PastelYellow; margin: 0 auto; bottom: 0; left: 0; right: 0;}
		}
		&-txt {font-size: 16px;}
		&.yellow {background: #f6eee1;}
		&.green {background: #c8e6e4;}
		&-wp { opacity: 1; transition: 0.3s;
			&:hover, &:focus { opacity: 0.7; transition: 0.3s; }
		}
	}
	.slick-dotted.slick-slider {margin-bottom: 0; padding-bottom: 0;}
	.slick-dots {top: auto;bottom: 20px;}
	.slick-dots li button:before {content: '';width: 10px;height: 10px; background: $c_White;border-radius: 50%;opacity: 1;}
}
/*main*/
@media(min-width: 1300px){
	.main-bl-wp { max-height: 400px; }
}
@media(max-width: 991px){
	.main {
		&-slider {
			&-info {top: 35px;}
			&-date { font-size: 16px;}
			&-ttl { font-size: 26px;}
			&-txt { font-size: 18px;margin-top: 22px;}
		}
		&-bl{
			&-wp { max-height: 56vw; }
			&-ttl { font-size: 14px; line-height: 18px;  padding: 0 10px;
				&:after {bottom: -10px;}
			}
			&-txt { font-size: 14px; line-height: 18px; text-align: center;  padding: 0 10px;}
		}
	}
}
@media(min-width: 718px) and (max-width: 991px){
	.main {max-width: 718px;margin: 0 auto;}
}

/*categories*/
.categories { padding: 68px 0 40px; background: #f8f8f8;
	& + .novelty { margin-top: -70px; }
	&-ttl { font-size: 40px; font-weight: 300; text-transform: uppercase; text-align: center; margin-bottom: 32px;}
	&-bl {padding: 0 15px; margin-bottom: 30px; width: 100%;
		&-in {height: 100%; background: $c_White; padding: 29px 24px; padding-right: 16px; display: flex; flex-direction: column; justify-content: space-between;
			&:hover, &:focus {box-shadow: 8px 8px 18px 2px #0000002b;}
		}
		&-ttl {font-size: 18px;padding-bottom: 16px; position: relative; line-height: 24px; max-height: 64px; font-weight: 400;transition: 0.3s;
			&:before {content: ''; position: absolute; display: block; height: 1px; width: 60px; background: $c_PastelYellow; margin: 0 0; bottom: 0; left: 0; }
			&:hover, &:focus {color: $c_PastelYellow;transition: 0.3s;}
		}
		&-img { padding: 19px 0;text-align: center;
			img {max-width: 100%; width: auto;}
		}
	}
}
/*categories*/
@media(min-width: 1400px){
	.categories {
		&-bl { max-width: 22.2%; width: 100%;
			&.big {max-width: 33.4%}
			&.middle {max-width: 27.8%;}
			&-in {height: 270px;}
		}
	}
}
@media(min-width:992px) and (max-width: 1399px){
	.categories {
		&-bl { max-width: 22.2%; width: 100%;
			&.big {max-width: 33.4%}
			&.middle {max-width: 27.8%;}
			&-in {height: 100%; padding: 29px 18px; padding-right: 10px;}
			&-ttl {height: auto; font: 1; font-size: 16px; max-height: 1000px;}
		}
	}
}
@media(max-width: 991px){
	.categories {padding-top: 50px;
		&-ttl {font-size: 24px;font-weight: 400;}
		&-bl { width: 50%;
			&-in {flex-direction: row;padding: 11px 11px;align-items: center; justify-content: flex-start; padding-right: 5px;}
			&-ttl {order:2; flex: 0 0 1;padding: 0; font-size: 16px;font-weight: 300;
				&:before {display: none;}
				br {display: none;}
			}
			&-img {order:1;padding: 0;margin-right: 15px;height: 35px;text-align: left;width: auto!important;}
		}
	}
}

@media(max-width: 767px) {
	.categories-bl {
		width: 100%;
	}
}

/*product-slider*/
.product {
	&-slide {padding: 20px 15px;}
	&-slider { height: 540px;
		&-bl {padding: 40px 10px 30px; position: relative; max-width: 340px; margin: 0 auto; background: $c_White;
			&-stickers { position: absolute; right: 15px; top: 15px;
				.new {background: url('../img/new.png');width: 80px; height: 26px; margin-bottom: 5px; font-size: 11px; font-weight: 500; text-transform: uppercase; color: $c_White;padding: 4px 4px 9px 15px;}
				.akc {background: url('../img/akc.png');width: 66px; height: 26px; margin-bottom: 5px; font-size: 15px; font-weight: 600; text-transform: uppercase; color: $c_White;padding: 2px 4px 9px 15px;}
			}
			&-ttl { text-align: center; font-size: 20px; line-height: 24px; font-weight: 500; margin-bottom: 0px; display: block; transition: 0.3s;
				&:hover, &:focus {color: $c_PastelYellow;transition: 0.3s;}
			}
			&-subttl { text-align: center; font-size: 13px; line-height: 20px; display: block; }
			&-img {text-align: center; padding-top: 14px; display: block;
				img {display: inline;}
			}
			&-prices {text-align: center;
				.old {font-size: 14px;line-height: 24px;color: $c_StormDust;text-decoration: line-through;}
				.cur {font-size: 18px;line-height: 24px; margin-top: 2px;transition: 0.3s;
					span {font-size: 24px;}
					&:hover {
						color: $c_PastelYellow;
						transition: 0.3s;
					}
				}
			}
			&-props {display: none;flex-wrap: wrap;justify-content: center; margin: 28px 0 10px;
				span { display: flex; align-items: center; font-size: 13px; line-height: 24px; padding: 0 7px; margin-bottom: 1px;
					img {margin-right: 7px;}
				}
			}
			&-btn {display: none; text-align: center; margin-top: 15px;
				a {font-size: 14px; font-weight: 300; line-height: 36px;padding: 0 26px; color: $c_RangoonGreen; text-transform: uppercase; border: 2px solid $c_SandBrown;display: inline-block;
					&:hover {border-color: $c_OrangeYellowCraiola;}
				}
			}
			&:hover {background: $c_White;box-shadow: 0 0 26px 2px #0000001c;
				.product-slider-bl {
					&-props {display: flex;}
					&-btn {display: block;}
				}
			}
			&-timer { display: flex; justify-content: center; margin: 5px 0; align-items: center;
				&>span { border: 1px solid #f00; font-size: 12px; padding: 15px 5px; width: 72px; text-align: center;
					&.sep { border: 0; font-size: 20px; font-weight: 600; padding: 0; margin: 0 9px; width: auto; }
				}
			}
		}
		&.slick-dotted.slick-slider {margin-bottom: 30px;}
		.slick-list {margin: 0 -10px; padding: 0 10px;
			&:before {content: ''; position: absolute; height: 100%; width: calc((100vw - 1160px)/2); z-index: 2; top: 0;right: calc(100% - 20px);}
			&:after {content: ''; position: absolute; height: 100%; width: calc((100vw - 1160px)/2); z-index: 2; top: 0;left: calc(100% - 20px);}
		}
		&-arrs { width: 96px; display: flex; position: absolute; top: 8px; right: 0;
			a { position: static;
				svg { position: static; }
			}
		}
		.slick-prev { left: auto; right: 43px; top: -30px;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
		.slick-next { left: auto; right: 2px; top: -30px;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
	}
}

.button_like {
	position: relative;
	&:hover .like_hint {
		opacity: 1;
		visibility: visible;
	}
	&.active {
		.after-added-text {
			opacity: 1;
			position: static;
			visibility: visible;
			@include transition;
		}
		.add-text {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}
	&.hint {
		.like_hint {
			opacity: 1;
			visibility: visible;
		}
		.added-text {
			opacity: 1;
			position: static;
			visibility: visible;
			@include transition;
		}
		.after-added-text {
			opacity: 0;
			position: absolute;
			visibility: hidden;
		}
	}
}

.like_hint {
    cursor: default;
    width: 148px;
    position: absolute;
    top: 10px;
	left: -5px;
	transform: translate(-100%, -50%);
    text-align: center;
    font-size: 13px;
    line-height: 1.33;
    background-color: $c_White;
    border: 1px solid $c_PastelYellow;
    padding: 5px;
    text-transform: initial;
	border-radius: 3px;
	@include transition;
	opacity: 0;
	visibility: hidden;
	&:before,
	&:after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0;
		margin: auto 0;
		display: block;
		width: 0;
		height: 0;
	}
	&:before {
		border-style: solid;
		border-width: 5px 0 5px 8px;
		border-color: transparent transparent transparent $c_PastelYellow;
		right: -8px;
	}
	&:after {
		border-style: solid;
		border-width: 4px 0 4px 7px;
		border-color: transparent transparent transparent $c_White;
		right: -7px;
	}
	span {
		position: absolute;
		opacity: 0;
		visibility: hidden;
	}
	.add-text {
		position: static;
		opacity: 1;
		visibility: visible;
		@include transition;
	}
}
/*product-slider*/
@media(max-width: 991px){
	.product {
		&-slider {height: auto;
			.slick-dots {top: auto;bottom: 0px;}
			.slick-dots li button:before {content: '';width: 10px;height: 10px; background: #c8c9c9;border-radius: 50%;opacity: 1;}
			.slick-dots li.slick-active button:before {background: #000;}
			&-arrs {display: none;}
			&-bl {
				&-props {display: flex;
					span {
						img {margin-right: 6px;}
					}
				}
				&:hover {background: none;box-shadow: none;
					.product-slider-bl {
						&-btn {display: none;}
					}
				}
			}
			.slick-list {margin: 0;padding: 0;}
		}
	}
}

/*popular*/
.popular { padding: 68px 0 0px; position: relative;z-index: 1;
	&:hover {z-index: 3;}
	.container {position: relative;}
	&-ttl { font-size: 40px; font-weight: 300; text-transform: uppercase; text-align: center; margin-bottom: 12px;}
}
/*popular*/
@media(max-width: 991px){
	.popular {padding: 68px 0;
		.container { padding: 0;}
		&-ttl {font-size: 24px;font-weight: 400;overflow: hidden; max-width: 191px; margin: 0 auto 12px; white-space: nowrap;}
	}
}

/*novelty*/
.novelty { padding: 68px 0 0px; position: relative;z-index: 1;margin-bottom: -79px;
	&:before {content: '';background: #f8f8f8;position: absolute;z-index: -1;width: 100%;height: 100%; height: calc(100% - 79px); top: 0px;}
	&:hover {z-index: 3;}
	.container {position: relative;}
	&-ttl { font-size: 40px; font-weight: 300; text-transform: uppercase; text-align: center; margin-bottom: 12px;}
}
/*novelty*/
@media(max-width: 991px){
	.novelty {padding: 68px 0;
		.container { padding: 0;}
		&-ttl {font-size: 24px;font-weight: 400;}
	}
}

/*stock*/
.stock { padding: 68px 0 0px; position: relative;z-index: 1; margin-bottom: -79px;
	&:hover {z-index: 3;}
	.container {position: relative;}
	&-ttl { font-size: 40px; font-weight: 300; text-transform: uppercase; text-align: center; margin-bottom: 12px;}
}
/*stock*/
@media(max-width: 991px){
	.stock {padding: 68px 0;
		.container { padding: 0;}
		&-ttl {font-size: 24px;font-weight: 400;}
	}
}

/*about*/
.about { padding: 68px 0; position: relative;z-index: 1; background: #f8f8f8;
	&-ttl { font-size: 40px; font-weight: 300; text-transform: uppercase; margin-bottom: 25px;
		text-align: left;}
	&-cont { display: flex; margin-bottom: 26px;}
	&-col {
		&-sm { width: 132px; flex: 0 0 132px; margin-right: 40px;}
	}
	&-img { text-align: center; padding-top: 7px;}
	&-txt {font-size: 16px;font-weight: 500;line-height: 26px; letter-spacing: -0.9px;}
	&-bl {margin-bottom: 25px;
		h3 {font-size: 16px;line-height: 24px;margin-bottom: 10px;}
		p {font-size: 12px;line-height: 24px; font-weight: 400;}
	}
}
/*about*/
@media(min-width: 1200px){
	.about {
		.container {max-width: 928px;}
	}
}
@media(max-width: 991px){
	.about {
		&-cont {flex-wrap: wrap;}
		&-col {
			&-sm {width: 100%;margin-bottom: 20px;}
			&-big { width: 100%;}
		}
	}
}

/*breadcrumbs*/
.breadcrumbs { padding: 18px 0;
	a, span {font-size: 12px; color: $c_StormDust;
		&.cur {color: #000;}
		&.sep {margin: 0 8px;}
	}
	span {color: $c_RangoonGreen;}
}
/*breadcrumbs*/

h1 { padding: 10px 0; text-transform: uppercase; font-size: 40px; font-weight: 300; margin: 5px 0; }
@media(max-width: 991px){
	h1 {font-size: 24px; font-weight: 400;text-align: center;}
}

h3 {font-size: 30px;line-height: 24px;margin-bottom: 15px;}
h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}

/*sidebar_left*/
.sidebar_left { border-right: 1px solid $c_Mercury; padding-right: 20px; max-width: 290px;padding-top: 30px; padding-bottom: 21px;
	&-bl { border-bottom: 1px solid $c_Mercury; margin-bottom: 30px;
		&:last-child {border-bottom: 0;}
		&-ttl {font-size: 18px;display: block;position: relative; cursor: pointer; margin-bottom: 21px; color: $c_RangoonGreen;
			&:before {content: '';width: 10px; height: 6px; background: url(../img/sel_arr.png) no-repeat 50% 50%; position: absolute; display: block; right: 0; top: 50%; margin-top: -3px;transition: 0.3s;}
			&.active {
				&:before {transform: rotate(180deg);transition: 0.3s;}
			}
		}
		&-subttl {font-size: 18px;display: block;position: relative; cursor: pointer; margin-bottom: 21px;width: 100%;margin-top: 15px;}
		&-filt { display: none; margin-bottom: 21px;
			&-range {display: flex; flex-wrap: wrap;
				label {display: flex;width: 50%; font-size: 14px; align-items: center;
					input {border: 1px solid $c_Mercury;margin-left: 10px;width: 85px; padding: 8px 9px;}
				}
				.irs {width: 100%;
					&--flat {
						.irs-line {height: 3px;border-radius: 0;}
						.irs-bar {height: 3px;border-radius: 0;background: $c_SandBrown;}
						.irs-handle {width: 14px; height: 14px; top: 19px; transform: translateX(-6px); cursor: pointer;
							&>i:first-child {width: 14px;height: 14px;border-radius: 50%;background: $c_SandBrown;}
						}
					}
				}
			}
			&-chk { margin-bottom: 11px;
				label {font-size: 13px; cursor: pointer;
					span {
						&.chbx {border: 1px solid $c_Mercury; width: 20px; height: 20px;margin-right: 6px;display: inline-block;vertical-align: middle;}
						&.cnt {color: #959595;}
					}
				}
				input {display: none;
					&:checked + label {
						span.chbx {border: 1px solid $c_SandBrown;background: url('../img/filt_chk.png')no-repeat 50% 50%, $c_SandBrown;}
					}
				}
				&:last-child {margin-bottom: 0;}
			}
		}
		.filter__title span {
			font-size: 18px;
			display: block;
			position: relative;
			cursor: pointer;
			margin-bottom: 21px;
			color: #1b1a16;
		}
		.flex-block {
			display: flex;
		}
		.content-between {
			justify-content: space-between;
		}
		.form-control {
			border: 1px solid #e7e6e6;
			width: 85px;
			padding: 8px 9px;
			border-radius: 0;
			font-size: 14px;
			margin-left: 10px;
		}
		.noUi-target {
			margin-top: 25px;
			box-shadow: none;
			height: 3px;
			border-radius: 0;
			border: 0;
		}
		.noUi-connect {
			background: #f3ad52;
		}
		.noUi-horizontal .noUi-handle {
			width: 14px;
			height: 14px;
			border-radius: 50%;
			background: #f3ad52;
			box-shadow: none;
			border: 0;
			right: -9px;
			top: -6px;
			cursor: pointer;
			&:focus {
				outline: 0;
			}
		}
		.noUi-handle:before {
			display: none;
		}
		.noUi-handle:after {
			display: none;
		}
		.noUi-connects {
			background-color: #e1e4e9;
			border-radius: 0;
		}
		.collapse {
			margin-bottom: 26px;
			label {
				width: 50%;
				font-size: 14px;
				align-items: center;
				display: flex;
			}
		}
	}
}
/*sidebar_left*/
@media(min-width: 992px){
	.sidebar_left {display: block!important;}
}
@media(max-width: 991px){
	.sidebar_left {border-left: 1px solid $c_Mercury; border-top: 1px solid $c_Mercury; padding-left: 20px; margin: 0 auto;display: none;}
}

/*page_catalog*/
.page_catalog {
	&-remove { cursor: pointer; position: absolute; top: 0; right: 6px; font-size: 40px; font-weight: 400; color: $c_SilverChalice; @include transition;
		&:hover {color: $c_Black}
	}
	&-top { display: flex;justify-content: center;
		&-fltr {display: flex;flex-wrap: wrap; flex: 1; align-items: center;
			& > span {font-size: 12px;padding: 7px 10px 4px; border:1px solid #000; border-radius: 0px; margin-right: 10px;
				&.del-all {border-color: $c_SunsetOrange;cursor: pointer;}
				&.item {border-color: $c_Mercury;
					span {position: relative; width: 8px; height: 8px; display: inline-block; vertical-align: middle;cursor: pointer;
						&:before {content: '';background: #000;height: 1px;width: 100%; display: block; position: absolute;transform: rotate(45deg);top:3px;}
						&:after {content: '';background: #000;height: 1px;width: 100%; display: block; position: absolute;transform: rotate(-45deg);top:3px;}
					}
				}
			}
		}
		&-sort {display: flex;align-items: center;
			span {font-size: 12px;}
			select {font-size: 12px;padding: 6px 10px 5px; padding-right: 20px;border:1px solid $c_Mercury; margin-left: 10px; background: url(../img/sel_arr.png) no-repeat 95% 50%; cursor: pointer;
				option {padding: 5px 10px;
					&:hover {background: $c_Mercury;}
				}
			}
			.select2 { margin-left: 10px;
				&-container .select2-selection--single {border:1px solid $c_PastelYellow;}
			}
		}
	}
	&-cont {margin-top: 25px;}
	&-bnnr {margin: 40px auto 30px; position: relative; max-width: 340px; text-align: center; display: block;
		&-img {display:block; background: #f6eee1;display: flex;position: relative;justify-content: center;align-items: center;min-height: 369px;z-index: 1;}
		&-info {display:block; position: relative; z-index: 2;position: absolute;top: 0;left: 0;width: 100%;padding: 40px 0;}
		&-ttl {display:block; font-size: 20px;line-height: 24px;font-weight: 500;color: $c_SunsetOrange;opacity: 1;transition: 0.3s;
			&:hover {opacity: 0.7;transition: 0.3s;}
		}
		&-date {display:block; font-size: 13px;line-height: 24px;}
		&-skid {display:block; font-size: 72px;font-weight: 700; color: $c_SunsetOrange;padding-top: 43%;}
	}
	&-bnnr2 {padding: 40px 0px 30px; position: relative; max-width: 340px; margin: 0 auto;text-align: center;}
	&-bl {padding: 40px 10px 30px; position: relative; max-width: 340px; margin: 0 auto;
		&-wp {height: 400px; position: relative; max-width: 340px; margin: 0 auto;}
		&-stickers { position: absolute; right: 20px; top: 15px;
			.new {background: url('../img/new.png');width: 80px; height: 26px; margin-bottom: 5px; font-size: 11px; font-weight: 500; text-transform: uppercase; color: $c_White;padding: 4px 4px 9px 15px;}
			.akc {background: url('../img/akc.png');width: 66px; height: 26px; margin-bottom: 5px; font-size: 15px; font-weight: 600; text-transform: uppercase; color: $c_White;padding: 2px 4px 9px 15px;}
		}
		&-ttl { text-align: center; font-size: 20px; line-height: 24px; margin-bottom: 0px; display: block; font-weight: 500;
			&:hover, &:focus {color: $c_PastelYellow;}
		}
		&-subttl { text-align: center; font-size: 13px; line-height: 20px; display: block; font-weight: 400; }
		&-img {text-align: center; padding-top: 14px; display: block; min-height: 204px;
			img {display: inline;}
		}
		&-prices {text-align: center; display: block;
			.old {font-size: 14px;line-height: 24px;color: $c_StormDust;text-decoration: line-through;display: block;}
			.cur {font-size: 18px;line-height: 24px; margin-top: 2px; display: block;
				span {font-size: 24px;}
				&:hover, &:focus {color: $c_PastelYellow;}
			}
		}
		&-props {display: none;flex-wrap: wrap;justify-content: center; margin: 28px 0 10px;
			span { display: flex; align-items: center; font-size: 13px; line-height: 24px;  padding: 0 7px;
				img {margin-right: 7px;}
			}
		}
		&-btn {display: none; text-align: center; margin-top: 15px;
			a {font-size: 14px; line-height: 36px; border: 2px solid transparent; padding: 0 26px; color: $c_White; text-transform: uppercase; background: $c_SandBrown;display: inline-block; font-weight: 300;
				&:hover {background: $c_OrangeYellowCraiola;}
			}
		}
		&:hover {background: $c_White;box-shadow: 0 0 26px 2px #0000001c;position: absolute; z-index: 3; display: block; width: 100%;
			.page_catalog-bl {
				&-props {display: flex;}
				&-btn {display: block;}
			}
		}
	}
	&-show_more {display: block; text-align: center;margin-top: 20px;
		button {background: url('../img/show_more.png')no-repeat 80% 50%, #f8f8f8; border: 0;font-size: 20px;font-weight: 300;font-family: 'Montserrat',sans-serif;cursor: pointer;padding: 16px 75px;padding-right: 100px;display: inline-block;
			&:hover, &:focus {background-color: $c_Mercury;}
		}
	}
	&-about { padding: 68px 0 40px; position: relative;z-index: 1;max-width: 928px;
		&-ttl { font-size: 36px; font-weight: 300;  margin-bottom: 45px; text-transform: none; text-align: left;}
		&-cont { display: flex; margin-bottom: 26px;}
		&-col {
			&-sm { width: 132px; flex: 0 0 132px; margin-right: 40px;}
		}
		&-img { text-align: center; padding-top: 7px;}
		&-txt {font-size: 16px;font-weight: 500;line-height: 26px; letter-spacing: -0.9px;}
		&-seo {
			h2, h3 {font-size: 16px; font-weight: 400;line-height: 24px;text-align: left;margin-bottom: 10px; text-transform: none;}
			p {font-size: 12px;line-height: 24px; font-weight: 300;
				&:last-child {
					margin-bottom: 25px;
				}
				& + p {
					margin-top: 10px;
				}
				& + h2,
				& + h2 {
					margin-top: 25px;
				}
			}
		}
		&-bl {margin-bottom: 25px;
			h2, h3 {font-size: 16px; font-weight: 400;line-height: 24px;text-align: left;margin-bottom: 10px; text-transform: none;}
			p {font-size: 12px;line-height: 24px; font-weight: 300;
				& + p {
					margin-top: 10px;
				}
			}
		}
	}
	&-showroom {background: url('../img/showroom.jpg');background-size: cover; padding: 40px 0;
		&-ttl {text-transform: uppercase;font-size: 40px;font-weight: 300;}
		&-city {margin-top: 26px; display: -webkit-flex; display: -moz-flex; display: -ms-flex; display: -o-flex; display: flex; align-items: center;
			span {font-size: 14px;margin-right: 19px;}
			select {width: 100%;max-width: 318px; font-size: 14px;padding: 11px 10px; background: url(../img/sel_arr.png) no-repeat 95% 50%, $c_White;cursor: pointer;}
			.select2-container--default .select2-selection--single .select2-selection__rendered {
				line-height: 18px;
			}
		}
		&-list {margin-top: 45px;}
		&-bl {margin-bottom: 17px;display:block;
			&-ttl {font-weight: 600;font-size: 18px;line-height: 24px;margin-bottom: 2px;display:block;}
			&-txt {font-size: 14px;line-height: 24px;display:block;}
		}
		.select2-container--open .select2-selection__rendered{
			box-shadow: inset 0px 0px 0px 1px $c_PastelYellow;
		}
		span span.select2-selection.select2-selection--single.shopIon {
			margin-right: 0;
			&:focus {
				outline: 0;
				box-shadow: none;
			}
		}
	}
}
.sorting_mdl {
	&.select2-dropdown { margin-top: 0px;
		.select2-results__options { padding: 0!important;
			.select2-results__option { padding: 6px 5px 6px 10px; white-space: nowrap;}
		}
	}
}
.shopSel_mdl {
	&.select2-dropdown { margin-top: -2px;
		.select2-results {
			border: 0;
		}
		.select2-results__options { padding: 0!important;
			.select2-results__option { padding: 6px 5px 6px 10px; }
		}
	}
}
.ion_mdl {
	&.select2-dropdown { margin-top: 0px; width: max-content!important;
		.select2-results__options { padding: 0!important;
			.select2-results__option { padding: 6px 5px 6px 10px; }
		}
	}
}
.headIon { border: 0!important;
	.select2-selection__arrow { display: none; }
	.select2-selection__rendered { background: url(../img/sel_arr.png) no-repeat 90% 50%; font-size: 12px; font-weight: 500; }
}
.shopIon{ border: 0!important;
	.select2-selection__arrow { display: none; }
	.select2-selection__rendered {
		width: 100%;
		max-width: 318px;
		font-size: 14px;
		padding: 11px 10px;
		background: url(../img/sel_arr.png) no-repeat 95% 50%,#fff;
		cursor: pointer;
		border: 0;
	}
}
/*page_catalog*/
@media(max-width: 991px){
	.page_catalog{
		&-top { flex-direction: column;
			&-fltr {flex-wrap: wrap; display: none;
				&>span {margin-bottom: 10px;}
			}
		}
		&-bl {
			&-wp {height: auto; }
			&-props {display: flex;
				span {margin-bottom: 5px;
					img {margin-right: 5px;}
				}
			}
			&:hover {background: none;box-shadow: none;position: static;
				.page_catalog-bl {
					&-btn {display: none;}
				}
			}
		}
		&-about {
			&-cont {flex-wrap: wrap;}
			&-col {
				&-sm {width: 100%;margin-bottom: 20px;}
				&-big { width: 100%;}
			}
		}
		&-show_more button {padding: 16px 100px 16px 48px;}
	}
}

.filt_open {display: none;}
@media(max-width: 991px){
	.filt_open {display: block;border:2px solid $c_SandBrown; text-align: center;font-weight: 400;font-size: 16px;text-transform: uppercase;max-width: 290px;margin: 0 auto 30px;cursor: pointer;padding: 10px 15px;
		&.active {background: $c_SandBrown;color: $c_White;}
	}
}

/*page_catalog*/
.page_search { padding: 50px 0;
	&-ttl {text-align: center;}
	&-cont {margin-top: 25px;}
	&-form {margin-top: 30px; background: #f8f8f8; padding: 20px 0;
		form {display: flex; border: 1px solid transparent; background: $c_White; align-items: center; padding: 9px 0px; margin: 0 auto; max-width: 666px; padding: 9px 15px; border: 1px solid $c_Mercury;
			input {border:0;flex:1;font-weight: 300;font-size: 14px;color: #9e9d9b;}
			button {background: url('../img/h-srch.png')no-repeat 50% 50%;border: 0; padding: 0; margin-left: 26px; width: 22px; height: 22px; cursor: pointer;}
		}
		&.active {width: 393px; transition: 0.7s;
			form {padding: 9px 15px; border: 1px solid $c_Mercury;}
		}
	}
	&-bl {padding: 40px 10px 30px; position: relative; max-width: 340px; margin: 0 auto;
		&-wp {height: 400px; position: relative; max-width: 340px; margin: 0 auto;}
		&-stickers { position: absolute; right: 20px; top: 15px;
			.new {background: url('../img/new.png');width: 80px; height: 26px; margin-bottom: 5px; font-size: 11px; font-weight: 500; text-transform: uppercase; color: $c_White;padding: 4px 4px 9px 15px;}
			.akc {background: url('../img/akc.png');width: 66px; height: 26px; margin-bottom: 5px; font-size: 15px; font-weight: 600; text-transform: uppercase; color: $c_White;padding: 2px 4px 9px 15px;}
		}
		&-ttl { text-align: center; font-size: 20px; line-height: 24px; margin-bottom: 0px; display: block; font-weight: 500; }
		&-subttl { text-align: center; font-size: 13px; line-height: 20px; display: block; }
		&-img {text-align: center; padding-top: 14px; display: block;
			img {display: inline;}
		}
		&-prices {text-align: center;
			.old {font-size: 14px;line-height: 24px;color: $c_StormDust;text-decoration: line-through;}
			.cur {font-size: 18px;line-height: 24px; margin-top: 2px;
				span {font-size: 24px;}
				&:hover {color: $c_PastelYellow;}
			}
		}
		&-props {display: none;flex-wrap: wrap;justify-content: center; margin: 28px 0 10px;
			span { display: flex; align-items: center; font-size: 13px; line-height: 24px;  padding: 0 7px;
				img {margin-right: 7px;}
			}
		}
		&-btn {display: none; text-align: center; margin-top: 15px;
			a {font-size: 14px; line-height: 36px;padding: 0 26px; border: 2px solid transparent; color: $c_White; text-transform: uppercase; background: $c_SandBrown;display: inline-block;
				&:hover {background: $c_OrangeYellowCraiola;}
			}
		}
		&:hover {background: $c_White;box-shadow: 0 0 26px 2px #0000001c;position: absolute; z-index: 3;
			.page_search-bl {
				&-props {display: flex;}
				&-btn {display: block;}
			}
		}
	}
	&-show_more {display: block; text-align: center;margin-top: 20px;
		button {background: url('../img/show_more.png')no-repeat 80% 50%, #f8f8f8; border: 0;font-size: 20px;font-weight: 300;font-family: 'Montserrat',sans-serif;cursor: pointer;padding: 16px 75px;padding-right: 100px;display: inline-block;
			&:hover, &:focus {background-color: $c_Mercury;}
		}
	}
}
/*page_catalog*/
@media(max-width: 991px){
	.page_search{
		&-bl {
			&-wp {height: auto; }
			&-props {display: flex;
				span {margin-bottom: 5px;
					img {margin-right: 5px;}
				}
			}
			&:hover {background: none;box-shadow: none;position: static;
				.page_search-bl {
					&-btn {display: none;}
				}
			}
		}
		&-show_more button {padding: 16px 100px 16px 48px;}
	}
}

/*page_collections*/
.page_collections { padding: 0 0 50px;
	&-top { max-width: 928px;margin: 25px auto; font-size: 14px;line-height: 24px; font-weight: 300;}
	&-ttl {text-align: center;}
	&-cont {margin-top: 25px;margin-top: 55px;}
	&-bl {position: relative; margin-bottom: 100px;
		&-img {display: block;}
		&-info {max-width: 500px;margin: 0 auto;position: absolute;bottom: -40px;background: $c_White;padding: 30px 40px 37px;left: 0;right: 0;margin: 0 auto;box-shadow: 0 0 26px 2px #0000001c;
			&.yellow {background: #f6eee1;}
			&.blue {background: #d7e2e8;}
			&.green {background: #d6fadd;}
			&.red {background: #f9deda;}
		}
		&-ttl {font-size: 20px;font-weight: 600;line-height: 24px;margin-bottom: 5px;display: block;}
		&-txt {font-size: 14px;line-height: 22px;font-weight: 300;}
		&-link {display: block;position: absolute;bottom: 16px;right: 20px;}
	}
	&-about { padding: 0px 0 10px; position: relative;z-index: 1;max-width: 928px;margin: 0 auto;
		&-ttl { font-size: 36px; font-weight: 300;  margin-bottom: 45px;}
		&-cont { display: flex; margin-bottom: 26px;}
		&-col {
			&-sm { width: 132px; flex: 0 0 132px; margin-right: 40px;}
		}
		&-img { text-align: center; padding-top: 7px;}
		&-txt {font-size: 16px;font-weight: 500;line-height: 26px; letter-spacing: -0.9px;}
		&-bl {margin-bottom: 25px;}
		h2 {font-size: 16px;line-height: 24px;margin-bottom: 10px; margin-top: 20px; font-weight: 400; text-align: left; text-transform: none;}
		h3 {font-size: 16px;line-height: 24px;margin-bottom: 10px;}
		p {font-size: 14px;line-height: 24px; font-weight: 300;
			& + p {
				margin-top: 10px;
			}
		}
	}
}
/*page_collections*/
@media(min-width: 992px) and (max-width: 1199px){
	.page_collections{
		&-bl {
			&-ttl {font-size: 16px;}
			&-txt {font-size: 12px;}
		}
	}
}
@media(max-width: 991px){
	.page_collections{
		&-bl {
			&-info {padding: 10px;padding-right: 40px;width: 96%;}
			&-ttl {font-size: 16px;}
			&-txt {font-size: 12px;}
		}
		&-about {
			&-cont {flex-wrap: wrap;}
			&-col {
				&-sm {width: 100%;margin-bottom: 20px;}
				&-big { width: 100%;}
			}
		}
	}
}

/*page_single_collections*/
.page_single_collections {
	h1 {margin-top: 30px;}
	&-infoblock {padding-bottom: 20px;}
	&-slider {text-align: center;padding: 0 40px;}
	&-slide {text-align: center;max-width: 750px;
		img {display: inline-block;}
	}
	&-txt {font-size: 12px;line-height: 24px;font-weight: 400;}
	.slick-dotted.slick-slider {margin: 18px 0 30px;}
	.slick-prev {left: 0;
		&:hover {
			path {stroke:$c_SandBrown;}
		}
	}
	.slick-next {right: 0;
		&:hover {
			path {stroke:$c_SandBrown;}
		}
	}
	.slick-dots {top: auto;bottom: 20px;left: 0;}
	.slick-dots li {margin: 0 2px;}
	.slick-dots li button:before {content: '';width: 10px;height: 10px; background: $c_White;border-radius: 50%;opacity: 1;}
	.slick-dots li.slick-active button:before {background: $c_SandBrown;}
}
/*page_single_collections*/
@media(min-width: 1300px){
	.page_single_collections {
		&-slider {text-align: center;padding: 0 75px;}
		.slick-prev {left: 0;}
		.slick-next {right: 10px;}
		.slick-dotted.slick-slider {margin: 18px auto 30px;}
	}
}
@media(max-width: 991px){
	.page_single_collections h1 {margin: 20px 0;}
}


/*page_promotionList*/
.page_promotionList { padding: 0px 0;
	&-ttl {text-align: center;}
	&-bl {padding: 40px 0;
		&-img {display: block;}
		&-date {font-size: 18px;font-weight: 500;line-height: 24px;margin-bottom: 16px; margin-top: 20px;}
		&-ttl {display: block;font-size: 24px;line-height: 24px;font-weight: 500;color: $c_SunsetOrange;margin-bottom: 20px;text-transform: none;}
		&-txt {font-size: 14px;line-height: 24px;font-weight: 300;}
		&-more {padding-top: 15px;
			a {font-size: 13px;line-height: 24px;border-bottom: 1px solid #000;
				&:hover {border-bottom: 1px solid transparent;}
			}
		}
		&:nth-child(2n){background: #f8f8f8;
			.page_promotionList {
				&-bl{
					&_l {order:2;}
					&_r {order:1;
					}
				}
			}
			.page_promotionList-bl-top {
				padding-right: 10px;
			}
		}
		&:nth-child(2n+1) {
			.page_promotionList-bl-top {
				padding-left: 10px;
			}
		}
	}
}
/*page_promotionList*/
@media(min-width: 1400px) {
	.page_promotionList-bl .row {
		max-width: 1160px;margin: 0 auto;
	}
}
@media(min-width: 992px) and (max-width: 1199px){
	.page_promotionList{
		&-bl {
			&-ttl {font-size: 22px;}
		}
	}
}
@media(max-width: 991px){
	.page_promotionList{
		&-bl {
			&-top {padding: 0 !important;}
			&-ttl {text-align: center;font-size: 18px;}
			&-img {text-align: center;}
			&-date {margin-top: 25px;text-align: center;font-size: 14px;}
			&:nth-child(2n){
				.page_promotionList {
					&-bl{
						&_l {order:1;}
						&_r {order:2;}
					}
				}
			}
		}
	}
}


/*page_promotions*/
.page_promotions {
	&-top {
		&_content {display: flex; align-items: center;}
		&_img {margin-right: 40px; width: 100%; max-width: 460px;
			img {
				display: block;
				margin: 0 auto;
			}
		}
	}
	&-date {font-size: 18px;font-weight: 500;line-height: 24px;margin-bottom: 7px;}
	&-ttl {font-size: 30px;line-height: 36px;font-weight: 500;color: $c_SunsetOrange;margin-bottom: 20px;text-transform: none;}
	&-end {color: $c_SunsetOrange;font-size: 14px;line-height: 24px;text-transform: uppercase;margin-bottom: 10px;}
	&-timer { display: flex; justify-content: flex-start; margin: 5px 0; align-items: center;
		&>span { border: 1px solid #f00; font-size: 12px; padding: 15px 5px; width: 72px;text-align: center;
			&.sep { border: 0; font-size: 20px; font-weight: 600; padding: 0; margin: 0 9px;width: auto; }
		}
	}
	&-txt {font-size: 16px;line-height: 24px;  margin: 50px 0 55px;
		p {margin-bottom: 25px;font-size: 14px;}
		b {font-weight: 600;font-size: 16px;margin-bottom: 10px; display: inline-block;}
		a {opacity: 1;
			&:hover {opacity: 0.7;}
		}
	}
	&-about { padding: 40px 0 40px; position: relative;z-index: 1;margin: 0 auto; background: #f8f8f8; color: #1b1a16;
		&-ttl { font-size: 36px; font-weight: 300;  margin-bottom: 45px;}
		&-cont { display: flex; margin-bottom: 26px;}
		&-col {
			&-sm { width: 132px; flex: 0 0 132px; margin-right: 40px;}
		}
		&-img { text-align: center; padding-top: 7px;}
		&-txt {font-size: 16px;font-weight: 500;line-height: 26px; letter-spacing: -0.9px;		}
		&-bl {margin-bottom: 25px;
			h3 {font-size: 16px;line-height: 24px;margin-bottom: 10px; font-weight: 500;}
			p {font-size: 12px;line-height: 24px; font-weight: 400;margin-bottom: 10px;}
		}
	}
}
/*page_promotions*/
@media(min-width: 1200px) {
	.page_promotions-about .container {
		max-width: 927px;
	}
}
@media(min-width: 992px) and (max-width: 1199px){
	.page_promotions{
		&-ttl {font-size: 22px;}
	}
}
@media(max-width: 991px){
	.page_promotions{
		&-top {
			&_content {
				display: block;
			}
			&_img {
				margin-right: 0;
				max-width: none;
				text-align: center;
			}

		}
		&-ttl {text-align: center;font-size: 18px;}
		&-end {text-align: center;}
		&-date {margin-top: 25px;text-align: center;font-size: 14px;}
		&-timer {justify-content: center;margin-top: 20px;}
		&-about {
			&-cont {flex-wrap: wrap;}
			&-col {
				&-sm {width: 100%;margin-bottom: 20px;}
				&-big { width: 100%;}
			}
		}
	}
}


/*page_cooperation*/
.page_cooperation { padding: 0 0 50px;
	&-top { max-width: 928px;margin: 20px auto 45px; font-size: 14px;line-height: 24px; font-weight: 300;
		p {margin-bottom: 10px;}
	}
	&-ttl {text-align: center;}
	&-img {margin-top: 25px;text-align: center; margin-bottom: 30px;}
	&-about { padding: 40px 0; position: relative;z-index: 1;max-width: 928px;margin: 0 auto; border-bottom: 1px solid #e7e6e6;
		&-ttl { font-size: 36px; font-weight: 300;  margin-bottom: 45px;}
		&-cont { display: flex; margin-bottom: 26px;}
		&-col {
			&-sm { width: 132px; flex: 0 0 132px; margin-right: 40px;}
		}
		&-img { text-align: center; padding-top: 7px;}
		&-txt {font-size: 16px;font-weight: 500;line-height: 26px; letter-spacing: -0.9px;}
		&-bl {margin-bottom: 25px;
			h2, h3 {font-size: 30px;font-weight: 400; line-height: 24px;margin-bottom: 22px; text-transform: none; text-align: left;}
			p {font-size: 14px;line-height: 24px; font-weight: 300; margin-bottom: 15px;
				&:last-child {
					margin-bottom: 0;
				}
			}
		}
	}
}
/*page_cooperation*/
@media(min-width: 992px) and (max-width: 1199px){
	.page_cooperation{
		&-bl {
			&-ttl {font-size: 16px;}
			&-txt {font-size: 12px;}
		}
	}
}
@media(max-width: 991px){
	.page_cooperation{
		&-bl {
			&-info {padding: 10px;padding-right: 40px;width: 96%;}
			&-ttl {font-size: 16px;}
			&-txt {font-size: 12px;}
		}
		&-about {
			&-cont {flex-wrap: wrap;}
			&-col {
				&-sm {width: 100%;margin-bottom: 20px;}
				&-big { width: 100%;}
			}
		}
	}
}

/*page_clients*/
.page_clients {
	&-ttl {text-align: center;}
	h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	&-cont {padding-bottom: 50px;border-bottom: 1px solid $c_Mercury;
		p {font-size: 14px;font-weight: 300;line-height: 24px;margin-bottom: 10px;
			&.dfl {display: flex;justify-content: space-between;flex-wrap:wrap;
				img {margin-bottom: 15px; max-width: 30%;}
			}
		}
		h2 {font-size: 30px;line-height: 24px;margin-bottom: 25px; margin-top: 40px; text-align: left; text-transform: none;}
		h3 {font-size: 25px;line-height: 24px;margin-bottom: 15px;}
		h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	}
	.info_sidebar {border-right: 1px solid #e7e6e6;}
	&-list {
		margin-bottom: 50px;
	}
	.inner_bottom-seemore {
		margin-top: 20px;
	}
	.info_sidebar_title {
		font-size: 20px;
		font-weight: 500;
		line-height: 24px;
		margin-bottom: 20px;
	}
}
/*page_clients*/
@media(max-width: 767px){
	.page_clients {
		&-ttl {
			margin-top: 40px;
		}
		&-seemore {
			&-ttl {font-size: 24px; font-weight: 400;text-align: center;}
		}
	}
}

/*about_products*/
.about_products {
	&-top { max-width: 928px;margin: 25px auto; font-size: 14px;line-height: 24px; font-weight: 400;
		p {font-weight: 300;margin-bottom: 10px;}
		h2 {font-size: 30px;line-height: 24px;margin-bottom: 25px;margin-top: 35px;text-transform: none;text-align: left;font-weight: 400;}
		h3 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	}
	&-ttl {text-align: center;}
	&-img {margin-top: 45px;text-align: center; margin-bottom: 60px;}
	h3 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	&-cont {padding-bottom: 30px;border-bottom: 1px solid $c_Mercury; max-width: 928px;margin: 25px auto;
		p {font-size: 14px;font-weight: 300;line-height: 24px;margin-bottom: 15px;}
		h2 {font-size: 30px;line-height: 24px;margin-bottom: 15px;margin-top: 15px;text-transform: none;text-align: left;}
		h3 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	}
	&-slider { max-width: 928px;margin: 40px auto 60px;
		.slick-list {
			margin: 0 -15px;
		}
		.slick-prev {left: -65px;text-align: center;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
		.slick-next {right: -50px;text-align: center;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
		img {display: inline-block; width: 100%; margin: 0 auto !important; }
		.slick-dots li {
			padding: 0;
			&:before {
				display: none;
			}
		}
	}
	&-slide {text-align: center; padding: 0 15px;}
}
/*about_products*/
@media(max-width: 1199px) and (min-width: 992px){
	.about_products {
		&-slider {
			.slick-prev {left: -85px;}
			.slick-next {right: -50px;}
		}
	}
}
@media(max-width: 991px){
	.about_products-slider {
		.slick-dots {padding: 0;
			li button:before { content: ''; width: 10px; height: 10px; background: #c8c9c9; border-radius: 50%; opacity: 1; }
			li.slick-active button:before { background: #000; }
		}
	}
}
@media(max-width: 767px){
	.about_products {
		&-seemore {
			&-ttl {font-size: 24px; font-weight: 400;text-align: center;}
		}
	}
}

/*inner_bottom*/
.inner_bottom {
	&-seemore {padding: 35px 0 75px;margin-top: 50px;background: #f8f8f8;
		&-ttl {margin-bottom: 25px;padding: 10px 0; text-transform: uppercase; font-size: 40px; font-weight: 300; text-align: center;}
		&-bl {margin: 0 auto 20px;display: block; max-width: 318px;
			&-img {display: block;text-align: center; max-height: 210px; overflow: hidden;
				img {transform: scale(1);transition: 0.3s;}
			}
			&-ttl {font-size: 18px;line-height: 24px;font-weight: 300;display: block;margin-top: 10px;transition: 0.3s;}
			&:hover {
				.inner_bottom-seemore-bl {
					&-img {
						img {transform: scale(1.2);transition: 0.3s;}
					}
					&-ttl {color: $c_SandBrown;transition: 0.3s;}
				}
			}
		}
	}
}
/*inner_bottom*/
@media(max-width: 767px){
	.inner_bottom {
		&-seemore {
			&-ttl {font-size: 24px; font-weight: 400;text-align: center;}
		}
	}
}

/*info_sidebar*/
.info_sidebar {margin-top: 20px; min-height: 100%;
	h3 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	&-list {
		a, li {cursor: pointer; font-size: 13px;line-height: 24px;text-transform: uppercase;position: relative;border:2px solid #f3f3f3; background: #f3f3f3;display: block;padding: 6px 10px;margin-bottom: 19px; padding-right: 27px;max-width: 287px;
			&:last-child { margin-bottom: 0; }
			&:hover, &:focus, &.active {background: transparent; border-color: #f9c25e;}
			&:after {content: '';width: 10px; height: 6px; background: url(../img/sel_arr.png) no-repeat 50% 50%; position: absolute; display: block; right: 15px; top: 50%; margin-top: -3px; transform: rotate(-90deg);}
		}
	}
}

@media(max-width: 991px) {
	.info_sidebar {
		border: none !important;
		&-list {
			font-size: 0;
			margin-left: -15px;
			li, a {
				display: inline-block;
				width: 50%;
				margin-left: 15px
			}
		}
	}
}
@media(max-width: 767px) {
	.info_sidebar-list {
		li, a {
			width: auto;
		}
	}
}
@media(max-width: 480px) {
	.info_sidebar-list {
		margin-left: 0;
		li, a {
			margin-left: 0;
			width: 100%;
			max-width: none;
		}
	}
}
/*info_sidebar*/


/*page_shop*/
.page_shop {
	&-top { max-width: 928px;margin: 25px auto; font-size: 14px;line-height: 24px; font-weight: 300;
		p {margin-bottom: 10px;}
	}
	&-slider { max-width: 825px;margin: 55px auto 10px;text-align: center;
		.slick-prev {left: -85px;text-align: center;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
		.slick-next {right: -68px;text-align: center;
			&:hover {
				path {stroke:$c_SandBrown;}
			}
		}
		img {display: inline-block;}
	}
	&-slider_pag { max-width: 825px;margin: 10px auto 25px; text-align: center; overflow: hidden;
		.slick-list {margin: 0 -6px;}
		img {display: inline-block;cursor: pointer;}
		.page_shop-slide {padding: 0 6px;opacity: 0.6;}
		.slick-current {
			.page_shop-slide {opacity: 1;}
		}
	}
	&-slide {text-align: center;}
	&-ttl {text-align: center;}
	h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	&-cont {padding-bottom: 30px;border-bottom: 1px solid $c_Mercury; max-width: 928px;margin: 25px auto 0;
		p {font-size: 14px;font-weight: 300;line-height: 24px;margin-bottom: 10px;
			&.dfl {display: flex;justify-content: space-between;flex-wrap:wrap;
				img {margin-bottom: 15px; max-width: 30%;}
			}
		}
		h3 {font-size: 30px;line-height: 24px;margin-bottom: 15px;}
		h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
		ul {padding-left: 25px;
			li {color: $c_SandBrown;padding-left: 35px; position: relative; color: #000;font-size: 14px;line-height: 36px;font-weight: 400;
				&:before {content: '';position: absolute;top:50%; margin-top: -2px;left: 15px;background: $c_SandBrown;display: block;width: 5px; height: 5px;border-radius: 50%;}
				a {color: #000;
					&:hover {color: $c_SandBrown;}
				}
			}
		}
	}
	&-btm {padding-top: 40px;padding-bottom: 90px; max-width: 928px;margin: 0 auto;
		&-bl {margin-bottom: 15px;
			&-ttl {color: $c_SandBrown;font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 18px;}
			&-info {font-size: 16px;line-height: 24px; margin-bottom: 8px;
				span {font-weight: 300;}
			}
		}
	}
	&-map {
		.map {height: 550px;}
	}
}
/*page_shop*/
@media(max-width: 991px){
	.page_shop {
		&-slider {
			.slick-dots {padding: 0;
				li button:before { content: ''; width: 10px; height: 10px; background: #c8c9c9; border-radius: 50%; opacity: 1; }
				li.slick-active button:before { background: #000; }
			}
		}
		&-slider_pag {margin: 0 auto;
			.slick-list {margin: 0 -10px;}
			.page_shop-slide {padding: 0 10px;}
		}
	}
}
@media(max-width: 991px){
	.page_shop {
		&-map {
			.map {height: 80vw;}
		}
	}
}


@media(max-width: 991px){
	.page_catalog_shop {
	}
}
@media(max-width: 991px){
	.page_catalog_shop {
		&-map {
			.map {height: 80vw;}
		}
	}
}
@media(max-width: 767px){
	.page_shop {
		&-slider_pag {display: none;}
	}
}

/*page_mattress*/
.page_mattress {
	&-top { max-width: 928px;margin: 25px auto 45px; font-size: 14px;line-height: 24px; font-weight: 400;
		p {font-weight: 400;margin-bottom: 10px;}
	}
	&-anchors {text-align: center;background: #f8f8f8; padding: 40px 0;
		&-ttl {font-size: 18px;text-transform: uppercase;margin-bottom: 40px;}
		&-line {display: flex;justify-content: center;align-items: flex-start; max-width: 706px;margin: 0 auto 35px; position: relative;
			&:before {content: '';z-index: 1;position: absolute;width: 100%;height: 1px;background: #e5e5e5;top:14px; left: 0;}
			&-num {display: block;font-weight: 500;font-size: 18px;line-height: 22px;background: $c_White;border-radius: 50%;max-width: 27px;border:1px solid #e5e5e5;margin: 0 auto 5px;padding: 3px 5px 0px;transition: 0.3s;}
			&-txt {display: block;font-weight: 300;font-size: 14px;line-height: 22px;transition: 0.3s;}
			&-bl {display: block;margin: 0 25px;z-index: 2; width: 106px;
				&:hover, &:focus {
					.page_mattress-anchors-line {
						&-num {background: $c_SandBrown;border-color:$c_SandBrown;color: $c_White;transform: scale(1.2, 1.2); transition: 0.3s;}
						&-txt {font-weight: 500;transition: 0.3s;}
					}
				}
			}
		}
		&-btn { text-align: center;
			a {max-width: 228px;font-size: 15px;text-transform: uppercase;color: $c_White;font-weight: 300;background: $c_SandBrown;text-align: center;display: inline-block;padding: 10px 24px;border: 2px solid transparent;opacity: 1; transition: 0.3s;
				&:hover, &:focus {opacity: 0.7; transition: 0.3s;}
			}
		}
	}
	&-ttl {text-align: center;}
	h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	&-cont {padding-bottom: 30px; max-width: 928px;margin: 65px auto 25px;
		p {font-size: 14px;font-weight: 300;line-height: 24px;margin-bottom: 15px;
			&.dfl {display: flex;justify-content: space-between;flex-wrap:wrap;
				img {margin-bottom: 15px; max-width: 30%;}
			}
		}
		h2 {font-size: 30px;font-weight: 400; line-height: 24px;margin-bottom: 22px;text-align: left;text-transform: none;}
		h3 {font-size: 30px;line-height: 24px;margin-bottom: 25px;}
		h4 {font-size: 20px;line-height: 24px;font-weight: 500;margin-bottom: 20px;}
	}
	&-table {background: #f8f8f8;padding: 50px 0;
		&-wp {max-width: 100%;overflow-x: auto; overflow-y: hidden;
			table {width: 100%;text-align: left;
				th {vertical-align: middle; font-size: 14px;line-height: 36px;color: $c_SandBrown;text-transform: uppercase;padding: 5px 0;padding-right: 15px;border-bottom: 1px solid #d7d7d6;}
				td {padding: 18px 0;padding-right: 15px;border-bottom: 1px solid #d7d7d6;
					&.name {font-size: 16px;line-height: 36px;
						b {font-weight: 500;}
					}
					&.step { font-size: 14px;line-height: 24px;
						b {font-weight: 500;}
					}
					&.chars {color: $c_StormDust;font-size: 14px;line-height: 18px;
						b{font-weight: 600;color: #000;}
					}
					&.for {font-size: 14px;line-height: 24px;}
				}
				.name {min-width: 140px;}
				.step {min-width: 240px;}
				.chars {min-width: 260px;}
				.for {min-width: 490px;}

				tbody {
					tr:last-child {
						td {border-bottom: 0;}
					}
				}
			}
		}
	}
	&-kinds {padding: 50px 0; position: relative;
		&:after {content: ''; max-width: 1130px; margin: 0 auto; position: absolute; bottom: 0; left: 0;right: 0;height: 1px; background: $c_Mercury;}
		&-ttl {padding: 10px 0; text-transform: uppercase; font-size: 40px; font-weight: 300; margin-bottom: 50px;text-align: center;}
		&-bl { margin-bottom: 40px;
			&-ttl {color: $c_SandBrown;font-size: 30px;font-weight: 500;margin-bottom: 15px;}
			&-top {font-size: 14px;line-height: 24px;font-weight: 300; margin-bottom: 15px;
				b {font-weight: 500;}
			}
			&-img {text-align: center;margin-bottom: 25px;
				img {
					width: 100%;
					max-width: 550px;
				}
			}
			&-full {font-size: 13px;line-height: 20px;margin-bottom: 15px;
				span {text-transform: uppercase;font-weight: 500;}
			}
			&-txt {font-size: 14px;line-height: 24px;font-weight: 300;margin-bottom: 15px; height: 96px;overflow: hidden;
				&.active {height: auto;}
			}
			&-url {text-align: right;
				a {font-size: 13px;line-height: 24px;text-decoration: underline;
					&:focus {text-decoration: underline;}
					&:hover {text-decoration: none;}
				}
			}
		}
		.col-lg-6 {
			&:nth-child(2n+1) {
				padding-right: 35px;
			}
			&:nth-child(2n) {
				padding-left: 35px;
			}
		}
	}
}
/*page_mattress*/
@media(min-width: 768px) and (max-width: 991px){
	.page_mattress {
		&-table {
			&-wp {
				table {
					.name {
						min-width: 90px;
					}
					.step {
						min-width: 150px;
					}
					.chars {
						min-width: 220px;
					}
					.for {
						min-width: 220px;
					}
					th {
						font-size: 11px;
					}
					td {

					}
				}
			}
		}
	}
}
@media(max-width: 767px){
	.page_mattress {
		&-anchors {
			&-line {
				&-bl { margin: 0 5px; width: 80px;}
				&-txt { font-weight: 300; font-size: 8px;}
			}
		}
	}
}
@media(max-width: 991px){
	.page_mattress {
		//&-anchors {
		//	&-line {flex-wrap: wrap; justify-content: center; align-items: center;
		//		&:before {display: none;}
		//		&-bl {margin: 0 11px 15px; width: 41%;}
		//	}
		//}
		&-kinds .col-lg-6 {
			&:nth-child(2n+1) {
				padding-right: 15px;
			}
			&:nth-child(2n) {
				padding-left: 15px;
			}
		}
	}
}
@media(max-width: 767px){
	.page_mattress {
		&-table {
			.container {
				max-width: 100%;
			}
			&-wp {
				table {
					th {
						font-size: 14px;
						line-height: 1.5;
					}
					td {
						&.name {
							font-size: 14px;
							line-height: 1.5;
						}
						&.step {
							font-size: 14px;
							line-height: 1.5;
						}
						&.chars {
							font-size: 14px;
							line-height: 1.5;
						}
						&.for {
							font-size: 14px;
							line-height: 1.5;
						}
					}
					.name {
						min-width: 12vw;
					}
					.step {
						min-width: 12vw;
					}
					.for {
						min-width: 12vw;
					}
					.chars {
						min-width: 12vw;
					}
				}
			}
		}
	}
}

@media(max-width: 480px){
	.page_mattress {
		&-table {
			&-wp {
				table {
					th {
						font-size: 3.3vw;
						line-height: 1.5;
					}
					td {
						&.name {
							font-size: 3.3vw;
							line-height: 1.5;
						}
						&.step {
							font-size: 3.3vw;
							line-height: 1.5;
						}
						&.chars {
							font-size: 3.3vw;
							line-height: 1.5;
						}
						&.for {
							font-size: 3.3vw;
							line-height: 1.5;
						}
					}
				}
			}
		}
	}
}

/*checkout-page*/
.checkout-page {padding: 25px 0 70px;
    .info_sidebar {margin-left: auto;margin-top: 0;padding-top: 7px; max-width: 320px; position: relative;
		h3 {font-size: 18px;font-weight: 300;}
	}
	&-ttl {text-align: center;margin-top: 31px;}
	.private_office-content {
		padding-top: 7px;
		padding-right: 77px;
	}
	.form_title {
		margin-bottom: 11px;
	}
	.form_select {
		margin-bottom: 15px;
	}
	.form_textarea {
		margin-bottom: 25px;
	}
	&-cart {
		&-img {text-align: center;margin-bottom: 30px;padding-top: 40px;}
		&-ttl {font-size: 16px;line-height: 24px;font-weight: 500;text-decoration: underline;margin-bottom: 20px;display: block;}
		.left {width: 58%; padding-right: 10px;}
		.right {width: 42%;}
		&-params {margin-bottom: 25px;}
		&-row { display: flex; justify-content: space-between; align-items: flex-start; font-size: 13px; line-height: 16px; margin-bottom: 10px;}
		&-cnt { display: flex; justify-content: space-between; align-items: flex-start; font-size: 14px; line-height: 24px; margin-bottom: 5px;
			.right {text-align: right;
				.num {background: none;vertical-align: middle;color: #000;}
				.minus, .plus {border-radius: 50%; font-size: 22px; width: 30px;height: 30px;vertical-align: middle;cursor: pointer;}
			}
		}
		&-price { display: flex; justify-content: space-between; align-items: flex-start; font-size: 14px; line-height: 24px; margin-bottom: 10px;
			.right {text-align: right;}
		}
		&-deliv { display: flex; justify-content: space-between; align-items: flex-start; font-size: 14px; line-height: 24px; margin-bottom: 30px;
			.right {text-align: right;}
		}
		&-all { display: flex; justify-content: space-between; align-items: flex-end; font-size: 16px; line-height: 24px; font-weight: 500; margin-bottom: 15px;
			.right {font-size: 18px;font-weight: 600;text-align: right;}
		}
		&-prepay { display: flex; justify-content: space-between; align-items: flex-end; font-size: 16px; line-height: 24px; font-weight: 500; margin-bottom: 15px;
			.right {font-size: 18px;font-weight: 600;text-align: right;}
		}
		&-und { display: flex; justify-content: space-between; align-items: flex-end; font-size: 13px; line-height: 24px; margin-top: -20px;
			.right {text-align: right;}
		}
		&-info {
			margin-top: 30px;
			font-size: 14px;
			line-height: 1.33;
		}
	}
	.form_submit {
		margin-top: 35px;
	}
}
/*checkout-page*/
@media(min-width: 992px){
	.checkout-page {
		.info_sidebar {
			&:before {content: ''; background: #e7e6e6; width: 1px; height: 100%;position: absolute;left: -14px;top: 40px;}
		}
	}
}
@media(min-width: 1200px){
	.checkout-page {
		.info_sidebar {
			&:before {left: -60px;}
		}
		.container {
			max-width: 1163px;
		}
	}
}
@media(max-width: 991px){
	.checkout-page {padding: 25px 0 70px;
		.info_sidebar {margin: 0 auto;
			h3 {text-align: center;}
		}
	}
}

/*modal_window*/
.modal_window {max-width: 320px; width: 100%;
	&.from_button_like {
		.modal_window-ttl {margin-bottom: 20px;}
		.modal_window-sub_ttl {display: block; font-size: 13px; line-height: 1.33; margin-bottom: 25px;}
	}
	&.mattr {max-width: 600px;
		img {
			max-width: 100%;
		}
	}
	&-wp {
		.fancybox-skin {border-radius: 0px; background: $c_White; padding: 50px 58px!important;}
		.fancybox-close { background: none;right: 5px; top: 5px;
			&:before {content: '×';font-size: 40px; font-weight: 400; color: $c_SilverChalice;}
		}
		.fancybox-title.fancybox-title-float-wrap {
			display: none;
		}
		&.options_2 {
			.page_mattress-kinds-bl-img {
				display: none;
			}
			.js_modal_mattress_content {
				margin: 1px 0;
				font-weight: 500;
				font-size: 13px;
				line-height: 18px;
			}
		}
		&.options_3 {
			.modal_window {
				width: auto;
				max-width: 100%;
			}
			.page_mattress-kinds-bl-img {
				margin-bottom: 0;
			}
			.js_modal_mattress_content {
				display: none;
			}
		}
	}
	&-ttl {color: $c_RangoonGreen;font-size: 24px;line-height: 24px;font-weight: 300;margin-bottom: 40px;text-transform: uppercase;}
	&-sub_ttl {display: none;}
	&-txt {color: $c_RangoonGreen;font-size: 13px;line-height: 18px;font-weight: 300;margin-bottom: 20px;}
	&-soc {color: $c_RangoonGreen;font-size: 14px;line-height: 24px;font-weight: 300;margin-top: 20px;
		span {display: block;margin-bottom: 10px;}
	}
	&-urls {margin-bottom: 40px;
		a {font-size: 13px;line-height: 24px;font-weight: 300;border-bottom: 1px dashed #000;margin-right: 15px;}
		span {font-size: 13px;line-height: 24px;font-weight: 300;}
	}

	.message {
		font-size: 13px;
		line-height: 1.33;
		margin-bottom: 5px;
	}
}
#modal_forgot {
	.modal_window {
		&-ttl {margin-bottom: 15px;}
	}
}

.modal_forgot {
	.staticErrMsg {
		font-size: 13px;
		line-height: 1.33;
		margin-bottom: 5px;
	}
}
/*modal_window*/
@media(max-width: 991px){
	.modal_window {
		&-wp {
			.fancybox-skin {padding: 45px 20px 30px !important;}
		}
	}
}

/*page_product*/
.page_product {
	&-ttl {font-size: 24px;line-height: 24px;font-weight: 500; text-transform: none;padding: 15px 0;padding-left: 60px;
		span {font-size: 15px;line-height: 24px;font-weight: 400;}
	}
	&-price {text-align: center;padding-left: 24px;
		.cur {font-size: 18px;line-height: 24px;font-weight: 500;
			span {font-size: 24px;}
		}
		.old {font-size: 14px;line-height: 24px;font-weight: 300;color: $c_StormDust;text-decoration: line-through;}
	}
	&-actions {display: flex; width: 189px;float: right;flex-wrap: wrap;}
	&-parts {position: relative;
		&-btn {width: 40px;height: 40px;border:1px solid $c_PastelYellow;position: relative; display: block;
			&:before {content: '';width: 24px;height: 24px;background: url('../img/pay_part.png')no-repeat 50% 0%;position: absolute;top: 7px;left: 7px;}
			&:hover, &:focus {background: $c_SandBrown;border-color: $c_SandBrown;
				&:before {background: url('../img/pay_part.png')no-repeat 50% 100%;}
			}
		}
		&-hidden {cursor: pointer; overflow: hidden;width: 0; position: absolute; left: 100%;margin-left: 0px;top: 0;font-size: 13px;border-color:$c_PastelYellow;border-style: solid; border-width: 1px 0px; line-height: 36px;text-align: center; padding: 1px 0;transition: 0.3s; white-space: nowrap;
			&:hover {background: $c_SandBrown!important; border-color: $c_SandBrown; color: $c_White;}
		}
		&:hover {
			.page_product-parts-hidden {width: 140px;margin-left: 9px;border-width: 1px;padding: 1px 10px; background: $c_White;transition: 0.3s;}
		}
	}
	&-buy {
		button {width: 140px; margin-left: 9px;font-size: 14px;line-height: 36px;border:0; color: $c_White;text-align: center; padding: 1px 0;transition: 0.3s; padding: 0 8px; border: 2px solid transparent; text-transform: uppercase; background: $c_SandBrown; cursor: pointer;
			&:hover {background: $c_OrangeYellowCraiola;}
		}
	}
	&-mnth {width: 100%;text-align: right;font-size: 14px;margin-top: 12px;padding-right: 17px;}

	&-thumbs { max-width: 96px; margin: 0px 0; padding: 20px 0;
		&-slide {margin: 10px 0;cursor: pointer; position: relative;
			.img-origin { position: relative; z-index: 2; }
			.img-mask { z-index: 1; max-width: 100%; width: 100%; position: absolute; top: 0; left: 0; }
			.canvas-mask { z-index: 1; max-width: 100%; width: 100%; position: absolute; top: 0; left: 0; opacity: .95; filter: brightness(.9) brightness(.9) contrast(1.3) contrast(1.3) saturate(150%); }
		}
		.slick-arrow {width: 18px;height: 10px;background: url('../img/thumb_arrs.png')no-repeat;left: 0; right: 0; margin: 0 auto;}
		.slick-prev {background-position: 50% 0%; top:5px;
			&:before {display: none;}
		}
		.slick-next {background-position: 50% 100%; top:auto ;bottom: 0;
			&:before {display: none;}
		}
		.slick-slide {opacity: 0.33;}
		.slick-current {opacity: 1;}
	}
	&-link {max-width: 96px;margin-top: 30px;text-align: center;
		a {opacity: 1;transition: 0.3s;display: inline-block;
			&:hover, &:focus {opacity: 0.7;transition: 0.3s;}
		}
	}
	&-slider_block {
		font-size: 0;
		&:before {
			content: '';
			display: inline-block;
			vertical-align: middle;
			width: 0;
			height: 100%;
		}
	}
	&-sliders { margin-bottom: 50px; }
	&-slider { margin-top: 0px;
		display: inline-block;
		vertical-align: middle;
		width: 100%;
		position: relative;
		z-index: 1;
		&-slide {text-align: center; position: relative;
			img {display: inline-block;}
			.img-origin { position: relative; z-index: 2; }
			.img-mask { z-index: 1; max-width: 100%; width: 100%; position: absolute; top: 0; left: 0; }
			.canvas-mask { z-index: 1; max-width: 100%; width: 100%; position: absolute; top: 0; left: 0; opacity: .95; filter: brightness(.9) brightness(.9) contrast(1.3) contrast(1.3) saturate(150%); }
		}
		.slick-arrow {width: 20px;height: 36px;background: url('../img/pp-sldr_arrs.png')no-repeat;top: 0; bottom: 0; margin: auto 0;}
		.slick-prev {background-position: 0% 50%; left:0; right: auto;
			&:before {display: none;}
		}
		.slick-next {background-position: 100% 50%; left:auto; right: 0;
			&:before {display: none;}
		}
	}
	&-like {
		text-align: right;
		position: absolute;
		top: 0;
		right: 15px;
		z-index: 2;
		button {
			background: none;
			border: 0;
			&:hover{
				svg {
					path {
						stroke: $c_SandBrown;
					}
				}

			}
			&.active{
				svg {
					path {
						stroke: $c_SandBrown;
						fill: $c_SandBrown;
					}
				}

			}
		}
	}

	&-size {padding-bottom: 5px; max-width: 840px;margin: 0 auto; margin-top: 20px;
		&-ttl {text-transform: uppercase;font-size: 18px;line-height: 36px;font-weight: 500;text-align: center;margin-bottom: 11px;}
		&-line {
			.irs-line {background: none; height: 7px; top: 32px; cursor: pointer;}
			.irs-handle.single {top: 25px; margin-left: -8px; background: $c_SandBrown;border-color: $c_SandBrown;color: #fff;border-radius: 50%;width: 20px;height: 20px;cursor: pointer;transform: translateX(6px);}
			.irs-handle.single i {display: none;}
			.irs-bar.irs-bar--single { display: none; }
			.irs-grid-text {top: -37px;bottom: auto;font-weight: 300;font-size: 14px;color: #000;font-family: 'Montserrat', sans-serif;}
			.irs-grid-pol {	background: #fff; margin-left: -7px; border-radius: 50%;	max-width: 27px;	border: 1px solid #bdbdbd;	padding: 3px 5px 0;	width: 12px;	height: 12px;	font-weight: 500;	top: -10px;    pointer-events: none;}
			.irs-grid-pol.small { display: none; }
			.irs-line:before { background: #E5E5E4; height: 1px; top: 50%; content: ''; width: 100%; display: block; left: 0; position: absolute; margin-top: 0px; }

			.irs--flat .irs-single {
				background: #fff;
				z-index: 33;
				bottom: auto;
				top: -3px;
				transform: translateX(0px);
				padding-left: 8px;
				padding-right: 4px;
				font-size: 14px;
				color: #000;
				font-weight: 500;
				font-family: 'Montserrat',sans-serif;
			}
			.irs--flat .irs-single:before {
				display: none;
			}
		}
		&-line2 {display: flex;justify-content: center;align-items: flex-start; margin: 0 auto 35px; position: relative;
			&:before {content: '';z-index: 1;position: absolute;width: 100%;height: 1px;background: #e5e5e5;bottom:6px; left: 0;}
			&-circ {display: block;font-weight: 500;font-size: 18px;line-height: 22px;background: $c_White;border-radius: 50%;max-width: 27px;border:1px solid #e5e5e5;margin: 15px auto 0px;padding: 3px 5px 0px;width: 12px;height: 12px;transition: 0.3s;}
			&-txt {display: block;font-weight: 300;font-size: 14px;line-height: 22px;transition: 0.3s;}
			&-bl {display: block;margin: 0 5px;z-index: 2; width: 70px; cursor: pointer;text-align: center;
				&:hover, &:focus, &.active {
					.page_product-size-line2 {
						&-circ {background: $c_SandBrown;border-color:$c_SandBrown;color: $c_White;transform: scale(1.4, 1.4); transition: 0.3s;}
						&-txt {font-weight: 500;transition: 0.3s;}
					}
				}
			}
		}
	}
	&-colors {margin-top: 20px;padding-bottom: 5px; max-width: 840px;margin: 0 auto; margin-top: 20px;
		&-ttl {text-transform: uppercase;font-size: 18px;line-height: 36px;font-weight: 500;text-align: center;margin-bottom: 13px;}
		&-tabs {display: flex;justify-content: center;align-items: flex-start;}
		&-tab {font-size: 14px;line-height: 25px;flex-grow: 1;border-bottom: 1px solid $c_Mercury; margin-bottom: 1px;text-align: center;padding-bottom: 2px; height: 28px;
			&.active {margin-bottom: 0px;border-width: 2px;border-color: $c_SandBrown;font-weight: 600;height: 29px;}
			&:hover {border-color: $c_PastelYellow;}
		}
		&-cont {display: none; padding-top: 24px;
			&.active {display: block;}
		}
		&-subttl {text-transform: uppercase; font-weight: 600; font-size: 14px;
			img {display: inline-block;vertical-align: middle;transform: translateY(-1px)}
		}
		&-row {display: flex;flex-wrap: wrap; width: calc(100% + 10px); margin: 0 -5px;margin-top: 8px;}
		&-hidden { display: none;position: absolute; padding: 30px; padding-bottom: 15px; background: #fff; bottom: 120%; width: auto; width: max-content; z-index: 34; box-shadow: 0 0 26px 2px #0000001c; left: 50%; transform: translateX(-50%);
			&-ttl { text-align: center; font-size: 14px; font-weight: 300; line-height: 36px; margin-top: 10px; }
		}
		&-bl {border:2px solid transparent;padding: 3px; width: 67px; height: 67px; margin: 0 5px 5px; cursor: pointer; position: relative;
			&>img {width: 57px;height: 57px;}
			&:hover, &.active {border-color: $c_SandBrown;}
			&:hover {
				.page_product-colors-hidden { display: block;}
			}
		}
		&-mob-more {display: none;}
	}
	&-cloth_type {display: none;}
    &-bok { padding-bottom: 5px; max-width: 840px; margin: 0 auto; margin-top: 20px;
		&-ttl {  text-transform: uppercase;  font-size: 18px;  line-height: 36px;  font-weight: 500;  text-align: center;  margin-bottom: 11px;}
		&-row {display: flex;flex-wrap: wrap;}
		&-bl {padding: 7px;width: 168px; cursor: pointer; position: relative;
			&-img {
				display: block;
				text-align: center;
				img {
					width: 100%;
					max-width: 123px;
				}
			}
			&-ttl {
				display: block;
				text-align: center;
				font-size: 13px;
				line-height: 36px;
				font-weight: 400;}
			&-hidden {display: none;position: absolute; padding: 30px 30px; ; background: #fff; bottom: 120%; width: auto; width: max-content; z-index: 3; box-shadow: 0 0 26px 2px #0000001c; left: 50%; transform: translateX(-50%);    max-width: 400px;
				&-ttl {
					font-size: 16px;
					line-height: 36px;
					font-weight: 500;
					text-transform: uppercase;
				}
				&-subttl {
					font-weight: 500;
					font-size: 13px;
					line-height: 18px;
                    margin-bottom: 10px;
				}
				&-txt {
					font-size: 14px;
					line-height: 24px;
					font-weight: 300;
				}
			}
			&:hover {box-shadow: inset 0px 0px 0px 1px $c_SandBrown;
              .page_product-bok-bl{
                &-hidden {
                  display: block;}
              }
			}
			&.active {box-shadow: inset 0px 0px 0px 2px $c_SandBrown;
			}
		}
    }
	&-data {background: #f8f8f8;padding: 45px 0 67px;
		&-tabs {padding-left: 19px;display: flex;justify-content: space-between;align-items: flex-start;padding-right: 73px;max-width: 920px;position: relative;
			&:before {content: '';position: absolute;bottom: 2px;height: 1px;background: $c_Mercury;width: calc(100% - 15px);z-index: 1;}
		}
		&-tab {font-size: 16px;line-height: 25px;border-bottom: 2px solid transparent; margin-bottom: 1px;text-align: center;padding-bottom: 13px; position: relative;z-index: 2;
			&.active {margin-bottom: 0px;border-width: 2px;border-color: $c_SandBrown;font-weight: 600;}
			&:hover {border-color: $c_SandBrown;}
		}
		&-text {display: none;
			&>* {display: none;margin-bottom: 10px;}
			&>*:first-child {display: block;}
			.show_more { text-align: right; padding-top: 14px; display: block;
				span { font-size: 13px; line-height: 24px; font-weight: 400; border-bottom: 1px solid #000; cursor: pointer; display: inline!important; transition: 0.3s;
					&:hover {color: $c_SandBrown; border-bottom: 1px solid $c_SandBrown; transition: 0.3s;}
				}
			}
		}
		&-video-wrap {
			position: relative;
			padding-bottom: 56.25%;
			height: 0;
			iframe {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		&-cont {font-size: 13px;line-height: 24px;font-weight: 300;padding-left: 19px;margin-top: 30px;max-width: 920px;
			&-mob {display: none; position: relative;
				&:after {
					content: '';
					width: 14px;
					height: 14px;
					background: url(../img/pps-arr.png) no-repeat 50% 50%;
					position: absolute;
					top: 50%;
					right: 12px;
					margin-top: -7px;
					transform: rotate(0deg);
					transition: .3s;
				}
			}
			&.active {
				.page_product-data-text {display: block;}
				.page_product-data-cont-mob:after {
					transform: rotate(180deg);
				}
			}
			&.full {
				.page_product-data-text {
					& > * {display: block;}
				}
			}
			ul {
				li {color: $c_SandBrown;padding-left: 35px; position: relative; color: $c_Black;font-size: 13px;line-height: 26px;font-weight: 300;
					&:before {content: '';position: absolute;top:50%; margin-top: -2px;left: 15px;background: $c_SandBrown;display: block;width: 5px; height: 5px;border-radius: 50%;}
					a {color: #000;
						&:hover {color: $c_SandBrown;}
					}
				}
			}
		}
		&-sticker {display: block; padding-top: 36px;
			img {margin-right: 6px;}
		}
		&-change {
			max-width: 400px;
			.left {width: 58%; padding-right: 10px;}
			.right {width: 42%;}
			&-row { display: flex; justify-content: space-between; align-items: flex-start; font-size: 13px; line-height: 16px; margin-bottom: 10px;}
		}
	}
	&-cheap {padding: 70px 0 0px;
		&-cont {max-width: 898px;margin: 0 auto;padding: 24px 49px;border:1px solid $c_Mercury;}
		&-ttl {color: $c_SunsetOrange;font-weight: 500;font-size: 20px;line-height: 24px;}
		&-row {display: flex;padding: 15px 0;justify-content: space-between;align-items: flex-start;}
		&-bl {max-width: 188px;width: 100%;
			&-img {position: relative;padding: 28px 0;text-align: center;margin-bottom: 15px;display:block;opacity: 1; transition: 0.3s;
				&:hover {opacity: 0.7; transition: 0.3s;}
			}
			&-stickers {position: absolute; right: -6px; top: 0px;width: 100%;text-align: right;
				.akc {background: url(../img/akc.png); width: 66px; height: 26px; margin-bottom: 5px; font-size: 15px; font-weight: 600; text-transform: uppercase; color: #fff; padding: 2px 4px 9px 15px;display: inline-block;text-align: center;}
			}
			&-ttl {font-size: 14px;line-height: 18px;display:block;
				&:hover {color: $c_SandBrown;}
			}
			&-price {font-size: 14px;font-weight: 500;padding-top: 11px;
				span {font-size: 18px;line-height: 24px;font-weight: 600;}
				.old {color: $c_StormDust;font-size: 14px;line-height: 16px;text-decoration: line-through;font-weight: 300;}
				.cur {font-size: 14px;font-weight: 500;line-height: 24px;color: $c_SunsetOrange;
					span {font-size: 18px;line-height: 24px;font-weight: 600;}
				}
			}
		}
		&-plus {margin: 0 15px;padding-top: 59px;}
		&-equal {margin: 0 15px;padding-top: 59px;}
		&-result {max-width: 225px;width: 100%;padding: 15px 0;
			&-price {font-size: 30px;line-height: 24px;font-weight: 700;color: $c_SunsetOrange;text-align: center;}
			&-btn {font-size: 14px; line-height: 36px;padding: 0 26px; border: 2px solid transparent; color: $c_White; text-transform: uppercase; background: $c_SandBrown;display: inline-block; cursor: pointer; width: 100%; max-width: 225px; margin: 15px auto; font-weight: 300; text-align: center;
				&:hover {background: $c_OrangeYellowCraiola;}
			}
			&-econ {font-size: 14px;line-height: 24px;color: $c_SunsetOrange;text-align: center;}
		}
	}
	.popular-ttl {font-size: 30px;}
	.novelty-ttl {font-size: 30px;}
	&-sidebar {border-left: 1px solid $c_Mercury;padding-left: 29px; margin-top: 22px;padding-top: 3px; margin-bottom: 50px; cursor: pointer;
		&-prom {
			padding: 5px 0 20px;
			border-bottom: 1px solid $c_Mercury;
			margin-bottom: 15px;
			.title {
				font-size: 18px;
				font-weight: 500;
				color: $c_SunsetOrange;
				line-height: 1.33;
				margin-bottom: 10px;
			}
			.terms_link {
				display: inline-block;
				font-size: 13px;
				font-weight: 300;
				line-height: 1.33;
				color: $c_RangoonGreen;
				border-bottom: 1px dashed $c_RangoonGreen;
				margin-bottom: 10px;
				@include transition;
				&:hover {
					border-color: transparent;
				}
			}
			.terms_text {
				display: none;
				font-size: 14px;
				font-weight: 300;
				line-height: 1.5;
				padding-bottom: 10px;
			}
			.promo_timer {
				margin: 0;
				& > span {
					width: 60px;
				}
				& > span.sep {
					width: auto;
					margin: 0 8px;
				}
			}
		}
		&-ttl {font-weight: 400;font-size: 16px;line-height: 24px;margin-bottom: 23px;}
		&-options {margin-bottom: 30px;}
		&-burg {margin-bottom: 19px;
			&:last-child {
				margin-bottom: 0;
			}
			&-name {background: #f3f3f3; border:2px solid #f3f3f3; font-size: 13px;line-height: 24px;text-transform: uppercase;display: block;padding: 4px 6px;padding-left: 32px;position: relative;cursor: pointer;transition: 0.3s;
				&:hover, &:focus {border-color:$c_SandBrown; background: $c_White;transition: 0.3s;}
				&:before {content: ''; width: 14px; height: 14px; background: url('../img/pps-arr.png')no-repeat 50% 50%; position: absolute;top:50%;left: 12px;margin-top: -7px;transform: rotate(-90deg);transition: 0.3s;}
				&.active {
					&:before {transform: rotate(0deg);transition: 0.3s;}
				}
			}
			&-cont {display: none; padding: 27px 0 14px; border-bottom: 1px solid $c_Mercury;
				.col-6 {
					&:nth-child(2n + 1) {
						padding-right: 5px;
					}
					&:nth-child(2n) {
						padding-left: 5px;
					}
				}
			}
		}
		&-bl {
			&-img {text-align: center;padding: 2px;}
			&-ttl {text-align: center;font-size: 12px; line-height: 1.1; display: flex; justify-content: center;align-items: center; margin-top: 3px;
				span {margin-right: 5px;}
				a {opacity: 1;transition: 0.3s; flex-shrink: 0; font-size: 0; line-height: 1;
					&:hover {opacity: 0.6;transition: 0.3s;}
					img {
						width: 100%;
					}
				}
			}
			&:hover {
				.page_product-sidebar-bl {
					&-img {box-shadow: inset 0px 0px 0px 1px $c_PastelYellow;}
				}
			}
			&.active {
				.page_product-sidebar-bl {
					&-img {box-shadow: inset 0px 0px 0px 2px $c_PastelYellow;}
				}
			}
			&-htc {font-size: 12px;line-height: 18px;text-align: center;color: $c_White; background: $c_SandBrown;display: block; text-transform: uppercase;padding: 10px 0px;border: 2px solid transparent;margin-top: 34px;transition: 0.3s;
				&:hover, &:focus {background: $c_OrangeYellowCraiola;color: $c_White;transition: 0.3s;}
			}
		}
		&-change {
			&-ttl {font-size: 18px;font-weight: 500;line-height: 16px;margin-bottom: 20px;}
			.left {width: 58%; padding-right: 10px;}
			.right {width: 42%;}
			&-row { display: flex; justify-content: space-between; align-items: flex-start; font-size: 13px; line-height: 16px; margin-bottom: 10px;}
		}
	}
	.page_catalog-showroom { z-index: 2; position: relative; }
	&-size-line {
		position: relative;
		.points {
			width: 460px;
			max-width: 100%;
			margin: 0 auto;
			&:before {
				content: '';
				width: 100%;
				max-width: 666px;
				height: 1px;
				position: absolute;
				top: 10px;
				bottom: 0;
				right: 0;
				left: 0;
				margin: auto;
				background-color: #e5e5e4;
			}
		}
		.irs-line:before {
			display: none;
		}
	}
}
/*page_product*/

@media(min-width: 992px) and (max-width: 1199px) {
	.page_product {
		&-slider {
			&-slide {padding: 0 20px;}
			.slick-arrow {top: 50%;	margin-top: -18px; margin-bottom: 0;}
		}
	}
}
@media(max-width: 991px){
	.page_product {
		&-slider {
			.slick-dots li button:before {content: '';width: 10px;height: 10px; background: $c_White; border-radius: 50%;opacity: 1;}
			.slick-dots li.slick-active button:before {background: $c_SandBrown; border:1px solid $c_SandBrown;}
		}
		&-ttl { padding: 15px 0; font-size: 16px; line-height: 24px; font-weight: 500;
			span { font-size: 14px; }
		}
		&-size {
			&-ttl { text-align: left; font-size: 14px; font-weight: 600; line-height: 36px; }
			&-line { margin: 0px 0px; flex-wrap: wrap; justify-content: flex-start;
				&:before {display: none;} .page_product-size-line-circ { display: none; }
				&-bl { margin: 0 4px 10px; border: 1px solid #bebebe; padding: 5px 3px; width: 59px;
					&.active { border: 1px solid #F3AD52; background: #F3AD52; font-weight: 600; }
				}
			}
		}
		&-colors {border-bottom: 1px solid $c_Mercury; position: relative; overflow: hidden; padding-bottom: 70px;
			&.full {max-height: 9999px}
			&-ttl { text-align: left; font-size: 14px; font-weight: 600; line-height: 20px; margin-bottom: 0; }
			&-subttl {font-size: 13px;line-height: 20px;font-weight: 400;text-transform: none;
				img {display: none;}
			}
			&-bl {width: 46px;height: 46px;
				&>img {width: 36px;height: 36px;}
			}
			&-cont {padding-top: 15px;}
			&-hidden {display: none!important;}
			&-mob-more { display: block;width: calc(100% + 30px); margin: 0 -15px; background: #fff; position: absolute; bottom: 0; text-align: center; padding-bottom: 23px; box-shadow: -1px -16px 16px 1px #fff; z-index: 2;
				button { border: 2px solid #f3ad52; background: #fff; font-size: 16px; line-height: 24px; text-transform: uppercase; padding: 6px 10px; width: 100%; max-width: 281px; }
			}
		}
        &-bok {
		  &-row {position: relative;}
          &-bl {
			  position: static;
            width: 144px;
            &-hidden {
				max-width: calc(100vw - 30px);
				left: 0;
				transform: translate(0);
				bottom: 100%;
			}
          }
        }
		&-data {
			&-cont {padding-left: 0;
				&-mob {display: block;font-size: 16px;line-height: 24px;font-weight: 300;border-bottom: 1px solid $c_Mercury;padding: 5px 0;margin-bottom: 10px;}
			}
			&-tabs {display: none;}
			&-sticker {padding-left: 0;}
		}
		&-sidebar {border: 0;padding-left: 0;
			&-ttl {font-size: 14px;line-height: 24px;}
		}
		&-cheap {
			&-cont { padding: 20px 9px; }
			&-row { flex-wrap: wrap; justify-content: center; }
			&-bl { max-width: 106px;
				&-img { height: 159px; display: flex; align-items: center; text-align: center; width: 100%; }
				&-ttl { height: 37px; -webkit-line-clamp: 2; display: -webkit-box; -webkit-box-orient: vertical; overflow: hidden; }
			}
			&-plus { margin: 0 11px; }
			&-equal {
				width: 100%;
				text-align: center;
			}
		}
		.popular-ttl {font-size: 24px; font-weight: 400; overflow: visible; max-width: 9999px; white-space: normal;}
	}
}
@media(max-width: 991px){
	.page_product {
		&-size {
			&-line{
				.irs-grid-text { font-weight: 400;font-size: 8px;visibility: visible!important;}
			}
		}
	}
}

i {font-style: italic;}

/*footer*/
footer {background: #2a2924;color: $c_White;padding: 40px 0 0px;}
.f {
	&-small {font-size: 12px;line-height: 30px;margin-bottom: 4px;}
	&-phn {
		a {font-weight: 500;font-size: 22px;line-height: 30px;}
	}
	&-ml {
		a {font-weight: 600;font-size: 14px;line-height: 18px;}
	}
	&-adr {font-size: 12px;line-height: 24px;margin-top: 29px;}
	&-soc {margin-top: 30px;
		a {margin-right: 10px;}
	}
	&-bl {margin-bottom: 20px;
		&1 {
			margin-left: -27px;
		}
		&2 {
			margin-left: -6px;
		}
		&3 {
			margin-left: 13px;
		}
	}
	&-ttl {font-size: 16px;line-height: 30px;margin-bottom: 10px;}
	&-menu {
		li {
			a {font-size: 12px;line-height: 30px;font-weight: 300;
				&:hover, &:focus {text-decoration: underline;}
			}
		}
	}
	&-medal {max-width: 70px;margin-top: 20px;}
	&-btm {margin-top: 20px;background: $c_RangoonGreen; padding: 14px 0;
		.container {justify-content: space-between;display: flex;}
	}
	&-copy {font-size: 12px;line-height: 18px;font-weight: 300;}
	&-dev {font-size: 12px;line-height: 18px;font-weight: 300;
		img {transform: translateY(5px)}
	}

}
/*footer*/
@media(min-width: 1200px){
	.f {
		&-bl {margin-bottom: 20px;
			&1 {
				margin-left: -27px;
			}
			&2 {
				margin-left: -6px;
			}
			&3 {
				margin-left: 13px;
			}
		}
	}
}
@media(max-width: 991px){
	footer { padding-top: 30px;}
	.f {
		&-copy {font-size: 10px;margin-bottom: 10px;}
		&-bl1,
		&-bl3 {margin-left: 0;}
	}
}
@media(max-width: 767px){
	.f {
		&-btm {
			.container {flex-direction: column;}
		}
		&-bl1,
		&-bl2,
		&-bl3 {
			margin-left: 0;
		}
	}
}


.slick-dots {
    li {
        margin: 0 2px;
        &.slick-active {
            button {
                &:before {
                    background: $c_SandBrown;
                }
            }
        }
    }
}

.private_office-page {
    padding: 25px 0 70px;
    .info_sidebar {
        margin-left: auto;
        margin-top: 0;
    }
    .page_catalog-cont {
        margin: 0 0 -70px;
    }
}

.private_office-content {
    padding-top: 30px;
}
.private_office-content h2 {
    padding: 10px 0;
    text-transform: uppercase;
    font-size: 40px;
    font-weight: 300;
    margin: 5px 0 15px;
}

.po_form_addres {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .form_field {
        &:nth-child(1) {
            width: 40px;
        }
        &:nth-child(2) {
            width: 70px;
        }
        &:nth-child(3) {
            width: 60px;
        }
        &:nth-child(4) {
            width: 40px;
        }
    }
}

.po_form {
    .form_textarea {
        margin-bottom: 50px;
    }
}

.my_orders_item {
    border-bottom: 1px solid $c_Silver;
}

.my_orders_item-title {
    display: flex;
    align-items: center;
    height: 45px;
    padding-right: 80px;
    font-size: 14px;
    font-weight: 300;
    color: $c_RangoonGreen;
    position: relative;
    .number {
        width: 145px;
        padding-right: 15px;
    }
    .date {
        width: 145px;
        padding-right: 15px;
    }
    .status {
        width: 175px;
        padding-right: 15px;
        &.done {
            color: $c_Fern;
        }
        &.cancel {
            color: $c_SunsetOrange;
        }
    }
    .price {
        padding-right: 15px;
        flex-shrink: 0;
    }
    .more_details {
        cursor: pointer;
        position: absolute;
        right: 0;
        font-size: 13px;
        font-weight: 400;
        padding-bottom: 2px;
        border-bottom: 1px solid $c_Thunder;
        @include transition;
        &:hover,
        &.active {
            color: $c_SandBrown;
            border-color: $c_SandBrown;
        }
    }
}

.my_orders_item-hide_content {
    display: none;
}

.my_orders_item-descr {
    display: flex;
    align-items: flex-start;
    padding: 15px 0;
    font-size: 13px;
    padding-left: 210px;
    position: relative;
    .photo {
        position: absolute;
        top: 0;
        left: 0;
        width: 200px;
        img {
            max-width: 100%;
        }
    }
    .characteristics {
        flex-grow: 1;
        padding-right: 10px;
        &-title {
            display: inline-block;
            font-size: 16px;
            font-weight: 600;
            border-bottom: 1px solid $c_MineShaft;
            margin-bottom: 20px;
        }
        &-item {
            display: flex;
            align-items: flex-start;
            line-height: 1.33;
            margin-bottom: 10px;
            &:last-child {
                margin-bottom: 0;
            }
        }
        .name {
            width: 170px;
            min-width: 170px;
            padding-right: 10px;
        }
        .value {
            max-width: 140px;
        }
    }
    .customer_info {
        border-left: 1px solid $c_Silver;
        padding-left: 20px;
        max-width: 100%;

        &-item {
            display: flex;
            align-items: flex-start;
            line-height: 1.5;
            margin-bottom: 10px;
        }

        .name {
            font-weight: 500;
            width: 135px;
            min-width: 135px;
            padding-right: 10px;
        }

        .value {
            max-width: 225px;
        }
    }
}

@media(min-width: 992px){
    .private_office-page {
        .info_sidebar {
            padding-left: 40px;
            &:before {content: ''; background: #e7e6e6; width: 1px; height: 100%;position: absolute;left: 22px;top: 40px;
                }
        }
    }
}

// Shops catalog
.page_catalog_shop {
    padding-bottom: 35px;
    &-ttl {
        text-align: center;
        margin-bottom: 40px;
    }

    &-map {
        .map {
            height: 550px;
            margin-bottom: 70px;
        }
    }
}

.gm-style {
    .gm-style-iw {
        font-family: 'Montserrat', sans-serif;
        width: 285px;
        padding: 10px 0 5px 20px !important;
        border-radius: 0;
        box-shadow: 0px 0px 20px 0px rgba(15, 15, 12, 0.1);
        button {
            display: none !important;
        }
    }
    .gm-style-iw-t {
        bottom: 51px !important;
        &:after {
            display: none;
        }
    }
}

.gm-iw-title {
    font-size: 14px;
    font-weight: 500;
    color: $c_RangoonGreen;
    line-height: 1;
    padding-bottom: 10px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    span {
        display: inline-block;
        vertical-align: bottom;
    }
}

.gm-iw-company-icon {
    flex-shrink: 0;
    width: 24px;
    margin-left: 10px;
}

.gm-iw-info-item {
    font-size: 12px;
    font-weight: 400;
    color: $c_StormDust;
    margin-top: 3px;
}

.form-catalog_shop {
    width: 100%;
    max-width: 565px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    .select2-container {
        flex-grow: 1;
        margin-right: 20px;
        height: 40px;
        .select2-selection--single
        {
            height: 100%;
            border: 1px solid $c_Mercury;
            outline: none;
            .select2-selection__rendered {
                height: 100%;
                line-height: 38px;
                outline: none;
                padding: 0 30px 0 20px;
                font-size: 14px;
                font-weight: 300;
				color: $c_StormDust2;
				position: relative;
				&:before {
					content: '';
					width: 6px;
					height: 6px;
					border-right: 1px solid #4c4b48;
					border-bottom: 1px solid #4c4b48;
					transform: rotate(45deg);
					margin: auto 0;
					position: absolute;
					top: -2px;
					bottom: 0;
					right: 11px;
					z-index: 1;
				}
            }
            .select2-selection__arrow {
                display: none;
            }
        }
        &.select2-container--focus .select2-selection--single {
            border-color: $c_Mercury;
        }
    }
    .form_submit {
        flex-grow: 0;
        width: 130px;
        min-width: auto;
        height: 38px;
        line-height: 34px;
    }
}

.select2-dropdown {
    margin-top: -40px;
    border: none;
}

.select2-search--dropdown {
    padding: 0;
}

.select2-container--form .select2-search--dropdown .select2-search__field,
.select2-container--default .select2-search--dropdown .select2-search__field {
    border: 1px solid $c_Mercury;
    height: 40px;
    line-height: 38px;
    padding: 0 30px 0 20px;
    margin-bottom: 2px;
}

.select2-container--form .select2-search--dropdown .select2-search__field {
	border: none;
	border-bottom: 1px solid $c_Silver;
}

.select2-results {
    border: 1px solid $c_Mercury;
}

.select2-container--form .select2-results {
    border: 1px solid $c_Silver;
}

.select2-results__options {
    font-size: 13px;
    font-weight: 400;
    color: $c_RangoonGreen;
}

.select2-container--form .select2-results>.select2-results__options,
.select2-container--default .select2-results>.select2-results__options {
    padding: 16px 0 18px 0;
    max-height: 330px;
}

.select2-results__option {
    padding: 8px 5px 8px 20px
}

.select2-container--form .select2-results__option[aria-selected=true],
.select2-container--default .select2-results__option[aria-selected=true] {
    background-color: $c_SmokyWhite;
}

.select2-container--form .select2-results__option--highlighted[aria-selected],
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: $c_SmokyWhite;
    color: $c_Black;
}

.catalog_shop-item {
    margin-bottom: 35px;
    &_link {
        display: block;
        height: 100%;
        max-width: 370px;
        position: relative;
        padding-bottom: 31px;
        padding-right: 40px;
        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            display: block;
            width: 100%;
            height: 1px;
            background-color: $c_Silver;
        }
    }
    .company_icon {
        cursor: default;
        position: absolute;
        top: 5px;
        right: 0;
        width: 35px;
        svg {
            width: 100%;
        }
        &:hover .company_icon-hover {
            opacity: 1;
        }
        &-hover {
            position: absolute;
            bottom: 30px;
            left: 14px;
            white-space: nowrap;
            height: 30px;
            line-height: 30px;
            padding: 0 15px 0 12px;
            font-size: 12px;
            font-weight: 300;
            color: $c_StormDust;
            background-color: $c_White;
            box-shadow: 0px 0px 20px 0px rgba(15, 15, 12, 0.1);
            @include transition;
            opacity: 0;
        }
    }
    .title {
        font-size: 20px;
        color: $c_RangoonGreen;
        line-height: 1.33;
        margin-bottom: 11px;
        display: block;
        @include transition;
    }
    .info {
        margin-bottom: 15px;
    }
    .info_item {
        font-size: 14px;
        color: $c_StormDust;
        line-height: 1.33;
        @include transition;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }
    }
    .shown_on_map {
        cursor: pointer;
        display: inline-block;
        font-size: 13px;
        text-decoration: underline;
        @include transition;
        &:hover {
            text-decoration: none;
        }
    }
}

.form_select .select2-container--default .select2-search--dropdown .select2-search__field {
	border: none;
	border-bottom: 1px solid $c_RangoonGreen;
}

.information-page {
	padding-bottom: 90px;
}

@media(max-width: 1399px) {
    .my_orders_item-descr {
        flex-wrap: wrap;
        .characteristics,
        .customer_info {
            width: 100%;
            .value {
                max-width: none;
            }
        }
        .characteristics {
            padding-right: 0;
        }
        .customer_info {
            border-left: none;
            padding-left: 0;
            margin-top: 20px;
            padding-top: 20px;
            border-top: 1px solid #cdcdcd
        }
    }
}

@media(max-width: 991px) {
	.h-srch {
		button {
			z-index: 1;
			&.active {
				left: auto;
			}
		}
		&-form {
			right: auto;
			left: 0;
			&.active {
				width: 260px;
			}
			form {
				button {
					display: block;
					margin-right: 0;
				}
			}
		}
	}

    .private_office-page {
        .left_block {
            order: 2;
        }
        .right_block {
            order: 1;
        }
        .info_sidebar {
            margin-left: 0;
        }
    }

    .checkout-page .private_office-content {
        padding-right: 0;
    }

    .form_submit {
        display: block;
        margin: 0 auto 50px;
    }

    .page_mattress-kinds-bl-url a:hover,
    .catalog_shop-item .shown_on_map:hover,
    .checkout-page-cart-ttl:hover {
        text-decoration: underline;
    }

    .page_product-data-text .show_more span:hover {
        color: $c_Black;
        border-bottom: 1px solid $c_Black;
    }

    .my_orders_item-title .more_details.active,
    .my_orders_item-title .more_details:hover {
        color: $c_RangoonGreen;
        border-color: $c_RangoonGreen;
    }

    .page_product-price {
        padding-left: 0;
    }

    .page_product-top {
        margin-bottom: 30px;
    }

    .h-menu>ul>li>a {
        display: inline-block;
        &:hover {
            color: inherit;
        }
    }

    .h-menu>ul>li.dropdown:hover .h-submenu {
        display: none;
    }

    .h-menu>ul>li.dropdown>a.active {
        color: $c_SandBrown;
        &+ .h-submenu {
            display: block;
        }
    }

    .slick-dotted.slick-slider {
        padding-bottom: 25px;
    }

    .slick-dotted.slick-slider {
        margin-bottom: 20px;
    }

    .page_single_collections .slick-dotted.slick-slider,
    .page_shop-slider.slick-dotted {
        margin-bottom: 20px;
    }

    .slick-dots,
    .page_single_collections .slick-dots {
        bottom: -5px;
    }

    .main .slick-dots li button:before,
    .page_single_collections .slick-dots li button:before,
    .page_product-slider .slick-dots li button:before {
        background-color: $c_Silver2;
        border-color: $c_Silver2;
    }

    .slick-dots li.slick-active button:before,
    .page_single_collections .slick-dots li.slick-active button:before,
    .page_product-slider .slick-dots li.slick-active button:before {
        background-color: $c_Black;
        border-color: $c_Black;
    }

    .page_single_collections-slider {
        padding-left: 0;
        padding-right: 0;
    }

    .page_product-cloth_type {
        cursor: pointer;
        display: block;
        background: #f3f3f3;
        border: 2px solid #f3f3f3;
        font-size: 13px;
        line-height: 24px;
        text-transform: uppercase;
        display: block;
        padding: 4px 6px 4px 32px;
        margin-top: 15px;
        position: relative;
        transition: .3s;
        &:before {
            content: '';
            width: 14px;
            height: 14px;
            background: url(../img/pps-arr.png) no-repeat 50% 50%;
            position: absolute;
            top: 50%;
            left: 12px;
            margin-top: -7px;
            transform: rotate(-90deg);
            -webkit-transition: .3s;
            transition: .3s;
        }
        &.active:before {
            transform: rotate(0deg);
            transition: .3s;
        }
    }

    .page_product-colors-tabs {
        flex-direction: column;
        align-items: flex-start;
        padding-left: 15px;
        padding-bottom: 5px;
        border-bottom: 1px solid $c_Mercury;
    }

    .page_product-colors-tabs-wrapper {
        display: none;
    }

    .page_product-colors-tab {
        border: none;
        padding-bottom: 0;
        margin-top: 5px;
        margin-bottom: 0;
        line-height: 30px;
        height: auto;
        &.active {
            height: auto;
            font-weight: normal;
            color: $c_SandBrown;
        }
    }

    .page_product-colors-conts {
        max-height: 300px;
        &.full {
            max-height: none;
        }
    }
}

@media(max-width: 767px) {
    .my_orders_item-title {
        padding-left: 135px;
        align-items: flex-start;
        flex-wrap: wrap;
        height: auto;
        padding-top: 10px;
        .number {
            position: absolute;
            left: 0;
            width: auto;
        }
        .date,
        .status,
        .price {
            width: 100%;
            padding-right: 0;
            margin-bottom: 10px;
        }
    }
    .my_orders_item-descr {
        padding-left: 0;

        .photo {
            width: 290px;
            position: static;
        }
    }
    .catalog_shop-item {
        &_link {
            max-width: none;
        }
    }
    .company_icon-hover {
        display: none;
    }
    .categories-bl {
        margin-bottom: 10px;
    }
}

// fonts and buttons
@media (max-width: 767px) {
    h1 {
        font-size: 30px !important;
    }
    h2 {
        font-size: 24px !important;
    }
    h3 {
        font-size: 18px !important;
    }
    h1, h2, h3 {
        font-weight: 400 !important;
    }
    .h-menu>ul>li>a,
    .h-graf,
    .headIon .select2-selection__rendered,
    .h-lang .dropdown.span,
    .h-lang .dropdown>a,
    .product-slider-bl-props span,
    .product-slider-bl-subttl,
    .product-slider-bl-timer>span,
    .about-bl p,
    .f-small,
    .f-adr,
    .filt_open,
    .page_catalog-bl-props span,
    .page_catalog-top-sort span,
    .page_catalog-top-sort span,
    .inner_bottom-seemore-bl-ttl,
    .page_catalog-bl-subttl,
    .page_catalog-bnnr-date,
    .form_select .select2-selection__rendered,
    .form_field,
    .checkout-page-cart-row,
    .checkout-page-cart-und,
    .info_sidebar-list a,
    .info_sidebar-list li,
    .page_mattress-kinds-bl-full span,
    .page_mattress-kinds-bl-full,
    .page_product-colors-subttl,
    .page_product-sidebar-burg-name,
    .page_product-sidebar-change-row,
    .page_product-sidebar-bl-ttl,
    .page_product-data-change-row,
    .page_promotions-about-bl p,
    .page_search-bl-subttl,
    .page_search-bl-props span,
    .page_single_collections-txt {
        font-size: 14px;
    }
	.f-menu li a,
	.page_catalog-about-seo p,
    .page_catalog-about-bl p,
    .form_select_dd .select2-results__option,
    .form_submit,
    .page_collections-bl-txt,
    .page_product-data-cont,
    .page_product-bok-bl-ttl {
        font-size: 14px;
        font-weight: 400;
    }
    .categories-bl-ttl,
    .about_products-top p,
    .page_clients-cont p,
    .about_products-cont p,
    .other-site-sections ul li a,
    .other-site-sections ul li span,
    .simple_content ul li a,
    .simple_content ul li span,
    .page_catalog-show_more button,
    .form_subtitle,
    .page_collections-top,
    .page_cooperation-top,
    .page_mattress-cont p,
    .page_mattress-kinds-bl-top,
    .page_mattress-kinds-bl-txt,
    .inner_bottom-seemore-bl-ttl,
    .my_orders_item-title,
    .page_product-data-cont-mob,
    .page_catalog-showroom-ttl,
    .page_promotionList-bl-txt,
    .page_shop-top {
        font-weight: 400;
    }

    .h-map-select .select2-container {
        min-width: 70px;
    }
    .page_catalog-top-sort .select2 {
        min-width: 175px;
    }

    .page_mattress-anchors-line-txt {
        font-weight: 400;
        font-size: 10px;
    }

    .filt_open,
    .form-catalog_shop .form_submit,
    .form_submit,
    .page_mattress-anchors-btn a,
    .page_product-sidebar-bl-htc,
    .page_product-cheap-result-btn {
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
        line-height: 38px;
        border-width: 1px;
        font-size: 16px;
	}

	.filt_open,
	.page_product-colors-mob-more button {
		border-width: 2px;
	}

    .page_mattress-anchors-btn a {
        max-width: none;
    }

    .page_catalog-show_more button,
    .page_search-show_more button {
        padding-top: 0;
        padding-bottom: 0;
        height: 40px;
        line-height: 40px;
        font-size: 16px;
    }

    .page_catalog-show_more button,
    .page_search-show_more button {
        background-size: 22px;
        padding-left: 85px;
	}

	.page_mattress-table-wp {
		table {
			display: block;
			tr {
				display: flex;
				flex-wrap: wrap;
			}
			.for {
				font-size: 0;
			}
			td, th {
				border: none;
				&:nth-child(1) {
					width: 29%;
				}
				&:nth-child(2) {
					width: 31%;
				}
				&:nth-child(3) {
					width: 40%;
					padding-right: 0;
				}
				&:nth-child(4) {
					width: 100%;
					border-bottom: 1px solid #d7d7d6;
					padding-top: 7px;
					padding-right: 0;
					br {
						display: none;
					}
				}
			}
		}
	}
}

@media(max-width: 575px) {
    .page_product-actions {
        float: none;
        margin: 15px auto;
    }
}

@media(max-width: 480px) {
    .my_orders_item-title {
        padding-right: 0;
        .more_details {
            position: static;
            margin-bottom: 15px;
        }
    }
    .form-catalog_shop .select2-container .select2-selection--single .select2-selection__rendered {
        background: url(../img/sel_arr.png) no-repeat 96% 50%,#fff;
    }
    .form-catalog_shop {
        flex-wrap: wrap;
        .select2-container {
            width: 100%;
            margin-right: 0;
            margin-bottom: 20px;
        }
        .form_submit {
            width: 100%;
        }
    }

    .page_catalog_shop-map {
        .map {
            height: 400px;
            margin-bottom: 50px;
        }
    }

    .catalog_shop-item {
        .title {
            font-size: 16px;
        }
    }
}

@media (max-width: 375px) {
    .page_mattress-anchors-line-txt {
        font-size: 8px;
    }
}

.interior_images .fancybox-inner {
	overflow: visible !important;
}

.modal_interior {
	width: 100%;
	max-width: 900px;
}

.modal_interior_slider {
	padding: 0 5px !important;
	margin: 0 !important;
	.slick-arrow {
		width: inherit;
		height: inherit;
	}
	.slick-prev {
		left: -30px;
	}
	.slick-next {
		right: -30px;
	}
	// .slick-dots {
	// 	top: auto;
	// 	bottom: 20px;
	// 	left: 0;
	// 	li {
	// 		margin: 0 2px;
	// 		button:before {

	// 		}
	// 	}
	// }
}

.interior_image_item {
	img {
		margin: 0 auto
	}
}

@media (max-width: 991px) {
	.modal_interior_slider {
		margin-bottom: 20px !important;
		.slick-dots {
			bottom: -30px !important;
		}
	}
}