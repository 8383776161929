svg, svg path, a, button {
    @include transition;
}

h2 {
    font-size: 30px;
    font-weight: 300;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 40px;
}

.tab_list-content {
    li {
        display: none;

        &.active {
            display: block;
        }
    }
}

$placeholderSelectors: '::-webkit-input-placeholder' '::-moz-placeholder' ':-ms-input-placeholder' ':-moz-placeholder';
@each $selector in $placeholderSelectors {
    * #{$selector} {
        color: $c_RangoonGreen;
        font-family: 'Montserrat', sans-serif;
        font-size: 13px;
    }
}

.relative {
    position: relative;
}

.form {
    color: $c_RangoonGreen;
}

.form_title {
    font-size: 18px;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 25px;
}

.form_field {
    font-size: 13px;
    color: $c_RangoonGreen;
    width: 100%;
    height: 35px;
    line-height: 34px;
    border: none;
    border-bottom: 1px solid $c_Silver;
    margin-bottom: 15px;
    @include transition;
    &.is_invalid,
    & select.is_invalid {
        border-color: $c_Red;
    }
}

.form_textarea {
    padding: 0;
}

span.errMess {
    display: block;
    margin-top: -18px;
    font-size: 11px;
    margin-bottom: 10px;
    color: #f00;
}

.form_select {
    margin-bottom: 25px;
    transition: all .25s ease-in-out 0s;
    select {
        display: none !important;
    }
    .is_invalid + .select2 {
        border-color: $c_Red;
    }
    .select2 {
        width: 100% !important;
        border-bottom: 1px solid #cdcdcd;
        position: relative;
        &:after {
            content: '';
            width: 6px;
            height: 6px;
            border-right: 1px solid #4C4B48;
            border-bottom: 1px solid #4C4B48;
            transform: rotate(45deg);
            margin: auto 0;
            position: absolute;
            top: 0;
            bottom: 0;
            right: 5px;
            z-index: 1;
            @include transition
        }
        &.select2-container--open:after {
            transform: rotate(-135deg);
            top: 5px;
        }
    }
    .select2-selection__arrow {
        display: none;
    }
    .select2-selection--single {
        height: auto;
        border: none !important;
        outline: none !important;
    }
    .select2-selection__rendered {
        height: 34px;
        line-height: 34px !important;
        padding: 0 10px 0 0 !important;
        font-size: 13px;
        color: $c_RangoonGreen !important;
        outline: none !important;
    }
}

.form_select_dd {
    &.select2-dropdown {
        margin-top: 0;
    }
    .select2-results {
        border: 1px solid $c_Mercury;
        margin-top: -1px;
    }
    .select2-results__option--highlighted[aria-selected] {
        background-color: $c_SmokyWhite !important;
    }
    .select2-results>.select2-results__options {
        padding: 0 !important;
    }
    .select2-results__option {
        padding: 6px 15px;
        font-size: 13px;
        font-weight: 300;
    }
}

.select2-container--default {
    .select2-selection--multiple,
    .select2-selection--single {
        border-radius: 0;
    }
}


.form_submit {
    cursor: pointer;
    width: 240px;
    height: 50px;
    line-height: 46px;
    padding: 0 15px;
    background-color: $c_SandBrown;
    font-size: 14px;
    text-transform: uppercase;
    color: $c_White;
    font-weight: 300;
    border: 2px solid transparent;
    @include transition;
    &:hover {
        border-color: transparent;
        background-color: rgba($c_SandBrown, .78);
    }
    &:active {
        border-color: $c_SandBrown;
        background-color: $c_White;
        color: $c_Black;
    }
}

.yellow_btn {
    cursor: pointer;
    font-size: 14px !important;
    font-weight: 400 !important;
    border-color: $c_SandBrown !important;
    background-color: $c_SandBrown !important;
    color: $c_White !important;
    transition: all .2s ease-in-out;
    &:hover {
        border-color: transparent !important;
        background-color: rgba($c_SandBrown, .78) !important;
        color: $c_White !important;
    }
    &:active {
        border-color: $c_SandBrown !important;
        background-color: $c_White !important;
        color: $c_Black !important;
    }
}

@media(max-width: 991px) {
    .yellow_btn {
        font-size: 16px !important;
    }
}

.form_subtitle {
    font-size: 14px;
    line-height: 24px;
    font-weight: 300;
    margin-bottom: 10px;
}

.form_radio {
    display: inline-block;
    width: auto;
    height: auto;
    margin-right: 10px;
    margin-bottom: 10px;
    border-bottom: 0;

    input {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        border: 1px solid #cecece;
        vertical-align: middle;
        margin-top: -2px;
        cursor: pointer;
        position: relative;

        &:checked {
            &:before {
                content: '';
                background: #f3ad52;
                width: 10px;
                height: 10px;
                position: absolute;
                border-radius: 50%;
                top: 4px;
                left: 4px;
            }
        }
    }
}

.other-site-sections {
    max-width: 928px;
    margin: 30px auto 0;
    h3, h4 {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 20px;
    }
    ul {padding-left: 30px;
        li {color: $c_SandBrown;padding-left: 15px; position: relative; color: #000;margin-bottom: 12px;
            &:before {content: '';position: absolute;top:11px; left: 0;background: $c_SandBrown;display: block;width: 4px; height: 4px;border-radius: 50%;}
            a, span {font-size: 14px; font-weight: 300; line-height: 24px; color: #000;
                &:hover {color: $c_SandBrown;}
            }
        }
    }
}

.simple_content {
    ul {padding-left: 25px;
        li {color: $c_SandBrown;padding-left: 15px; position: relative; color: #000;margin-bottom: 15px;
            &:before {content: '';position: absolute;top:11px; left: 0;background: $c_SandBrown;display: block;width: 4px; height: 4px;border-radius: 50%;}
            a, span {font-size: 14px; font-weight: 300; line-height: 24px; color: #000;}
        }
    }
}